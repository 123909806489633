import React, { SVGProps } from "react";

export type Props = {
  variant?: "normal" | "large";
} & SVGProps<SVGSVGElement>;

const Checkmark = ({ variant = "normal", ...rest }: Props) =>
  variant === "normal" ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 10.42 8.12"
      height={10}
      width={10}
      {...rest}
    >
      <path d="M3.74 8.12L0 4.36l1.42-1.41 2.32 2.34L9 0l1.42 1.41-6.68 6.71z" />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={10}
      height={10}
      viewBox="0 0 800 400"
      {...rest}
    >
      <path d="M398.1 206.8l-6.1-6.1 2.3-2.3 3.8 3.8 8.6-8.6 2.3 2.3-10.9 10.9z" />
    </svg>
  );

export default Checkmark;

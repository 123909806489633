import React from "react";
import styles from "./module.sass";

const Pulse = () => (
  <div className={styles.root}>
    <div className={styles.pulse1}></div>
    <div className={styles.pulse2}></div>
  </div>
);

export default Pulse;

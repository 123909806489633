import React, { forwardRef, ReactNode, HTMLAttributes } from "react";
import Tippy, { TippyProps } from "@tippyjs/react/headless";
import { useSpring, animated } from "react-spring";
import cn from "classnames";

import { X } from "components/Icons";
import colors from "style/colors.module.scss";

import styles from "./module.sass";

export interface Props extends HTMLAttributes<HTMLDivElement> {
  children: TippyProps["children"];
  content: ReactNode | (() => ReactNode);
  offset: [number, number];
  isVisible?: boolean;
  isDisabled?: boolean;
  isDismissable?: boolean;
  isArrowVisible?: boolean;
  onDismiss?: () => void;
  variant?: "light" | "dark";
  popoverPosition?: "top" | "right";
  padding?: "small" | "big";
  size?: "normal" | "large";
  rendersWhenInvisible?: boolean;
}

const Popover = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      offset,
      content,
      style,
      className,
      isVisible = false,
      isDisabled = false,
      isDismissable = false,
      isArrowVisible = true,
      onDismiss = () => {},
      variant = "dark",
      popoverPosition = "top",
      padding = "small",
      size = "normal",
      rendersWhenInvisible = true,
      ...rest
    },
    ref
  ) => {
    if (!isVisible && !rendersWhenInvisible) {
      return <>{children}</>;
    }

    const config = { duration: 125 };
    const initialStyles = { opacity: 0 };
    const [animationProps, setSpring] = useSpring(() => initialStyles);

    const handleMount = () => {
      setSpring({
        opacity: 1,
        onRest: () => {},
        config,
      });
    };

    const handleHide = ({ unmount }: { unmount: () => void }) => {
      setSpring({
        ...initialStyles,
        onRest: unmount,
        config: { ...config, clamp: true },
      });
    };

    return (
      <Tippy
        appendTo={document.body}
        placement={popoverPosition}
        offset={offset}
        visible={isVisible}
        disabled={isDisabled}
        interactive={isDismissable}
        // delay={[525, null]}
        // duration={[125, 125]}
        animation={true}
        onMount={handleMount}
        onHide={handleHide}
        render={(attrs) => (
          <animated.div
            ref={ref}
            style={{
              maxWidth: 316,
              opacity: animationProps.opacity as any,
              padding: padding === "small" ? "15px 24px 20px" : "16px 28px",
              letterSpacing: "-0.025px",
              ...style,
            }}
            className={cn(
              styles.popover,
              variant === "light" && styles.popover$light,
              size === "large" && styles.popover$big,
              className
            )}
            {...rest}
            {...attrs}
          >
            {isDismissable && (
              <X
                width={12}
                height={12}
                fill={variant === "dark" ? colors.white : colors.grey700}
                className={styles.dismissIcon}
                onClick={onDismiss}
                onKeyDown={({ key }) => {
                  if (key !== "Enter") {
                    return;
                  }
                  onDismiss();
                }}
                tabIndex={0}
              />
            )}
            {typeof content === "function" ? content() : content}
            {isArrowVisible && (
              <div data-popper-arrow="" className={styles.arrow}></div>
            )}
          </animated.div>
        )}
      >
        {children}
      </Tippy>
    );
  }
);

export default Popover;

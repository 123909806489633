import React from "react";

const UpDown = ({
  variant = "plain",
}: {
  variant?: "plain" | "up" | "down";
}) => (
  <svg
    width="16"
    height="16"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 18"
  >
    <path
      fill={variant == "down" ? "#c9d2d6" : "#06192E"}
      d="M6.18 18.002H4.89c-.2 0-.36-.17-.37-.37V3.742l-3.01 3.08a.36.36 0 0 1-.5.01L1 6.822l-.88-.91-.02-.01a.347.347 0 0 1-.1-.25c0-.09.03-.18.1-.25L5.28.122l.01-.01a.33.33 0 0 1 .46-.02l.02.02 5.16 5.29c.14.15.14.38 0 .53l-.86.9-.01.01a.31.31 0 0 1-.24.1h-.01c-.1 0-.19-.04-.26-.11l-3.01-3.08v13.89c0 .19-.16.36-.36.36z"
    />
    <path
      fill={variant == "up" ? "#c9d2d6" : "#06192E"}
      d="M17.82.002h1.29c.2 0 .36.17.37.37v13.89l3.01-3.08a.36.36 0 0 1 .5-.01l.01.01.88.91.02.01c.07.07.1.16.1.25s-.03.18-.1.25l-5.18 5.28-.01.01a.33.33 0 0 1-.46.02l-.02-.02-5.16-5.29a.387.387 0 0 1 0-.53l.86-.9.01-.01c.06-.07.15-.1.24-.1h.01c.1 0 .19.04.26.11l3.01 3.08V.362c0-.19.16-.36.36-.36z"
    />
  </svg>
);

export default UpDown;

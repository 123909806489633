import React, { forwardRef } from "react";
import { FieldHookConfig, useField } from "formik";

import SimpleInput, { Props as InputProps } from "molecules/SimpleInput";

export type Props = InputProps & FieldHookConfig<string>;

const InputField = forwardRef<HTMLInputElement, Props>(
  ({ label, render, autoComplete, onChange, ...rest }, ref) => {
    const [field, meta, helpers] = useField<string>(rest.name);

    return (
      <div style={{ marginBottom: 14 }}>
        <SimpleInput
          {...field}
          ref={ref}
          label={label}
          error={meta.touched && meta.error ? meta.error : undefined}
          render={render}
          autoComplete={autoComplete}
          onChange={onChange ?? ((e) => helpers.setValue(e.target.value))}
        />
      </div>
    );
  }
);

export default InputField;

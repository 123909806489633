import React, { ReactNode } from "react";

import Text from "atoms/Text";
import colors from "style/colors.module.scss";

export interface Props {
  children: ReactNode;
  colSpan: number;
}

export default function NoResults({ children, colSpan }: Props) {
  return (
    <td
      colSpan={colSpan}
      style={{
        border: "none",
        paddingTop: 20,
      }}
    >
      <Text
        style={{
          width: "100%",
          padding: "22px 0",
          backgroundColor: colors.blueGray100,
          textAlign: "center",
        }}
      >
        {children}
      </Text>
    </td>
  );
}

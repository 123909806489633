import React, { forwardRef } from "react";
import { FieldHookConfig, useField } from "formik";

import Checkbox, { Props as CheckboxProps } from "components/Checkbox";

export type Props = Omit<CheckboxProps, "isChecked" | "onChange"> &
  FieldHookConfig<string>;

const CheckboxField = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const [field, meta, helpers] = useField<boolean>(props.name);

  return (
    <div style={{ marginBottom: 18 }}>
      <Checkbox
        {...field}
        ref={ref}
        label={props.label}
        isChecked={field.value}
        onChange={(isChecked, e) => helpers.setValue(isChecked)}
        className={props.className}
      />
    </div>
  );
});

export default CheckboxField;

import React, {
  ChangeEventHandler,
  KeyboardEventHandler,
  useState,
} from "react";
import cn from "classnames";

import SimpleSelect from "molecules/SimpleSelect";
import SimpleInput from "molecules/SimpleInput";
import { PaginationChevron } from "components/Icons";

import styles from "./module.sass";

export type Props = {
  pageSize: number;
  pageIndex: number;
  itemCount: number;
  onChange: (newPage: number) => void;
  onChangePageSize: (newPageSize: number, newPageIndex: number) => void;
};

export default function Pagination({
  pageSize,
  pageIndex,
  itemCount,
  onChange,
  onChangePageSize,
}: Props) {
  const [jumpDestination, setJumpDestination] = useState<number | null>(null);

  const pageCount = Math.ceil(itemCount / pageSize);
  const pages = Array.from(Array(pageCount).keys());

  const isPageIndexValid = (i: number) => i >= 0 && i < pageCount;
  const canPrev = isPageIndexValid(pageIndex - 1);
  const canNext = isPageIndexValid(pageIndex + 1);

  const handlePrev = () => {
    if (canPrev) {
      onChange(pageIndex - 1);
    }
  };

  const handleNext = () => {
    if (canNext) {
      onChange(pageIndex + 1);
    }
  };

  const handleJumpDestinationChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { value },
  }) => {
    const isJumpDestinationDeleted = value === "";
    if (isJumpDestinationDeleted) {
      setJumpDestination(null);
      return;
    }

    const newPage = parseInt(value);
    const isNewPageValid = !isNaN(newPage) && isPageIndexValid(newPage - 1);
    if (!isNewPageValid) {
      return;
    }
    setJumpDestination(newPage);
  };
  const handleJumpDestinationKeyDown: KeyboardEventHandler<HTMLInputElement> = (
    e
  ) => {
    const shouldJump = e.key === "Enter" && jumpDestination !== null;
    if (!shouldJump) {
      return;
    }
    onChange((jumpDestination as number) - 1);
    setJumpDestination(null);
  };

  const handlePageSizeChange = (newSize: number) => {
    if (newSize === pageSize) {
      return;
    }
    onChangePageSize(newSize, 0);
    setJumpDestination(null);
  };

  return (
    <div className={styles.pagination}>
      {pageCount > 1 && (
        <div className={styles.pageControls}>
          {pageCount > 4 && (
            <button
              type="button"
              className={cn(
                styles.prevNext,
                !canPrev && styles.prevNext$disabled
              )}
              tabIndex={canPrev ? 0 : -1}
              onClick={handlePrev}
              onKeyDown={({ key }) => key === "Enter" && handlePrev()}
            >
              <PaginationChevron
                direction="left"
                className={cn(
                  styles.chevron,
                  !canPrev && styles.chevron$disabled
                )}
              />
            </button>
          )}
          <div className={styles.pageControlsInner}>
            <div>
              {pageCount < 5 ? (
                pages.map((page) => (
                  <PaginationButton
                    key={page}
                    page={page + 1}
                    activePage={pageIndex + 1}
                    onClick={() => onChange(page)}
                  />
                ))
              ) : (
                <>
                  {/* First page */}
                  <PaginationButton
                    key={1}
                    page={1}
                    activePage={pageIndex + 1}
                    onClick={() => onChange(0)}
                  />

                  {/* One after first if last is active */}
                  {pageIndex + 1 === pageCount && (
                    <PaginationButton
                      key={2}
                      page={2}
                      activePage={pageIndex + 1}
                      onClick={() => onChange(1)}
                    />
                  )}

                  {pageIndex + 1 > 3 && "..."}

                  {/* Prev */}
                  {pageIndex + 1 > 2 && (
                    <PaginationButton
                      key={pageIndex}
                      page={pageIndex}
                      activePage={pageIndex + 1}
                      onClick={() => onChange(pageIndex - 1)}
                    />
                  )}

                  {/* Current */}
                  {pageIndex + 1 !== 1 && pageIndex + 1 !== pageCount && (
                    <PaginationButton
                      key={pageIndex + 1}
                      page={pageIndex + 1}
                      activePage={pageIndex + 1}
                      onClick={() => onChange(pageIndex)}
                    />
                  )}

                  {/* Next */}
                  {pageIndex + 1 < pageCount - 1 && (
                    <PaginationButton
                      key={pageIndex + 2}
                      page={pageIndex + 2}
                      activePage={pageIndex + 1}
                      onClick={() => onChange(pageIndex + 1)}
                    />
                  )}

                  {pageIndex + 1 < pageCount - 2 && "..."}

                  {/* One before the last if first is active */}
                  {pageIndex + 1 === 1 && (
                    <PaginationButton
                      key={pageCount - 1}
                      page={pageCount - 1}
                      activePage={pageIndex + 1}
                      onClick={() => onChange(pageCount - 2)}
                    />
                  )}

                  {/* Last page */}
                  {pageCount > 1 && (
                    <PaginationButton
                      key={pageCount}
                      page={pageCount}
                      activePage={pageIndex + 1}
                      onClick={() => onChange(pageCount - 1)}
                    />
                  )}
                </>
              )}
            </div>
          </div>
          {pageCount > 4 && (
            <button
              type="button"
              className={cn(
                styles.prevNext,
                !canNext && styles.prevNext$disabled
              )}
              tabIndex={canNext ? 0 : -1}
              onClick={handleNext}
              onKeyDown={({ key }) => key === "Enter" && handleNext()}
            >
              <PaginationChevron
                direction="right"
                className={cn(
                  styles.chevron,
                  !canNext && styles.chevron$disabled
                )}
              />
            </button>
          )}
          <div style={{ marginLeft: 20, width: 76 }}>
            <SimpleInput
              style={{ width: "100%" }}
              placeholder="Page #"
              value={jumpDestination?.toString() ?? ""}
              onChange={handleJumpDestinationChange}
              onKeyDown={handleJumpDestinationKeyDown}
            />
          </div>
        </div>
      )}
      {itemCount > 10 && (
        <div className={styles.rowsPerPage}>
          <SimpleSelect
            direction="up"
            size="small"
            value={pageSize.toString()}
            label="Rows per page:"
            onChange={(val) => {
              handlePageSizeChange(parseInt(val));
            }}
            options={[
              { value: "10", label: "10" },
              { value: "25", label: "25" },
              { value: "50", label: "50" },
              { value: "100", label: "100" },
            ]}
          />
        </div>
      )}
    </div>
  );
}

const PaginationButton = ({
  page,
  activePage,
  onClick,
  ...props
}: {
  page: number;
  activePage: number;
  onClick: () => void;
}) => (
  <button
    {...props}
    type="button"
    tabIndex={0}
    className={cn(
      styles.pageNumber,
      activePage == page && styles.pageNumber$active
    )}
    onClick={onClick}
  >
    {page}
  </button>
);

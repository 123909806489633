import React, { HTMLAttributes, ReactNode } from "react";
import cn from "classnames";

import styles from "./module.sass";

export type Props = {
  children: ReactNode;

  as?: keyof JSX.IntrinsicElements;
  color?:
    | "blue900"
    | "grey600"
    | "grey850"
    | "grey900"
    | "blueGray900"
    | "red700"
    | "red400"
    | "green600"
    | "white"
    | "black";
  size?:
    | 11
    | 12
    | 13
    | 14
    | 15
    | 16
    | 17
    | 18
    | 20
    | 21
    | 23
    | 24
    | 27
    | 30
    | 36;
  weight?: "normal" | "bold" | "black" | "light";
} & HTMLAttributes<HTMLOrSVGElement>;

export default function Text({
  children,
  as: Tag = "div",
  color = "grey900",
  size = 15,
  weight = "normal",
  style = {},
  ...rest
}: Props) {
  return (
    <Tag
      className={cn(
        styles.text,
        styles[`text$${color}`],
        styles[`text$${weight}Weight`],
        rest.className
      )}
      style={{
        fontSize: size,
        ...style,
      }}
      {...rest}
    >
      {children}
    </Tag>
  );
}

import React, { FormEventHandler, useState } from "react";
import cn from "classnames";

import Button from "components/Button";
import Input from "components/Input";
import { getAnalytics } from "domain/analytics";

import { TabPanel, SecondaryTabPanel, TabPanelItem } from ".";
import styles from "./LawFirmForm.module.sass";

export default function LawFirmForm({
  canCreateReportCard,
}: {
  canCreateReportCard: boolean;
}) {
  const [name, setName] = useState("");

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    recordFormSubmission(name);
    const urlParams = new URLSearchParams({ o: name });
    window.location.href = `/law-firm-tree-search.php?${urlParams.toString()}`;
  };

  return (
    <>
      <TabPanel>
        <div style={{ width: "100%" }}>
          <div className={styles.lawFirmSubheading}>Law Firm Dashboard</div>
          <div className={styles.lawFirmDescription}>
            View law firm data within our legacy dashboard.
          </div>
          <form
            style={{ width: "100%", marginTop: 22 }}
            onSubmit={handleSubmit}
          >
            <TabPanelItem>
              <Input
                label="Law Firm Name"
                value={name}
                onChange={(value) => setName(value.trimLeft())}
              />
            </TabPanelItem>
            <TabPanelItem>
              <Button
                id="homepageLawFirmBtnSubmit"
                leftIcon="magnifier"
                type="submit"
                variant="brand"
                isDisabled={!name}
              >
                Search
              </Button>
            </TabPanelItem>
          </form>
        </div>
      </TabPanel>
      {canCreateReportCard && (
        <SecondaryTabPanel>
          <div className={styles.lawFirmSubheading}>Law Firm Report Cards</div>
          <div
            className={cn(
              styles.lawFirmDescription,
              styles.lawFirmDescription$right
            )}
          >
            View metrics for evaluating outside counsel.
          </div>
          <TabPanelItem>
            <div
              onClick={() => {
                recordCreateLawFirmReportCardClick();
              }}
            >
              <a
                href="/smart-reports/law-firm-performance.php"
                className={styles.link}
              >
                <Button variant="contained">Create Law Firm Report Card</Button>
              </a>
            </div>
          </TabPanelItem>
        </SecondaryTabPanel>
      )}
    </>
  );
}

function recordFormSubmission(query: string) {
  getAnalytics().recordEvent({
    pageName: "home_page",
    interfaceEvent: "search_law_firms",
    interfaceValue: "search",
    action: "click",
    context: { query },
  });
}

function recordCreateLawFirmReportCardClick() {
  getAnalytics().recordEvent({
    pageName: "home_page",
    interfaceEvent: "link_click",
    interfaceValue: "create_law_firm_report_card",
    action: "click",
  });
}

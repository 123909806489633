import React, { HTMLAttributes } from "react";
import cn from "classnames";

import Anchor from "atoms/Anchor";

import styles from "./module.sass";

export type Props = HTMLAttributes<HTMLDivElement>;

export default function PrivacyPolicy({ className, ...rest }: Props) {
  return (
    <div className={cn(styles.privacyPolicy, className)} {...rest}>
      LexisNexis&reg; Legal &amp; Professional and our{" "}
      <Anchor
        href="https://www.lexisnexis.com/en-us/contact-us/contact-us.page"
        target="_blank"
        style={{ fontSize: "inherit", lineHeight: "inherit" }}
      >
        affiliates
      </Anchor>
      &nbsp;may further contact you in your professional capacity about related
      products, services and events. You will be able to opt-out at any time via
      the unsubscribe link provided within our communications or manage your
      communication preferences via our Preference Center. For more information,
      see our{" "}
      <Anchor
        href="https://www.lexisnexis.com/en-us/terms/privacy-policy.page"
        target="_blank"
        style={{ fontSize: "inherit", lineHeight: "inherit" }}
      >
        Privacy Policy
      </Anchor>
      .
      <p>
        By signing up, you agree to our{" "}
        <Anchor
          href="https://go.lexisnexisip.com/hubfs/PatentAdvisor-Terms-and-Conditions.pdf"
          target="_blank"
          style={{ fontSize: "inherit", lineHeight: "inherit" }}
        >
          Terms & Conditions
        </Anchor>
        .
      </p>
    </div>
  );
}

import React from "react";

import { ReleaseNote as ReleaseNoteType } from "domain/releaseNotes";
import ReleaseNote from "molecules/ReleaseNote";
import Text from "atoms/Text";
import HorizontalRule from "atoms/HorizontalRule";

import styles from "./module.sass";

export type Props = {
  releaseNotes: ReleaseNoteType[];
};

export default function ReleaseNotes({ releaseNotes }: Props) {
  return (
    <section className={styles.releaseNotes}>
      <Text
        color="grey900"
        size={36}
        weight="light"
        style={{ marginBottom: 32 }}
      >
        Release Notes
      </Text>
      {releaseNotes.map((releaseNote, i) => (
        <React.Fragment key={releaseNote.id}>
          <ReleaseNote {...releaseNote} />
          {i !== releaseNotes.length - 1 && (
            <HorizontalRule style={{ marginTop: 26, marginBottom: 26 }} />
          )}
        </React.Fragment>
      ))}
    </section>
  );
}

import React, { forwardRef, HTMLAttributes, ReactNode, useRef } from "react";
import cn from "classnames";
import ReactTooltip from "react-tooltip";

import { Magnifier } from "components/Icons";

import styles from "./module.sass";
import useCombinedRefs from "hooks/useCombinedRefs";

export type Props = {
  children?: ReactNode;
  leftIcon?: "magnifier" | ReactNode;
  variant?: "brand" | "contained" | "outlined" | "naked" | "link";
  size?: "lg" | "sm" | "xs" | "xxs" | "xxxs";
  type?: "button" | "submit";
  tooltip?: string;
  isDisabled?: boolean;
} & HTMLAttributes<HTMLButtonElement>;

const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      children = null,
      leftIcon = undefined,
      variant = "naked",
      size = "lg",
      type = "button",
      tooltip = "",
      isDisabled = false,
      onClick = () => {},
      className,
      ...rest
    },
    ref
  ) => {
    const innerRef = useRef<HTMLButtonElement>(null);
    const combinedRef = useCombinedRefs(ref, innerRef);

    return (
      <>
        <button
          {...rest}
          ref={combinedRef}
          type={type}
          onClick={onClick}
          disabled={isDisabled}
          data-tip={tooltip}
          className={cn(
            styles.button,
            styles[`button$${variant}`],
            styles[`button$${size}`],
            className
          )}
        >
          {leftIcon === "magnifier" ? (
            <Magnifier className={styles.icon} />
          ) : (
            ""
          )}

          {leftIcon && leftIcon !== "magnifier" && (
            <span
              className={cn(
                styles.customIcon,
                !children && styles.customIcon$onlyIcon
              )}
            >
              {leftIcon}
            </span>
          )}

          {children}
        </button>

        {tooltip.length > 0 && (
          <ReactTooltip
            place="top"
            effect="solid"
            textColor="white"
            className={styles.tooltip}
            backgroundColor="#132a32"
            arrowColor="#132a32"
            delayShow={575}
            // delayUpdate={575}
            delayHide={125}
          />
        )}
      </>
    );
  }
);

export default Button;

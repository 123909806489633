import { Environment, getEnvironment } from "domain/environment";
import { WamId } from "domain/users";

import * as Matomo from "./matomo";
import { LoggingAnalytics } from "./logging";

export function buildAnalytics(
  environment: Environment = getEnvironment()
): Analytics {
  if (environment === Environment.Production) {
    return new Matomo.ProductionAnalytics();
  }
  if (environment === Environment.QA) {
    return new Matomo.QAAnalytics();
  }
  return new LoggingAnalytics();
}

export interface Analytics {
  recordEvent: (event: Event) => void;
  recordPageView: (url: string, name: string) => void;
  setCurrentUser: (user: User) => void;
  removeCurrentUser: () => void;
}

export interface User {
  userId: number;
  wamId: WamId;
}

export interface Event {
  pageName: string;
  interfaceEvent: string;

  component?: string;
  subComponent?: string;
  interfaceValue?: string;
  action?: string;
  context?: Record<string, any>;
}

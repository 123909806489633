// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1heJ-n7neKN9tJhwRYRgbk{width:100%;max-width:900px;box-sizing:border-box;padding:30px 78px 78px 78px;background-color:#fff;box-shadow:0 0 8px 0 rgba(0,0,0,0.05)}\n", "",{"version":3,"sources":["webpack://./src/organisms/ReleaseNotes/module.sass","webpack://./src/style/_colors.sass"],"names":[],"mappings":"AAEA,yBACI,UAAW,CACX,eAAgB,CAChB,qBAAsB,CACtB,2BAA4B,CAE5B,qBCRQ,CDUR,qCAAsC","sourcesContent":["@import 'style/colors';\n\n.releaseNotes {\n    width: 100%;\n    max-width: 900px;\n    box-sizing: border-box;\n    padding: 30px 78px 78px 78px;\n\n    background-color: $white;\n\n    box-shadow: 0 0 8px 0 rgba(0,0,0,0.05); }\n","$white: #fff;\n$black: #000;\n\n$grey900: #212121;\n$grey850: #373737;\n$grey800: #424242;\n$grey700: #616161;\n$grey600: #757575;\n$grey500: #9e9e9e;\n$grey400: #bdbdbd;\n$grey350: #d5d5d5;\n$grey300: #e0e0e0;\n$grey200: #eee;\n$grey100: #f5f5f5;\n$grey50: #fafafa;\n\n$darkBlue800: #132a43;\n$darkBlue700: #1a304d;\n\n$darkCyan600: #465a63;\n\n$blue900: #0D47a1;\n$blue700: #1976d2;\n$blue500: #2196f3;\n$blue50: #e3f2fd;\n\n$lightBlue800: #006ebb;\n$lightBlue600: #039be5;\n$lightBlue500: #03a9f4;\n$lightBlue300: #4fc3f7;\n\n$blueGray900: #455a64;\n$blueGray800: #546e7a;\n$blueGray600: #8c9eb1;\n$blueGray400: #acbac8;\n$blueGray100: #eceff1;\n\n$red700: #d60019; // errors, alerts\n$red500: #f6271c; // brand\n$red400: #f3403e;\n\n$green600: #43a047;\n$green400: #66bb6a;\n\n$yellow800: #f9a825;\n\n$orange500: #ff9800;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"releaseNotes": "_1heJ-n7neKN9tJhwRYRgbk"
};
export default ___CSS_LOADER_EXPORT___;

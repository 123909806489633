import React, { useState } from "react";

import Popup from "organisms/Popup";

export default function TrialWelcome() {
  const [isShown, setIsShown] = useState(true);

  return (
    <Popup
      title="Welcome to PatentAdvisor!"
      isOpen={isShown}
      onDismiss={() => setIsShown(false)}
    >
      <div style={{ letterSpacing: "initial", marginBottom: 20 }}>
        Thank you for verifying your email. If you have already installed the
        Browser
        <br />
        Extension, you'll now be able to see examiner analytics directly in
        Patent Center
        <br />
        and Private PAIR. You also have access to our most popular features
        including File
        <br />
        Wrappers search, Examiner reports, Art Unit reports and QuickPAIR.
      </div>
      <div style={{ letterSpacing: "initial" }}>
        After your account is reviewed, you’ll be able to do even more research
        with Benchmarking Reports, Law Firm Report Cards, Tech Center Navigator,
        and PTAB Decisions.
      </div>
    </Popup>
  );
}

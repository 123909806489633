import React, { useCallback } from "react";
import ReactSelect from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format as formatDate } from "domain/dates";
import Checkbox from "components/Checkbox";
import Text from "components/Text";
import TextInput from "components/PTABDecisions/components/TextInput";
import {
  DictionaryItemType,
  getReactSelectDefaultProps,
} from "components/PTABDecisions/components/utils";
import { Partition } from "../components";
import {
  DecisionDetailsType,
  DecisionMainTypes,
  DecisionTypes,
} from "domain/ptabAdmin";
import styles from "./module.sass";

const DetailsLabel = ({
  label,
  children,
}: {
  label: string;
  children: React.ReactNode;
}) => {
  return (
    <div className={styles.label}>
      <Text className={styles.labelText}>{label}</Text>
      {children}
    </div>
  );
};

const DatePickerRow = ({
  label,
  data,
  propName,
  onChange,
}: {
  label: string;
  data?: any;
  propName: string;
  onChange: (newData?: any) => void;
}) => {
  return (
    <DetailsLabel label={label}>
      <DatePicker
        wrapperClassName={styles.datePickerContainer}
        className={styles.datePicker}
        selected={!!data && !!data[propName] ? new Date(data[propName]) : null}
        isClearable={true}
        clearButtonClassName={styles.clearButton}
        onChange={(date) => {
          if (!!date && date instanceof Date) {
            onChange({ ...data, [propName]: formatDate(date, "Y-m-d") });
          } else {
            onChange({ ...data, [propName]: null });
          }
        }}
      />
    </DetailsLabel>
  );
};

const TextRow = ({ label, value }: { label: string; value?: any }) => {
  return (
    <DetailsLabel label={label}>
      <Text className={styles.valueText}>{!value ? "-" : value}</Text>
    </DetailsLabel>
  );
};

const TextInputRow = ({
  label,
  data,
  propName,
  onChange,
}: {
  label: string;
  data?: any;
  propName: string;
  onChange: (newData?: any) => void;
}) => {
  const onChangeCallback = useCallback(
    (value: string) => {
      onChange({ ...data, [propName]: value ?? "" });
    },
    [data, propName, onChange]
  );

  return (
    <DetailsLabel label={label}>
      <TextInput
        value={data ? data[propName] ?? "" : ""}
        onChange={onChangeCallback}
        className={styles.textInput}
      />
    </DetailsLabel>
  );
};

const ComboboxRow = ({
  label,
  data,
  propName,
  onChange,
  options,
}: {
  label: string;
  data?: any;
  propName: string;
  onChange: (newData?: any) => void;
  options: Array<DictionaryItemType>;
}) => {
  return (
    <DetailsLabel label={label}>
      <ReactSelect
        {...getReactSelectDefaultProps({
          hasValue: false,
          hasOptions: true,
          width: 200,
          height: 28,
        })}
        value={data && options.find(({ value }) => value === data[propName])}
        options={options}
        onChange={(option) => {
          onChange({ ...data, [propName]: option.value });
        }}
      />
    </DetailsLabel>
  );
};

const CheckboxRow = ({
  label,
  data,
  propName,
  onChange,
}: {
  label: string;
  data?: any;
  propName: string;
  onChange: (newData?: any) => void;
}) => {
  return (
    <DetailsLabel label={label}>
      <Checkbox
        isChecked={!!data && !!data[propName] ? data[propName] : false}
        onChange={(value) => {
          onChange({ ...data, [propName]: value });
        }}
      />
    </DetailsLabel>
  );
};

export const DecisionFields = ({
  details,
}: {
  details?: DecisionDetailsType;
}) => {
  return (
    <Partition title={"Details"} id={"fields"} style={{ marginTop: 8 }}>
      <TextRow
        label={"Correspondent Name"}
        value={details?.correspondent_name}
      />
      <TextRow
        label={"Application Number"}
        value={details?.application_number}
      />
      <TextRow
        label={"Filing Date"}
        value={
          !details?.filing_date
            ? undefined
            : formatDate(new Date(details?.filing_date), "mm/dd/yyyy")
        }
      />
      <TextRow
        label={"First Named Inventor"}
        value={details?.first_named_inventor}
      />
      <TextRow
        label={"Attorney Docket Number"}
        value={details?.attorney_docket_number}
      />
      <TextRow
        label={"Confirmation Number"}
        value={details?.confirmation_number}
      />
      <TextRow label={"Examiner"} value={details?.examiner} />
      <TextRow label={"Group Art Unit"} value={details?.group_art_unit} />
      <TextRow label={"Customer Number"} value={details?.customer_number} />
    </Partition>
  );
};

export const DecisionFlags = ({
  details,
  setDecisionDetails,
}: {
  details?: DecisionDetailsType;
  setDecisionDetails: (details: DecisionDetailsType) => void;
}) => {
  return (
    <Partition title={"Details"} id={"flags"}>
      <CheckboxRow
        label={"Dissents"}
        propName={"dissents"}
        data={details}
        onChange={setDecisionDetails}
      />
      <CheckboxRow
        label={"Remand"}
        propName={"remand"}
        data={details}
        onChange={setDecisionDetails}
      />
      <CheckboxRow
        label={"Reexamination"}
        propName={"reexamination"}
        data={details}
        onChange={setDecisionDetails}
      />
      <CheckboxRow
        label={"Reissue"}
        propName={"reissue"}
        data={details}
        onChange={setDecisionDetails}
      />
      <CheckboxRow
        label={"Oral Hearing"}
        propName={"oral_hearing"}
        data={details}
        onChange={setDecisionDetails}
      />
      <DatePickerRow
        label={"Oral Hearing Date"}
        propName={"oral_hearing_date"}
        data={details}
        onChange={setDecisionDetails}
      />
      <TextInputRow
        label={"Federal Circuit Name"}
        propName={"federal_circuit_name"}
        data={details}
        onChange={setDecisionDetails}
      />
      <TextInputRow
        label={"Covered Business Model"}
        propName={"covered_business_model"}
        data={details}
        onChange={setDecisionDetails}
      />
      <TextInputRow
        label={"Inter Parts Review"}
        propName={"inter_parts_review"}
        data={details}
        onChange={setDecisionDetails}
      />
      <ComboboxRow
        label={"Type"}
        propName={"type"}
        options={DecisionTypes}
        data={details}
        onChange={setDecisionDetails}
      />
      <ComboboxRow
        label={"Main Type"}
        propName={"bigtype"}
        options={DecisionMainTypes}
        data={details}
        onChange={setDecisionDetails}
      />
    </Partition>
  );
};

import React from "react";

const Warning = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17.73 17.73"
    height={16}
    width={16}
    role="presentation"
    fill="#e8171f"
  >
    <path d="M8.2 3.84h1.48v7.83H8.2z" />
    <path d="M8.2 12.93h1.48v1.26H8.2z" />
    <path d="M8.87 17.73a8.87 8.87 0 118.86-8.86 8.87 8.87 0 01-8.86 8.86zM8.87 1a7.91 7.91 0 107.9 7.91A7.91 7.91 0 008.87 1z" />
  </svg>
);

export default Warning;

import React, { FocusEventHandler, FormEvent, useRef, useState } from "react";

import useClickOutside from "hooks/useClickOutside";
import {
  AppNumber,
  PatentNumber,
  PublicationNumber,
} from "domain/applications";
import Select from "components/Select";
import Input from "components/Input";
import Button from "components/Button";
import { getAnalytics } from "domain/analytics";

import { TabPanel, TabPanelItem } from ".";

type SearchType = "appNumber" | "patentNumber" | "publicationNumber";

export default function QuickPAIRForm() {
  const [searchType, setSearchType] = useState<SearchType>("appNumber");
  const [appNumber, setAppNumber] = useState("");
  const [error, setError] = useState("");
  const [patentNumber, setPatentNumber] = useState("");
  const [publicationNumber, setPublicationNumber] = useState("");
  const formRef = useRef<HTMLFormElement>(null);

  useClickOutside(formRef, () => {
    setError("");
  });

  const handleInputBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    if (
      e.relatedTarget instanceof HTMLButtonElement &&
      e.relatedTarget.type === "submit"
    ) {
      return;
    }
    setError("");
  };

  const validateAppNumber = () => {
    setError("");

    if (!new AppNumber(appNumber).isValid()) {
      setError(
        "The Application Number you entered is invalid.\nPlease try again."
      );
      return false;
    }
    return true;
  };
  const validatePatentNumber = () => {
    setError("");

    if (!new PatentNumber(patentNumber).isValid()) {
      setError("The Patent Number you entered is invalid.\nPlease try again.");
      return false;
    }
    return true;
  };
  const validatePublicationNumber = () => {
    setError("");

    if (!new PublicationNumber(publicationNumber).isValid()) {
      setError(
        "The Publication Number you entered is invalid.\nPlease try again."
      );
      return false;
    }
    return true;
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (searchType === "appNumber" && validateAppNumber()) {
      recordFormSubmission(searchType, appNumber);
      window.location.href = `/quickpair/${appNumber.match(/\d+/g)?.join("")}`;
    }
    if (searchType === "patentNumber" && validatePatentNumber()) {
      recordFormSubmission(searchType, patentNumber);
      const urlParams = new URLSearchParams({ patentNumber });
      window.location.href = `/quickpair/?${urlParams.toString()}`;
    }
    if (searchType === "publicationNumber" && validatePublicationNumber()) {
      recordFormSubmission(searchType, publicationNumber);
      const urlParams = new URLSearchParams({ publicationNumber });
      window.location.href = `/quickpair/?${urlParams.toString()}`;
    }
  };
  const handleSelectedSearchTypeChange = (newSearchType: SearchType) => {
    setError("");
    setSearchType(newSearchType);
  };

  const isSearchEnabled =
    (searchType === "appNumber" && appNumber) ||
    (searchType === "patentNumber" && patentNumber) ||
    (searchType === "publicationNumber" && publicationNumber);

  return (
    <TabPanel>
      <form ref={formRef} style={{ width: "100%" }} onSubmit={handleSubmit}>
        <TabPanelItem>
          <Select
            value={searchType}
            onChange={handleSelectedSearchTypeChange}
            options={[
              { value: "appNumber", label: "Application Number" },
              { value: "patentNumber", label: "Patent Number" },
              { value: "publicationNumber", label: "Publication Number" },
            ]}
          />
        </TabPanelItem>

        {searchType === "appNumber" && (
          <TabPanelItem>
            <Input
              value={appNumber}
              label="Example: 15253256 or 15/253,256"
              error={error}
              onChange={(value) => {
                setError("");
                setAppNumber(value);
              }}
              onBlur={handleInputBlur}
            />
          </TabPanelItem>
        )}
        {searchType === "patentNumber" && (
          <TabPanelItem>
            <Input
              value={patentNumber}
              label="Example: 10634492 or 10,634,492"
              error={error}
              onChange={(value) => {
                setError("");
                setPatentNumber(value);
              }}
              onBlur={handleInputBlur}
            />
          </TabPanelItem>
        )}
        {searchType === "publicationNumber" && (
          <TabPanelItem>
            <Input
              value={publicationNumber}
              label="Eg: 20180061040 or 2018-0061040"
              error={error}
              onChange={(value) => {
                setError("");
                setPublicationNumber(value);
              }}
              onBlur={handleInputBlur}
            />
          </TabPanelItem>
        )}
        <TabPanelItem>
          <Button
            id="homepagebtnQPTypeSubmit"
            type="submit"
            variant="brand"
            leftIcon="magnifier"
            isDisabled={!isSearchEnabled}
          >
            Search
          </Button>
        </TabPanelItem>
      </form>
    </TabPanel>
  );
}

function recordFormSubmission(searchType: SearchType, query: string) {
  getAnalytics().recordEvent({
    pageName: "home_page",
    interfaceEvent: "search_quick_pair",
    interfaceValue: "search",
    action: "click",
    context: {
      searchType,
      query,
    },
  });
}

import React, { useRef, useEffect, RefObject, ForwardedRef } from "react";

export default function useCombinedRefs<T extends HTMLElement>(
  ...refs: ForwardedRef<T>[] | RefObject<T>[]
) {
  const targetRef = useRef<T>(null);

  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) {
        return;
      }
      if (typeof ref === "function") {
        ref(targetRef.current);
        return;
      }
      ref.current = targetRef.current;
    });
  }, [refs]);

  return targetRef;
}

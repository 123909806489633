// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2yek5t4POekF0veQnck3nX{width:1600px;display:flex;flex-direction:column;padding:0 36px;box-sizing:border-box}\n", "",{"version":3,"sources":["webpack://./src/organisms/TrialUserApproval/module.sass","webpack://./src/style/_variables.sass"],"names":[],"mappings":"AAEA,yBACC,YCWa,CDVb,YAAa,CACb,qBAAsB,CACtB,cAAe,CACf,qBAAsB","sourcesContent":["@import \"style/variables\";\n\n.trialUserApproval {\n\twidth: $bp-xl;\n\tdisplay: flex;\n\tflex-direction: column;\n\tpadding: 0 36px;\n\tbox-sizing: border-box; }\n","@import \"_colors.sass\";\n\n$z-dropdown: 800;\n$z-tooltip: 900;\n$z-navbar: 5000;\n$z-overlay: 5002;\n$z-skip-navigation-link: 5500;\n\n// $bp-sm:\t640px\n// $bp-md:\t768px\n// $bp-lg:\t1024px\n// $bp-xl:\t1280px\n// $bp-2xl: 1536px\n\n$bp-xl: 1600px;\n$bp-lg: 1366px;\n$bp-md: 1100px;\n$bp-xs: 640px;\n$bp-xxs: 360px;\n\n$text-sm: 12px;\n$text-base: 15px;\n$text-lg: 21px;\n$text-xl: 24px;\n$text-2xl: 36px;\n$text-3xl: 42px;\n\n$space-1: 0.25rem;\n$space-2: 0.5rem;\n$space-3: 0.75rem;\n$space-4: 1rem;\n$space-5: 1.25rem;\n$space-6: 1.5rem;\n$space-7: 1.75rem;\n$space-8: 2rem;\n$space-10: 2.5rem;\n$space-12: 3rem;\n$space-16: 4rem;\n$space-20: 5rem;\n$space-24: 6rem;\n$space-32: 8rem;\n\n$navbar_height_lg: 60px;\n$navbar_height_xl: 80px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"trialUserApproval": "_2yek5t4POekF0veQnck3nX"
};
export default ___CSS_LOADER_EXPORT___;

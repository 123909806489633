import React, { Fragment, useEffect, useReducer } from "react";
import { v4 as uuid } from "uuid";

import { Decision, IssueCategory, Issue } from "domain/ptab";
import Back from "molecules/Back";
import FinderInput from "molecules/FinderInput";
import Text from "atoms/Text";
import Button from "components/Button";
import { format as formatDate } from "domain/dates";
import HorizontalRule from "atoms/HorizontalRule";
import {
  searchText,
  reconcileTextChunks,
  ReconcilableTextChunk,
} from "domain/search";
import { InfoIcon } from "components/Icons";
import Tooltip from "atoms/Tooltip";

import DocumentViewer, { Color, HighlightedTextChunk } from "./DocumentViewer";
import IssueGroup, { issueCategoryToColor } from "./IssueGroup";
import DecisionInfo from "./DecisionInfo";

export type Props = {
  decision: Decision;
  onBack: (() => void) | null;
  onDocumentDownload: () => void;
};

export default function ArgumentFinder({
  decision,
  onBack,
  onDocumentDownload,
}: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const unreconciledChunks = getUnreconciledChunks(decision, state);
  const focusableToSortedUniqueRanges = getFocusableToSortedUniqueRanges(
    unreconciledChunks
  );

  const reconciledChunks = reconcileTextChunks(unreconciledChunks);
  const highlightedChunks = highlightChunks(
    reconciledChunks,
    focusableToSortedUniqueRanges,
    state
  );

  const focusableToChunkCount = getFocusableToChunkCount(
    focusableToSortedUniqueRanges
  );
  const focusedChunks = highlightedChunks.filter(isChunkFocused);
  const focusedIssuesNames = getIssuesNames(focusedChunks);

  const focusedTextOffset = focusedChunks.length
    ? focusedChunks[0].start
    : null;

  const tooltipId = uuid();

  useEffect(() => {
    if (state.activeFocusable === "conclusion") {
      dispatch({ type: "unfocus" });
    }
  }, [state.activeFocusable]);

  return (
    <section style={{ paddingTop: 12 }}>
      {onBack ? (
        <Back style={{ marginBottom: 16 }} onClick={onBack}>
          Decisions by Argument
        </Back>
      ) : (
        <div style={{ marginBottom: 16 }} />
      )}
      <div
        style={{
          height: "100%",
          display: "flex",
          paddingBottom: 40,
          boxSizing: "border-box",
        }}
      >
        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <div
            style={{
              marginBottom: 20,
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <Text as="h1" size={24} style={{ marginBottom: -6 }}>
              {decision.caseName}
            </Text>
            {decision.conclusionTextChunk ? (
              <Button
                size="sm"
                variant="outlined"
                style={{ marginLeft: "auto" }}
                onClick={() => dispatch({ type: "jumpToConclusion" })}
              >
                View Conclusions
              </Button>
            ) : (
              <div style={{ marginLeft: "auto" }} />
            )}
            <Button
              size="sm"
              variant="outlined"
              style={{ marginLeft: 20 }}
              onClick={onDocumentDownload}
            >
              Download PDF
            </Button>
          </div>
          <DocumentViewer
            document={decision.document}
            highlights={highlightedChunks}
            focusedTextOffset={focusedTextOffset}
            isHighlightUnderlined={isChunkFocused}
          />
        </div>
        <div
          style={{
            width: 510,
            marginLeft: 50,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FinderInput
            query={state.query}
            placeholder="Search document"
            focusedItem={state.focusableToFocusedItem.search ?? 0}
            itemCount={focusableToChunkCount["search"] ?? 0}
            onChange={(query) => dispatch({ type: "search", query })}
            onFocusedItemChange={(focusedItem) =>
              dispatch({
                type: "focus",
                focusable: "search",
                focus: focusedItem,
              })
            }
            style={{ marginBottom: 25 }}
          />
          <div style={{ height: "100%", overflowY: "auto" }}>
            <Text
              as="h2"
              size={20}
              color="grey900"
              style={{
                marginBottom: 16,
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              Argument Finder
              <InfoIcon
                style={{ marginLeft: 6 }}
                data-tip={
                  "Find references to the legal<br />issues cited in this PTAB case."
                }
                data-for={tooltipId}
              />
              <Tooltip id={tooltipId} html={true} />
            </Text>
            <HorizontalRule style={{ marginBottom: 16 }} />
            {decision.issueGroups.map((issueGroup) => (
              <Fragment key={issueGroup.category}>
                <IssueGroup
                  {...issueGroup}
                  issues={issueGroup.issues.map((issue) => ({
                    name: issue.name,
                    outcome: issue.outcome,
                    isUnderlined: focusedIssuesNames.includes(issue.name),
                  }))}
                  focusedItem={
                    state.focusableToFocusedItem[issueGroup.category] ?? 0
                  }
                  itemCount={focusableToChunkCount[issueGroup.category] ?? 0}
                  isShown={
                    state.issueCategoryToVisibility[issueGroup.category] ?? true
                  }
                  onFocusChange={(focusedItem) =>
                    dispatch({
                      type: "focus",
                      focusable: issueGroup.category,
                      focus: focusedItem,
                    })
                  }
                  onToggle={() =>
                    dispatch({
                      type: "toggle",
                      issueCategory: issueGroup.category,
                    })
                  }
                />
                <HorizontalRule style={{ marginTop: 10, marginBottom: 15 }} />
              </Fragment>
            ))}
            <DecisionInfo
              applicationNumber={decision.applicationNumber.toString()}
              applicationLink={decision.applicationLink}
              decisionDate={formatDate(decision.decisionDate, "Y-m-d")}
              filingDate={
                decision.filingDate
                  ? formatDate(decision.filingDate, "Y-m-d")
                  : null
              }
              examiner={decision.examiner.name}
              examinerLink={`/statistics.php?Parent=Examiner&Examiner=${decision.examiner.id}`}
              groupArtUnit={decision.groupArtUnit}
              firstNamedInventor={decision.firstNamedInventor}
              inventionTitle={decision.inventionTitle}
              panelJudges={decision.panelJudges}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

type Focusable = IssueCategory | "search" | "conclusion";

type State = {
  query: string;
  activeFocusable: Focusable | null;
  focusableToFocusedItem: Partial<{ [key in Focusable]: number }>;
  issueCategoryToVisibility: Partial<{ [key in IssueCategory]: boolean }>;
};

type Action =
  | { type: "focus"; focusable: Focusable; focus: number }
  | { type: "search"; query: string }
  | { type: "toggle"; issueCategory: IssueCategory }
  | { type: "jumpToConclusion" }
  | { type: "unfocus" };

const initialState: State = {
  query: "",
  activeFocusable: null,
  focusableToFocusedItem: {},
  issueCategoryToVisibility: {},
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "toggle": {
      const oldVisibility = isIssueCategoryVisible(action.issueCategory, state);

      return {
        ...state,
        issueCategoryToVisibility: {
          ...state.issueCategoryToVisibility,
          [action.issueCategory]: !oldVisibility,
        },
        focusableToFocusedItem: {
          ...state.focusableToFocusedItem,
          [action.issueCategory]: 0,
        },
      };
    }
    case "focus":
      return {
        ...state,
        activeFocusable: action.focusable,
        focusableToFocusedItem: {
          ...state.focusableToFocusedItem,
          [action.focusable]: action.focus,
        },
      };
    case "search":
      return {
        ...state,
        query: action.query,
        activeFocusable: null,
        focusableToFocusedItem: { ...state.focusableToFocusedItem, search: 0 },
      };
    case "jumpToConclusion":
      return {
        ...state,
        activeFocusable: "conclusion",
        focusableToFocusedItem: {
          ...state.focusableToFocusedItem,
          conclusion: 1,
        },
      };
    case "unfocus":
      return {
        ...state,
        activeFocusable: null,
      };
    default:
      throw new Error();
  }
}

type ChunkMergableData = {
  focusable: Focusable;
  issueName: string;
  originalIndexInFocusable: number;
};

function getUnreconciledChunks(
  decision: Decision,
  state: State
): ReconcilableTextChunk[] {
  const conclusionChunks = getConclusionChunks(decision);
  const searchChunks = getSearchChunks(decision, state);
  const issuesChunks = getIssuesChunks(decision, state);

  return conclusionChunks.concat(searchChunks).concat(issuesChunks);
}

type FocusableToRanges = Partial<{ [key in Focusable]: string[] }>;

function getFocusableToSortedUniqueRanges(
  unreconciledChunks: ReconcilableTextChunk[]
): FocusableToRanges {
  const focusableToUniqueRanges: FocusableToRanges = {};

  unreconciledChunks.forEach((chunk) => {
    const focusable = chunk.mergableData![0].focusable as Focusable;
    const range = `${chunk.start}:${chunk.end}`;

    if (!focusableToUniqueRanges[focusable]) {
      focusableToUniqueRanges[focusable] = [];
    }
    if (focusableToUniqueRanges[focusable]!.includes(range)) {
      return;
    }
    focusableToUniqueRanges[focusable]!.push(range);
  });

  const focusableToSortedUniqueRanges: FocusableToRanges = {};
  Object.keys(focusableToUniqueRanges).forEach((focusable) => {
    focusableToSortedUniqueRanges[
      focusable as Focusable
    ] = focusableToUniqueRanges[focusable as Focusable]!.sort((a, b) => {
      const [startA, endA] = a.split(":").map((v) => Number(v));
      const [startB, endB] = b.split(":").map((v) => Number(v));

      return startA - startB;
    });
  });

  return focusableToSortedUniqueRanges;
}

function highlightChunks(
  reconciledChunks: ReconcilableTextChunk[],
  focusableToSortedUniqueRanges: FocusableToRanges,
  state: State
): HighlightedTextChunk[] {
  const { start: focusStart, end: focusEnd } = getFocusedRange(
    focusableToSortedUniqueRanges,
    state
  );

  return reconciledChunks.map((chunk) => {
    const isFocused = chunk.start >= focusStart && chunk.end <= focusEnd;

    return {
      ...chunk,
      color: getChunkColor({ ...chunk, isFocused } as any, state),
      isFocused,
    };
  });
}

function getFocusedRange(
  focusableToSortedUniqueRanges: FocusableToRanges,
  state: State
): { start: number; end: number } {
  if (!state.activeFocusable) {
    return { start: 0, end: 0 };
  }

  const isFocusableVisible =
    state.activeFocusable === "search" ||
    state.activeFocusable === "conclusion" ||
    isIssueCategoryVisible(state.activeFocusable, state);
  if (!isFocusableVisible) {
    return { start: 0, end: 0 };
  }

  const focusableRanges =
    focusableToSortedUniqueRanges[state.activeFocusable] ?? [];
  const focusedItem = state.focusableToFocusedItem[state.activeFocusable];
  if (!focusedItem) {
    return { start: 0, end: 0 };
  }

  const focusedRange = focusableRanges[focusedItem - 1];
  const [start, end] = focusedRange.split(":").map((v) => Number(v));

  return { start, end };
}

type FocusableToChunkCount = Partial<{ [key in Focusable]: number }>;

function getFocusableToChunkCount(
  focusableToRanges: FocusableToRanges
): FocusableToChunkCount {
  const focusableToChunkCount: FocusableToChunkCount = {};

  Object.keys(focusableToRanges).forEach((focusable) => {
    const rangesCount = focusableToRanges[focusable as Focusable]!.length;
    focusableToChunkCount[focusable as Focusable]! = rangesCount;
  });

  return focusableToChunkCount;
}

function getIssuesNames(chunks: ReconcilableTextChunk[]): string[] {
  return chunks
    .map((chunk) => chunk.mergableData as ChunkMergableData[])
    .reduce((a, b) => a.concat(b), [])
    .filter((d) => d.issueName)
    .map((d) => d.issueName);
}

function getConclusionChunks(decision: Decision): ReconcilableTextChunk[] {
  return decision.conclusionTextChunk
    ? [
        {
          ...decision.conclusionTextChunk,
          mergableData: [{ focusable: "conclusion" }],
        },
      ]
    : [];
}

function getSearchChunks(
  decision: Decision,
  state: State
): ReconcilableTextChunk[] {
  if (state.query.length < 2) {
    return [];
  }

  return searchText(decision.document, state.query)
    .sort((a, b) => a.start - b.start)
    .map((chunk, i) => ({
      ...chunk,
      priority: 2,
      mergableData: [{ focusable: "search" }],
    }));
}

function getIssuesChunks(
  decision: Decision,
  state: State
): ReconcilableTextChunk[] {
  const visibleIssueGroups = decision.issueGroups.filter((issueGroup) =>
    isIssueCategoryVisible(issueGroup.category, state)
  );
  const issues = visibleIssueGroups.reduce<Issue[]>(
    (issues, issueGroup) => issues.concat(issueGroup.issues),
    []
  );
  return issues.reduce<ReconcilableTextChunk[]>((textChunks, issue) => {
    const issueChunks = issue.textChunks
      // .sort((a, b) => a.start - b.start) ??
      .map((chunk) => ({
        ...chunk,
        mergableData: [{ focusable: issue.category, issueName: issue.name }],
      }));

    return textChunks.concat(issueChunks);
  }, []);
}

function getChunkColor(chunk: ReconcilableTextChunk, state: State): Color {
  const chunkFocusables = getDistinctChunkFocusables(chunk);

  const isSearchChunk = chunkFocusables.some((f) => f.startsWith("search"));
  if (isSearchChunk) {
    return "strongYellow";
  }

  const isConclusionChunk = chunkFocusables.some((f) => f === "conclusion");
  if (isConclusionChunk) {
    return "transparent";
  }

  const hasSingleFocusable = chunkFocusables.length === 1;
  if (hasSingleFocusable) {
    return issueCategoryToColor[chunkFocusables[0] as IssueCategory];
  }

  if (isChunkFocused(chunk)) {
    return issueCategoryToColor[state.activeFocusable as IssueCategory];
  }

  return "grey";
}

function isIssueCategoryVisible(
  issueCategory: IssueCategory,
  state: State
): boolean {
  const visibilitySetting = state.issueCategoryToVisibility[issueCategory];
  const defaultSetting = true;

  return visibilitySetting ?? defaultSetting;
}

function isChunkFocused(chunk: ReconcilableTextChunk): boolean {
  return (chunk as any).isFocused ?? false;
}

function getDistinctChunkFocusables(chunk: ReconcilableTextChunk): Focusable[] {
  const focusables = (chunk.mergableData as ChunkMergableData[]).map(
    (id) => id.focusable
  );

  return [...new Set(focusables)];
}

import axios from "axios";

import {
  APICitation,
  APILicensingReport,
  APILicensingReportRequest,
  Citation,
  LicensingReport,
} from "./models";

export function fetchLicensingReport(request: APILicensingReportRequest) {
  return axios
    .get<APILicensingReport>("/api/v2/licensing-report.php", {
      params: request,
    })
    .then((response) => mapAPIToDomainLicensingReport(response.data));
}

function mapAPIToDomainLicensingReport(
  apiLicensingReport: APILicensingReport
): LicensingReport {
  return {
    ...apiLicensingReport,
    citations: apiLicensingReport.citations.map(mapAPIToDomainCitation),
  } as LicensingReport;
}

function mapAPIToDomainCitation(apiCitation: APICitation): Citation {
  return {
    ...apiCitation,
    citationDate: new Date(apiCitation.citationDate * 1000),
  };
}

import React from "react";

const Download = ({ color }: { color?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    width={24}
    height={24}
    fill={color ?? "#666"}
  >
    <path d="M25.9 18.8V25H4.1v-6.2H2.8v7.5h24.4v-7.5z" />
    <path d="M21.8 14.6l-.9-.9-5.3 5.2V3.7h-1.2v15.5l-5.5-5.5-.9.9 6.9 6.8z" />
  </svg>
);

export default Download;

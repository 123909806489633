// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rmAH5K1Zpu6-9JIR40h-X{position:relative}.rmAH5K1Zpu6-9JIR40h-X div[class$=\"-control\"]{min-height:24px;font-size:13px;line-height:15px}.rmAH5K1Zpu6-9JIR40h-X div[class$=\"-indicatorContainer\"]{padding:0}.rmAH5K1Zpu6-9JIR40h-X div[class$=\"-placeholder\"]{font-size:13px;line-height:15px}.rmAH5K1Zpu6-9JIR40h-X div[class$=\"-singleValue\"]{margin:0;padding:0;font-size:13px;line-height:16px}.rmAH5K1Zpu6-9JIR40h-X div[class$=\"-Input\"]{margin:-2px 2px 0;padding:0}.rmAH5K1Zpu6-9JIR40h-X div[class$=\"-Input\"] div{font-size:13px;line-height:15px}.rmAH5K1Zpu6-9JIR40h-X div[class$=\"-menu\"]{font-size:14px;line-height:20px;vertical-align:middle !important}.rmAH5K1Zpu6-9JIR40h-X div[class$=\"-menu\"] div{box-sizing:border-box}\n", "",{"version":3,"sources":["webpack://./src/components/AdminPanel/DecisionPage/SelectWithSuggestions.module.sass"],"names":[],"mappings":"AAAA,uBACE,iBAAkB,CADpB,8CAII,eAAgB,CAChB,cAAe,CACf,gBAAiB,CANrB,yDASI,SAAU,CATd,kDAYI,cAAe,CACf,gBAAiB,CAbrB,kDAgBI,QAAS,CACT,SAAU,CACV,cAAe,CACf,gBAAiB,CAnBrB,4CAsBI,iBAAkB,CAClB,SAAU,CAvBd,gDA0BM,cAAe,CACf,gBAAiB,CA3BvB,2CA8BI,cAAe,CACf,gBAAiB,CACjB,gCAAiC,CAhCrC,+CAmCM,qBAAsB","sourcesContent":[".smallSelectContainer {\n  position: relative;\n\n  div[class$=\"-control\"] {\n    min-height: 24px;\n    font-size: 13px;\n    line-height: 15px; }\n\n  div[class$=\"-indicatorContainer\"] {\n    padding: 0; }\n\n  div[class$=\"-placeholder\"] {\n    font-size: 13px;\n    line-height: 15px; }\n\n  div[class$=\"-singleValue\"] {\n    margin: 0;\n    padding: 0;\n    font-size: 13px;\n    line-height: 16px; }\n\n  div[class$=\"-Input\"] {\n    margin: -2px 2px 0;\n    padding: 0;\n\n    div {\n      font-size: 13px;\n      line-height: 15px; } }\n\n  div[class$=\"-menu\"] {\n    font-size: 14px;\n    line-height: 20px;\n    vertical-align: middle !important;\n\n    div {\n      box-sizing: border-box; } } }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"smallSelectContainer": "rmAH5K1Zpu6-9JIR40h-X"
};
export default ___CSS_LOADER_EXPORT___;

import Text from "components/Text";
import React, { CSSProperties, useCallback, useRef } from "react";
import { InfoIcon, X as XIcon } from "../../../../components/Icons";
import Tooltip from "../Tooltip/Tooltip";
import { Gap } from "../utils";
import cn from "classnames";
import styles from "./module.sass";
import { Place } from "react-tooltip";

export const Label = ({
  label,
  children,
  tooltip,
  tooltipPlace,
  className,
  style,
}: {
  label?: string;
  children: React.ReactNode;
  tooltip?: string;
  tooltipPlace?: Place;
  className?: string;
  style?: CSSProperties;
}) => {
  if (!label) {
    return <>{children}</>;
  }
  return (
    <div className={className}>
      <div className={styles.labelContainer} style={style}>
        <Text color={"label"}>{label}</Text>

        {tooltip && (
          <>
            <Gap size={2} />
            <Tooltip message={tooltip} place={tooltipPlace}>
              <InfoIcon color="#000" />
            </Tooltip>
          </>
        )}
      </div>
      {children}
    </div>
  );
};

const TextInput = ({
  label,
  value,
  onChange,
  onBlur,
  placeholder,
  className,
  onEnterClick,
  isReadOnly,
}: {
  label?: string;
  value?: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  placeholder?: string;
  className?: string;
  onEnterClick?: () => void;
  isReadOnly?: boolean;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const onResetClick = useCallback(() => {
    inputRef?.current?.focus();
    onChange("");
  }, [onChange]);

  const onKeyDown = useCallback(
    (e) => {
      if (e.key === "Enter") {
        onEnterClick && onEnterClick();
      }
    },
    [onEnterClick]
  );

  return (
    <Label label={label} className={className}>
      <div className={cn(styles.root, !label && className)}>
        <input
          ref={inputRef}
          className={styles.input}
          type={"search"}
          autoComplete={"off"}
          autoCapitalize={"none"}
          autoCorrect={"off"}
          spellCheck={"false"}
          value={value}
          placeholder={placeholder ?? label}
          readOnly={isReadOnly ?? false}
          onChange={(e) => onChange(e.target.value)}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
        />

        {!!value && (
          <button
            className={styles.button}
            type="button"
            tabIndex={-1}
            onClick={onResetClick}
          >
            <XIcon />
          </button>
        )}
      </div>
    </Label>
  );
};

export default TextInput;

import React, {
  ReactNode,
  useRef,
  useState,
  createContext,
  useContext,
} from "react";
import cn from "classnames";

import useClickOutside from "hooks/useClickOutside";

import styles from "./Menu.module.sass";

type MenuContextType = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const MenuContext = createContext<MenuContextType | undefined>(undefined);

const Icon = ({ variant }: { variant: "appDrawer" | "more" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox={variant === "more" ? "0 0 19 33" : "0 0 34 33"}
    height={33}
    width={variant === "more" ? 19 : 34}
  >
    {variant === "more" ? (
      <>
        <circle className={styles.dots} cx="10" cy="16" r="2" />
        <circle className={styles.dots} cx="10" cy="8" r="2" />
        <circle className={styles.dots} cx="10" cy="24" r="2" />
      </>
    ) : (
      <>
        <circle className={styles.dots} cx="17" cy="16" r="2" />
        <circle className={styles.dots} cx="25" cy="16" r="2" />
        <circle className={styles.dots} cx="25" cy="8" r="2" />
        <circle className={styles.dots} cx="17" cy="8" r="2" />
        <circle className={styles.dots} cx="9" cy="8" r="2" />
        <circle className={styles.dots} cx="9" cy="16" r="2" />
        <circle className={styles.dots} cx="9" cy="24" r="2" />
        <circle className={styles.dots} cx="17" cy="24" r="2" />
        <circle className={styles.dots} cx="25" cy="24" r="2" />
      </>
    )}
  </svg>
);

const List = ({ children }: { children: ReactNode }) => (
  <ul className={styles.list}>{children}</ul>
);

const ListItem = ({
  href,
  children,
  id,
  isExternalLink = false,
  onClick = () => {},
}: {
  href: string;
  children: ReactNode;
  id?: string;
  isExternalLink?: boolean;
  onClick?: () => void;
}) => {
  const menuContext = useContext(MenuContext);
  if (!menuContext) {
    throw new Error("This component must be used within a <Menu> component.");
  }

  return (
    <li id={id} key={href}>
      <a
        href={href}
        className={styles.listItem}
        rel={isExternalLink ? "noopener norreferer" : undefined}
        target={isExternalLink ? "_blank" : undefined}
        onClick={() => {
          menuContext.setIsOpen(false);
          onClick();
        }}
      >
        {children}
      </a>
    </li>
  );
};

const Divider = ({ isVertical = false }: { isVertical?: boolean }) => (
  <div
    className={cn(styles.divider, isVertical && styles.divider$vertical)}
  ></div>
);

function Menu({
  trigger,
  children,
  dropdownPosition = "bottomLeft",
}: {
  trigger: ReactNode;
  children: ReactNode;
  dropdownPosition?: "bottomLeft" | "bottomRight";
}) {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  useClickOutside(menuRef, () => setIsOpen(false));

  return (
    <MenuContext.Provider value={{ isOpen, setIsOpen }}>
      <div
        className={cn(styles.menu, isOpen && styles.menu$open)}
        ref={menuRef}
      >
        <button
          type="button"
          className={styles.trigger}
          onClick={() => setIsOpen((isOpen) => !isOpen)}
        >
          {trigger}
        </button>
        {isOpen && (
          <div
            className={cn(
              styles.dropdown,
              dropdownPosition === "bottomRight" && styles.dropdown$right,
              dropdownPosition === "bottomLeft" && styles.dropdown$left
            )}
          >
            {children}
          </div>
        )}
      </div>
    </MenuContext.Provider>
  );
}

Menu.Icon = Icon;
Menu.List = List;
Menu.ListItem = ListItem;
Menu.Divider = Divider;

export default Menu;

import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Switch, Route } from "react-router-dom";
import { useQuery } from "react-query";

import useAnalytics from "hooks/useAnalytics";
import useExperienceTracking from "hooks/useExperienceTracking";
import { makeAnalyticsGloballyAccessible } from "integration/analytics";
import usePage from "hooks/usePage";
import useIsBehindLogin from "hooks/useIsBehindLogin";
import Footer from "components/Footer";
import Navbar, { EmptyNavbar } from "components/Navbar";
import Home from "components/Home";
import MySavedWork from "components/MySavedWork";
import { fetchUser } from "domain/users";
import PTABDecisionsPage from "components/PTABDecisions/PTABDecisionsPage";
import DecisionsByIssuePage from "components/PTABDecisions/DecisionsByIssuePage";
import OpinionPage from "components/PTABDecisions/OpinionPage";
import DecisionPage from "components/AdminPanel/DecisionPage";
import ApplicationLookupPage from "components/AdminPanel/ApplicationLookupPage";
import MonthlyReviewPage from "components/AdminPanel/MonthlyReviewPage";
import DailyReviewPage from "components/AdminPanel/DailyReviewPage";
import { ToastProvider } from "components/Toast";
import TrialWelcome from "organisms/TrialWelcome";
import Timeline from "templates/Timeline";
import useApplication, {
  Options as UseApplicationOptions,
} from "hooks/useApplication";

import styles from "./Portals.module.sass";

export default function App() {
  const page = usePage();
  const isBehindLogin = useIsBehindLogin();

  const {
    status,
    data: user,
    error,
  } = useQuery("user", fetchUser, {
    enabled: isBehindLogin,
  });

  useAnalytics(user);
  useExperienceTracking(user);
  makeAnalyticsGloballyAccessible();

  const { applicationNumber, applicationId } =
    page.name === "quickPAIR"
      ? page
      : { applicationNumber: null, applicationId: null };
  const {
    status: applicationStatus,
    application,
    error: applicationError,
  } = useApplication({
    applicationId,
    applicationNumber,
    isEnabled:
      page.name === "quickPAIR" &&
      (applicationId !== null || applicationNumber !== null),
  } as UseApplicationOptions);
  if (applicationError) {
    console.error(applicationError);
  }

  const [isTimelineCollapsed, setIsTimelineCollapsed] = useState(true);

  const appEl = document.getElementById("react-app");
  const homeEl = document.getElementById("react-home");
  const headerEl = document.getElementById("react-header");
  const footerEl = document.getElementById("react-footer");
  const timelineEl = document.getElementById("react-timeline");

  return (
    <>
      {["idle", "error"].includes(status) &&
        headerEl &&
        ReactDOM.createPortal(<EmptyNavbar />, headerEl)}

      {status === "success" &&
        headerEl &&
        user &&
        ReactDOM.createPortal(<Navbar user={user} />, headerEl)}

      {status === "success" &&
        user &&
        homeEl &&
        ReactDOM.createPortal(
          <ToastProvider>
            <main>
              <Switch>
                <Route path="/my-saved-work">
                  <MySavedWork user={user} />
                </Route>

                {user.permissions.search.ptab && (
                  <Route path="/opinion" component={OpinionPage} />
                )}
                {user.permissions.search.ptab && (
                  <Route path="/decisions" component={DecisionsByIssuePage} />
                )}
                {user.permissions.search.ptab && (
                  <Route path="/ptab-decisions" component={PTABDecisionsPage} />
                )}
                {user.permissions.ptabDecisionReview && (
                  <Route path="/decision">
                    <DecisionPage user={user} />
                  </Route>
                )}
                {user.permissions.ptabDecisionReview && (
                  <Route
                    path="/application-lookup"
                    component={ApplicationLookupPage}
                  />
                )}
                {user.permissions.ptabDecisionReview && (
                  <Route path="/monthly-review" component={MonthlyReviewPage} />
                )}
                {user.permissions.ptabDecisionReview && (
                  <Route path="/daily-review" component={DailyReviewPage} />
                )}

                <Route path="/">
                  <Home
                    lastLogin={user.lastLogin}
                    searchPermissions={user.permissions.search}
                    hasSmartReports={user.permissions.smartReports}
                    hasLicensingReports={user.permissions.licensingReports}
                  />
                </Route>
              </Switch>
            </main>
          </ToastProvider>,
          homeEl
        )}

      {footerEl &&
        ReactDOM.createPortal(
          <Footer
            variant={
              ["login", "passwordReset"].includes(page.name) ? "white" : "grey"
            }
          />,
          footerEl
        )}

      {appEl &&
        ReactDOM.createPortal(
          <Switch>
            <Route path="/trial-welcome" component={TrialWelcome} />
          </Switch>,
          appEl
        )}

      {timelineEl &&
        application &&
        ReactDOM.createPortal(
          <div className={styles.portal}>
            <Timeline
              today={new Date()}
              application={application}
              isCollapsed={isTimelineCollapsed}
              onIsCollapsedChange={(isCollapsed) =>
                setIsTimelineCollapsed(isCollapsed)
              }
            />
          </div>,
          timelineEl
        )}
    </>
  );
}

import React, {
  forwardRef,
  HTMLAttributes,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { v4 as uuid } from "uuid";

import {
  Application,
  linkToApplicationDocument,
  linkToPatentDocument,
} from "domain/applications";
import Text, { Props as TextProps } from "atoms/Text";
import Anchor from "atoms/Anchor";
import { capitalize } from "domain/strings";
import {
  PdfIcon as PDFIcon,
  ExternalLink as ExternalLinkIcon,
} from "components/Icons";
import Tooltip from "atoms/Tooltip";

import styles from "./module.sass";

export interface Props {
  application: Application;
  areStatisticsLinksInternal?: boolean;
}

export default function Info({
  application,
  areStatisticsLinksInternal = true,
}: Props) {
  const {
    applicationNumber,
    readableApplicationNumber,
    patentNumber,
    readablePatentNumber,
    title,
    status,
    artUnit,
    examiner,
    pdfLinkEnabled,
  } = application;
  const titleRef = useRef<HTMLDivElement>(null);
  const [requiredTitleRowCount, setRequiredTitleRowCount] = useState(1);

  const availableTitleRowCount = status === "patented" ? 1 : 2;
  const usedTitleRowCount =
    requiredTitleRowCount <= availableTitleRowCount ? requiredTitleRowCount : 1;
  // const examinerViewModel = examiner ? new ExaminerViewModel(examiner) : null;

  useLayoutEffect(() => {
    const hasEllipsis = titleRef.current
      ? titleRef.current.offsetWidth < titleRef.current.scrollWidth
      : false;

    if (hasEllipsis) {
      setRequiredTitleRowCount(2);
    }
  }, [titleRef.current]);

  return (
    <section style={{ display: "flex" }}>
      <div
        style={{
          boxSizing: "border-box",
          display: "grid",
          gridAutoFlow: "column",
          gridTemplateColumns: "max-content 1.5fr max-content 1fr",
          gridTemplateRows: "repeat(5, 21px)",
          columnGap: 6,
          rowGap: 3,
        }}
      >
        <Label>Application:</Label>
        <Label style={{ gridRow: usedTitleRowCount === 1 ? "auto" : "span 2" }}>
          Title:
        </Label>
        <Label>Status:</Label>
        {readablePatentNumber && <Label>Patent #:</Label>}
        <Label
          style={{
            gridRow:
              usedTitleRowCount === 1 && availableTitleRowCount === 2
                ? "span 2"
                : "auto",
          }}
        >
          Art Unit:
        </Label>
        {readableApplicationNumber && applicationNumber && pdfLinkEnabled ? (
          <DocumentLink url={linkToApplicationDocument(applicationNumber)}>
            {readableApplicationNumber}
          </DocumentLink>
        ) : (
          readableApplicationNumber && (
            <Value>{readableApplicationNumber}</Value>
          )
        )}
        <Value
          ref={titleRef}
          style={{
            display: usedTitleRowCount === 1 ? "block" : "-webkit-box",
            height: "min-content",
            overflow: "hidden",
            gridRow: usedTitleRowCount === 1 ? "auto" : "span 2",
            whiteSpace: usedTitleRowCount === 1 ? "nowrap" : undefined,
            textOverflow: usedTitleRowCount === 1 ? "ellipsis" : undefined,
            WebkitLineClamp: usedTitleRowCount === 1 ? 1 : 2,
            WebkitBoxOrient: "vertical",
            // lineHeight: requiredTitleRowCount === 1 ? "auto" : "1.5em",
          }}
        >
          {title}
        </Value>
        <Status>{status}</Status>
        {readablePatentNumber && patentNumber && pdfLinkEnabled ? (
          <Value>
            <DocumentLink url={linkToPatentDocument(patentNumber)}>
              {readablePatentNumber}
            </DocumentLink>
          </Value>
        ) : (
          readablePatentNumber && <Value>{readablePatentNumber}</Value>
        )}
        <div
          style={{
            gridRow:
              usedTitleRowCount === 1 && availableTitleRowCount === 2
                ? "span 2"
                : "auto",
          }}
        >
          {artUnit ? (
            <Anchor
              href={artUnit.url}
              style={{ fontWeight: "bold" }}
              target="_blank"
            >
              {artUnit.name}
              {!areStatisticsLinksInternal && (
                <ExternalLinkIcon style={{ marginLeft: 5 }} />
              )}
            </Anchor>
          ) : (
            "-"
          )}
        </div>

        <Label style={{ paddingLeft: 45 }}>Examiner:</Label>
        <Label style={{ paddingLeft: 45 }}>Examiner's Allowance Rate:</Label>
        <Label style={{ paddingLeft: 45 }}>Examiner's ETA:</Label>
        <Label style={{ paddingLeft: 45 }}>
          Examiner's Avg. Time to Allowance:
        </Label>
        <Label style={{ paddingLeft: 45 }}>
          Examiner's Avg. OAs to Allowance:
        </Label>
        <ExaminerField
          examiner={examiner}
          areStatisticsLinksInternal={areStatisticsLinksInternal}
        />
        <ExaminerAllowanceRate examiner={examiner} />
        <ExaminerETA examiner={examiner} />
        <ExaminerAverageTimeToAllowance examiner={examiner} />
        <ExaminerAverageOfficeActionsToAllowance examiner={examiner} />
      </div>
    </section>
  );
}

function Label(props: TextProps) {
  return (
    <Text
      style={{ boxSizing: "border-box", whiteSpace: "nowrap" }}
      {...props}
    />
  );
}

const Value = forwardRef<HTMLDivElement, TextProps>((props, ref) => (
  <div ref={ref} weight="bold" className={styles.value} {...props} />
));

function Status({ children: status }: { children: Application["status"] }) {
  const color = {
    abandoned: "#d31622",
    pending: "#212121",
    patented: "#2e7d32",
    unknown: "#fff",
  }[status];

  return <Value style={{ color }}>{capitalize(status)}</Value>;
}

function ExaminerField({
  examiner,
  areStatisticsLinksInternal,
}: {
  examiner: Application["examiner"];
  areStatisticsLinksInternal: boolean;
}) {
  if (!examiner) {
    return <Value>Central Docket</Value>;
  }

  return (
    <Anchor
      href={examiner.url}
      style={{
        display: "flex",
        alignItems: "baseline",
        fontWeight: "bold",
        whiteSpace: "nowrap",
      }}
      target="_blank"
    >
      {examiner.name}
      {!examiner.isActive && <InactiveExaminerMark />}
      {!areStatisticsLinksInternal && (
        <ExternalLinkIcon style={{ marginLeft: 5 }} />
      )}
    </Anchor>
  );
}

function ExaminerAllowanceRate({
  examiner,
}: {
  examiner: Application["examiner"];
}) {
  if (!examiner?.allowanceRate) {
    return <Value>N/A</Value>;
  }

  const { allowanceRate } = examiner;
  const percentage = (allowanceRate * 100).toFixed(1);
  const color = getAllowanceRateColor(allowanceRate);

  return (
    <Value>
      {`${percentage} %`} <Circle style={{ backgroundColor: color }} />
    </Value>
  );
}

function ExaminerETA({ examiner }: { examiner: Application["examiner"] }) {
  if (!examiner || !examiner.ETA) {
    return <Value>N/A</Value>;
  }

  return (
    <Value>
      {examiner.ETA}{" "}
      <Circle style={{ backgroundColor: getETAColor(examiner.ETA) }} />
    </Value>
  );
}

/**
 * @todo use ITimelineViewModel
 */
function ExaminerAverageTimeToAllowance({
  examiner,
}: {
  examiner: Application["examiner"];
}) {
  if (!examiner?.expectedApprovalWindow?.averageInMonths) {
    return <Value>N/A</Value>;
  }

  const { averageInMonths } = examiner.expectedApprovalWindow;
  const years = Math.floor(averageInMonths / 12);
  const months = averageInMonths % 12;
  const yearsPart = years ? `${years} years` : "";
  const monthsPart = months ? `, ${months} months` : "";

  return <Value>{`${yearsPart}${monthsPart}`}</Value>;
}

function ExaminerAverageOfficeActionsToAllowance({
  examiner,
}: {
  examiner: Application["examiner"];
}) {
  if (!examiner?.averageOfficeActionsToAllowance) {
    return <Value>N/A</Value>;
  }
  return <Value>{examiner.averageOfficeActionsToAllowance.toFixed(1)}</Value>;
}

function Circle({ style, ...rest }: HTMLAttributes<HTMLSpanElement>) {
  return (
    <span
      style={{
        marginLeft: 4,
        marginTop: -2,
        alignSelf: "center",
        width: 8,
        height: 8,
        borderRadius: "50%",
        ...style,
      }}
      {...rest}
    />
  );
}

function InactiveExaminerMark() {
  const tooltipId = uuid();

  return (
    <>
      <Circle
        style={{
          width: 11,
          height: 11,
          marginLeft: 6,
          backgroundColor: "#e9171f",
          outline: "#f9a8aa solid 2px",
        }}
        data-tip="Inactive"
        data-for={tooltipId}
      />
      <Tooltip id={tooltipId} />
    </>
  );
}

function getAllowanceRateColor(allowanceRate: number) {
  if (allowanceRate <= 0.33) {
    return "#d31622";
  }
  if (allowanceRate > 0.66) {
    return "#489f50";
  }
  return "#fdc23c";
}

function getETAColor(ETA: number) {
  if (ETA >= 6) {
    return "#d31622";
  }
  if (ETA <= 2.5) {
    return "#489f50";
  }
  return "#fdc23c";
}

function DocumentLink({
  children: label,
  url,
}: {
  children: string;
  url: string;
}) {
  return (
    <Value
      style={{
        alignItems: "unset",
      }}
    >
      {label}
      <Anchor href={url as string} target="_blank">
        <PDF />
      </Anchor>
    </Value>
  );
}

function PDF() {
  return <PDFIcon width={20} height={20} style={{ marginLeft: 8 }} />;
}

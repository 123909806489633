import React, { useState } from "react";

import { ReleaseNote } from "domain/releaseNotes";
import useReleaseNotesAdmin from "hooks/useReleaseNotesAdmin";
import ReleaseNotesAdmin from "organisms/ReleaseNotesAdmin";
import useIsLoggedIn from "hooks/useIsLoggedIn";
import useUser from "hooks/useUser";
import PageLoader from "molecules/PageLoader";
import Navbar, { EmptyNavbar } from "components/Navbar";
import Footer from "components/Footer";
import ReleaseNotesForm from "organisms/ReleaseNotesForm";
import { DeletionModal } from "components/Modal";
import SkipNavigationLink from "atoms/SkipNavigationLink";

import styles from "./module.sass";
import Text from "atoms/Text";

export default function ReleaseNotesAdminPage() {
  const [mode, setMode] = useState<"listing" | "creating" | "editing">(
    "listing"
  );
  const [
    releaseNoteToDelete,
    setReleaseNoteToDelete,
  ] = useState<ReleaseNote | null>(null);

  const [
    editedReleaseNotes,
    setEditedReleaseNotes,
  ] = useState<ReleaseNote | null>(null);

  const { status: isLoggedInStatus, isLoggedIn } = useIsLoggedIn();
  if (isLoggedInStatus === "success" && !isLoggedIn) {
    redirectToLogin();
  }

  const { status: userStatus, user } = useUser();
  if (userStatus === "success" && !user?.permissions.releaseNotesAdmin) {
    redirectToLogin();
  }

  const {
    status: releaseNotesStatus,
    releaseNotes,
    mutations,
  } = useReleaseNotesAdmin();

  const isLoading =
    userStatus !== "success" ||
    isLoggedInStatus !== "success" ||
    releaseNotesStatus !== "success";

  const handleDelete = (releaseNote: ReleaseNote) => {
    mutations.deletion.mutate(releaseNote.id);
    setReleaseNoteToDelete(null);
  };
  const handleCreate = (
    newReleaseNote: Omit<ReleaseNote, "id" | "creationDate">
  ) => {
    mutations.creation.mutate(newReleaseNote);
    setMode("listing");
  };
  const handleEdit = (
    releaseNote: Omit<ReleaseNote, "id" | "creationDate">
  ) => {
    if (!editedReleaseNotes) {
      return;
    }
    mutations.edition.mutate({ ...editedReleaseNotes, ...releaseNote });
    setMode("listing");
  };

  return (
    <div className={styles.page}>
      <SkipNavigationLink />
      <PageLoader isLoading={isLoading} />
      {user ? <Navbar user={user} /> : <EmptyNavbar />}
      <main className={styles.container}>
        {mode === "listing" && (
          <ReleaseNotesAdmin
            releaseNotes={releaseNotes}
            onEdit={(releaseNote) => {
              setMode("editing");
              setEditedReleaseNotes(releaseNote);
            }}
            onDelete={(releaseNote) => setReleaseNoteToDelete(releaseNote)}
            onCreate={() => setMode("creating")}
          />
        )}
        {mode === "creating" && (
          <ReleaseNotesForm
            title="New Release Note"
            action="Save Release Note"
            onSubmit={handleCreate}
            onCancel={() => setMode("listing")}
          />
        )}
        {mode === "editing" && editedReleaseNotes && (
          <ReleaseNotesForm
            title="Edit Release Note"
            action="Save Changes"
            {...editedReleaseNotes}
            onSubmit={handleEdit}
            onCancel={() => setMode("listing")}
          />
        )}
      </main>
      <Footer variant="grey" position="static" />
      <DeletionModal
        message={
          <>
            <Text as="span">Do you really want to DELETE Release Note </Text>
            <Text as="span" weight="bold">
              {releaseNoteToDelete?.releaseDate.toLocaleString("default", {
                month: "long",
                day: "numeric",
                year: "numeric",
              })}
            </Text>
            <Text as="span">?</Text>
          </>
        }
        isOpen={releaseNoteToDelete !== null}
        onDelete={() => handleDelete(releaseNoteToDelete as ReleaseNote)}
        onDismiss={() => setReleaseNoteToDelete(null)}
      />
    </div>
  );
}

function redirectToLogin() {
  window.location.href = "/login.php";
}

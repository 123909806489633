import React from "react";

const Pen = () => (
  <svg
    width="30"
    height="30"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    fill="#666"
  >
    <path d="M22.5 3l-3.7 3.7 4.4 4.4 3.6-3.7L22.5 3zm.7 6l-2.3-2.3 1.6-1.6 2.3 2.3L23.2 9zM9.9 22.3L7.6 20 18.3 9.7l-1-1.1L5.5 20l-2.3 7 6.8-2.7L21.7 13l-1-1.1L9.9 22.3zm-3.2-1.2l2.1 2.1-3.2 1.3 1.1-3.4z" />
  </svg>
);

export default Pen;

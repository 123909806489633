import React from "react";

import { IssueCategory, IssueOutcome as IssueOutcomeType } from "domain/ptab";
import Highlight, { Props as HighlightProps } from "atoms/Highlight";
import Separator from "components/Separator";
import { Eye, EyeCrossed, SmallChevron } from "components/Icons";
import Button from "components/Button";
import colors from "style/colors.module.scss";
import Text from "atoms/Text";

import IssueOutcome from "./IssueOutcome";
import styles from "./IssueGroup.module.sass";

export interface Issue {
  name: string;
  outcome: IssueOutcomeType;
  isUnderlined?: boolean;
}

export type Props = {
  category: IssueCategory;
  issues: Issue[];
  focusedItem: number;
  itemCount: number;
  isShown: Boolean;
  onFocusChange: (focusedItem: number) => void;
  onToggle: (isShown: boolean) => void;
};

export default function IssueGroup({
  category,
  issues,
  focusedItem,
  itemCount,
  isShown,
  onFocusChange,
  onToggle,
}: Props) {
  const canGoToPrevious = isShown;
  const canGoToNext = isShown;

  const isFirstItemFocused = focusedItem === 1;
  const isUnfocused = focusedItem === 0;
  const isLastItemFocused = focusedItem === itemCount;

  const previousItem =
    isFirstItemFocused || isUnfocused ? itemCount : focusedItem - 1;
  const nextItem = isLastItemFocused ? 1 : focusedItem + 1;

  return (
    <section>
      <header
        style={{ display: "flex", alignItems: "center", marginBottom: 6 }}
      >
        <Highlight color={issueCategoryToColor[category]} isUnderlined>
          <Text weight="bold" style={{ padding: "0 8px" }}>
            {issueCategoryToTitle[category]}
          </Text>
        </Highlight>
        <Text
          color="grey900"
          style={{
            marginLeft: "auto",
            color: isShown ? colors.grey850 : colors.grey350,
          }}
        >
          {focusedItem}/{itemCount}
        </Text>
        <Separator style={{ margin: "0 6px 0 15px", height: 24 }} />
        <Button
          size="xxxs"
          isDisabled={!canGoToPrevious}
          onClick={() => onFocusChange(previousItem)}
        >
          <SmallChevron direction="up" {...getIconProps(!canGoToPrevious)} />
        </Button>
        <Button
          size="xxxs"
          style={{ marginLeft: 8 }}
          isDisabled={!canGoToNext}
          onClick={() => onFocusChange(nextItem)}
        >
          <SmallChevron direction="down" {...getIconProps(!canGoToNext)} />
        </Button>
        <Button
          size="xxxs"
          style={{ marginLeft: 8 }}
          onClick={() => onToggle(!isShown)}
        >
          {isShown ? (
            <Eye style={{ fill: colors.grey350 }} />
          ) : (
            <EyeCrossed style={{ fill: colors.grey350 }} />
          )}
        </Button>
      </header>
      <ul className={styles.list}>
        {issues.map((issue, i) => {
          const isLast = i === issues.length - 1;

          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: isLast ? 0 : 4,
              }}
            >
              <Text
                as="li"
                size={13}
                key={issue.name}
                color={issue.isUnderlined ? "black" : "grey850"}
                style={{
                  textDecoration: issue.isUnderlined ? "underline" : "none",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  paddingBottom: 4,
                }}
              >
                {issue.name}
              </Text>
              {issue.outcome !== "notAvailable" && (
                <IssueOutcome
                  outcome={issue.outcome}
                  style={{ marginLeft: 8, marginBottom: 2 }}
                />
              )}
            </div>
          );
        })}
      </ul>
    </section>
  );
}

export const issueCategoryToColor: {
  [key in IssueCategory]: HighlightProps["color"];
} = {
  "101": "cyan",
  "102": "red",
  "103": "green",
  "112": "olive",
  "112a": "yellow",
  "112b": "orange",
  "112d": "blue",
  "112f": "brown",
  improperMarkoushGrouping: "teal",
  obviousnessTypeDoublePatenting: "purple",
};

const issueCategoryToTitle: { [key in IssueCategory]: string } = {
  "101": "101 Issues",
  "102": "102 Issues",
  "103": "103 Issues",
  "112": "112 Issues",
  "112a": "112(a) Issues",
  "112b": "112(b) Issues",
  "112d": "112(d) Issues",
  "112f": "112(f) Issues",
  improperMarkoushGrouping: "Improper Markoush Issues",
  obviousnessTypeDoublePatenting: "Obviousness Issues",
};

function getIconProps(isDisabled: boolean) {
  return {
    style: {
      fill: isDisabled ? colors.grey350 : colors.grey850,
    },
  };
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3d8ECP0Us3-Q5Yv_G1TLYy{height:100vh;display:flex;flex-direction:column;background-color:#fff}._1aYZ7FiQ4auhVbsjJ4dTll{width:100%;max-width:1600px;margin:0 auto;padding:10px 30px 40px 30px;box-sizing:border-box;display:flex;justify-content:center;overflow:hidden}\n", "",{"version":3,"sources":["webpack://./src/templates/ArgumentFinderTemplate/module.sass","webpack://./src/style/_colors.sass"],"names":[],"mappings":"AAEA,yBACI,YAAa,CACb,YAAa,CACb,qBAAsB,CAEtB,qBCPQ,CDOmB,yBAG3B,UAAW,CACX,gBAAiB,CACjB,aAAc,CACd,2BAA4B,CAC5B,qBAAsB,CACtB,YAAa,CACb,sBAAuB,CACvB,eAAgB","sourcesContent":["@import 'style/colors';\n\n.page {\n    height: 100vh;\n    display: flex;\n    flex-direction: column;\n\n    background-color: $white; }\n\n.container {\n    width: 100%;\n    max-width: 1600px;\n    margin: 0 auto;\n    padding: 10px 30px 40px 30px;\n    box-sizing: border-box;\n    display: flex;\n    justify-content: center;\n    overflow: hidden; }\n","$white: #fff;\n$black: #000;\n\n$grey900: #212121;\n$grey850: #373737;\n$grey800: #424242;\n$grey700: #616161;\n$grey600: #757575;\n$grey500: #9e9e9e;\n$grey400: #bdbdbd;\n$grey350: #d5d5d5;\n$grey300: #e0e0e0;\n$grey200: #eee;\n$grey100: #f5f5f5;\n$grey50: #fafafa;\n\n$darkBlue800: #132a43;\n$darkBlue700: #1a304d;\n\n$darkCyan600: #465a63;\n\n$blue900: #0D47a1;\n$blue700: #1976d2;\n$blue500: #2196f3;\n$blue50: #e3f2fd;\n\n$lightBlue800: #006ebb;\n$lightBlue600: #039be5;\n$lightBlue500: #03a9f4;\n$lightBlue300: #4fc3f7;\n\n$blueGray900: #455a64;\n$blueGray800: #546e7a;\n$blueGray600: #8c9eb1;\n$blueGray400: #acbac8;\n$blueGray100: #eceff1;\n\n$red700: #d60019; // errors, alerts\n$red500: #f6271c; // brand\n$red400: #f3403e;\n\n$green600: #43a047;\n$green400: #66bb6a;\n\n$yellow800: #f9a825;\n\n$orange500: #ff9800;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "_3d8ECP0Us3-Q5Yv_G1TLYy",
	"container": "_1aYZ7FiQ4auhVbsjJ4dTll"
};
export default ___CSS_LOADER_EXPORT___;

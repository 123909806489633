export enum DecisionTypes {
  Informative = "Informative",
  Precedential = "Precedential",
  Final = "Final",
}

export enum OutcomeRequestTypes {
  ExaminerReversed = "Examiner Reversed",
  ExaminerAffirmed = "Examiner Affirmed",
  ExaminerAffirmedInPart = "Examiner Affirmed in Part",
  NotAvailable = "Not available",
}

export type ArgumentType = {
  id: number;
  parent_id: number;
  is_issue: number;
  name: string;
  full_string: string;
  tree_lvl: number;
  reversed: number;
  affirmed: number;
  affirmed_in_part: number;
  decision_count?: number;
  isChecked?: boolean;
};

export type ExaminerType = {
  id: number;
  name: string;
};

export type LawFirmType = {
  id: number;
  name: string;
};

export type JudgeType = {
  id?: number;
  name: string;
};

export type ArtUnitType = {
  id: number;
  name: string;
};

export type OpinionType = {
  id: number;
  opinion_text: string;
  application_number: string;
  application_number_link: string;
  appeal_number: number;
  decision_date: string;
  case_name: string;
  filing_date: string;
  group_art_unit: number;
  class: number;
  subclass: string;
  first_named_inventor: string;
  title_of_invention: string;
  examiner_name: string;
  examiner_id: number;
  panel_judges: string;
};

export type DecisionType = {
  id: string;
  application_number: string;
  application_number_link: string;
  case_name: string;
  decision_date: string;
  date: Date;
  issues: Array<string>;
  judges_names: Array<string>;
  type: string;
  outcomes?: Array<string>;
};

export type FetchDecisionsArgsType = {
  year?: string;
  issues: Array<number>;
  tags: Array<number>;
  artUnitValue: string;
  outcomeRequest?: OutcomeRequestTypes;
  decisionType?: DecisionTypes;
  decisionSearch?: string;
  judgeName?: string;
  lawFirmId?: string;
  customerNumber?: string;
  examinerId?: string;
};

import React from "react";

import useUser from "hooks/useUser";
import { User } from "domain/users";
import PageLoader from "molecules/PageLoader";
import MainContainer, { ContentContainer } from "atoms/Layout";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import BrowserExtensionSettings from "organisms/BrowserExtensionSettings";

export type Props = {
  hasBrowserExtension: User["hasActiveBrowserPlugin"];
};

const BrowserExtensionPage = () => {
  const { status, user, isLoggedOut } = useUser();

  if (isLoggedOut) {
    window.location.href = "/login.php?next=/browser-extension.php";
  }

  return (
    <MainContainer>
      <>
        <PageLoader isLoading={status === "loading"} />
        {user && <Navbar user={user} />}

        <ContentContainer>
          <>
            {user && (
              <BrowserExtensionSettings
                hasBrowserExtension={user.hasActiveBrowserPlugin}
              />
            )}
          </>
        </ContentContainer>

        <Footer position="static" />
      </>
    </MainContainer>
  );
};

export default BrowserExtensionPage;

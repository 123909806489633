import React, { HTMLAttributes } from "react";
import cn from "classnames";

import styles from "./module.sass";

const Separator = (props: HTMLAttributes<HTMLDivElement>) => (
  <div {...props} className={cn(styles.separator, props.className)}></div>
);

export default Separator;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._7QRxjgXTOTMuw5QYjfrlw{z-index:100;position:fixed;right:0;left:0;top:0;height:100vh;display:flex;align-items:flex-end;justify-content:flex-end;background-color:transparent;pointer-events:none}.ou6u5WJKKQRewNXg-C8Li{background-color:#ebeef0;padding:20px;margin:30px;max-width:30%;border-left:4px solid;box-shadow:2px 2px 23px 13px rgba(0,0,0,0.1)}._1DoNoquWDKhmlKTtzEzIW5{border-color:#4fc3f6}._1bdmZkg3LcVi8ZToop1Rna{border-color:#fed518}._3QPQaairVwaAOtyqyr_1OS{border-color:#e43935}._10yTILRV2-pu8eMd8t4xlT{border-color:#64ba6b}\n", "",{"version":3,"sources":["webpack://./src/components/Toast/module.sass"],"names":[],"mappings":"AACA,wBACI,WAAY,CACZ,cAAe,CACf,OAAQ,CACR,MAAO,CACP,KAAM,CACN,YAAa,CACb,YAAa,CACb,oBAAqB,CACrB,wBAAyB,CACzB,4BAA6B,CAC7B,mBAAoB,CAAG,uBAGvB,wBAAyB,CACzB,YAAa,CACb,WAAY,CACZ,aAAc,CACd,qBAAsB,CAGtB,4CAAiD,CAEjD,yBACI,oBAAqB,CAAG,yBAGxB,oBAAqB,CAAG,yBAGxB,oBAAqB,CAAG,yBAGxB,oBAAqB","sourcesContent":["\n.toastContainer {\n    z-index: 100;\n    position: fixed;\n    right: 0;\n    left: 0;\n    top: 0;\n    height: 100vh;\n    display: flex;\n    align-items: flex-end;\n    justify-content: flex-end;\n    background-color: transparent;\n    pointer-events: none; }\n\n.toast {\n    background-color: #ebeef0;\n    padding: 20px;\n    margin: 30px;\n    max-width: 30%;\n    border-left: 4px solid;\n\n    -webkit-box-shadow: 2px 2px 23px 13px rgba(0, 0, 0, 0.10);\n    box-shadow: 2px 2px 23px 13px rgba(0, 0, 0, 0.10);\n\n    &$info {\n        border-color: #4fc3f6; }\n\n    &$warning {\n        border-color: #fed518; }\n\n    &$error {\n        border-color: #e43935; }\n\n    &$success {\n        border-color: #64ba6b; } }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toastContainer": "_7QRxjgXTOTMuw5QYjfrlw",
	"toast": "ou6u5WJKKQRewNXg-C8Li",
	"toast$info": "_1DoNoquWDKhmlKTtzEzIW5",
	"toast$warning": "_1bdmZkg3LcVi8ZToop1Rna",
	"toast$error": "_3QPQaairVwaAOtyqyr_1OS",
	"toast$success": "_10yTILRV2-pu8eMd8t4xlT"
};
export default ___CSS_LOADER_EXPORT___;

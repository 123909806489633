import React, { InputHTMLAttributes, ReactNode, forwardRef } from "react";
import cn from "classnames";

import InputError from "atoms/InputError";
import InputLabel from "atoms/InputLabel";
import Button from "components/Button";
import { Magnifier, X as XIcon } from "components/Icons";
import colors from "style/colors.module.scss";

import styles from "./Input.module.sass";

export interface Props
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  label: ReactNode;
  error: string;
  isClearable: boolean;
  onClear: () => void;
  onChange: (value: string) => void;
}

export const Input = forwardRef<HTMLInputElement, Props>(
  ({ label, value, error, isClearable, onChange, onClear, ...rest }, ref) => (
    <div className={cn(styles.label, error && styles.label$withError)}>
      <InputLabel>{label}</InputLabel>
      <div className={styles.inputWrapper}>
        <Magnifier style={{ margin: "0 6px 0 4px" }} />
        <input
          ref={ref}
          value={value}
          className={styles.input}
          onChange={(e) => onChange(e.target.value)}
          {...rest}
        />
        {isClearable && (
          <Button
            size="xxxs"
            onClick={onClear}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <XIcon width={9} height={9} fill={colors.darkCyan600} />
          </Button>
        )}
      </div>
      {error !== "" && (
        <InputError style={{ marginTop: 6 }}>{error}</InputError>
      )}
    </div>
  )
);

export default Input;

import React, { useState, MouseEventHandler } from "react";

import { format as formatDate } from "domain/dates";
import { Briefcase } from "domain/briefcases";
import Button from "components/Button";
import { PersonPlus, Bin, Pen } from "components/Icons";
import { DeletionModal } from "components/Modal";
import Separator from "components/Separator";
import TruncatedText from "atoms/TruncatedText";

import cn from "classnames";
import styles from "./module.sass";

export type Props = {
  briefcase: Briefcase;
  isReadOnly: boolean;
  onOpen: (briefcase: Briefcase) => void;
  onDelete: (briefcase: Briefcase) => void;
};

export default function DeletableBriefcaseCard({
  briefcase,
  isReadOnly,
  onOpen,
  onDelete,
}: Props) {
  const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false);

  const handleDelete = () => {
    setIsDeletionModalOpen(false);
    onDelete(briefcase);
  };

  const { name, activeMonitorCount, isCreatedByCurrentUser } = briefcase;

  return (
    <>
      <BriefcaseCard
        briefcase={briefcase}
        isReadOnly={isReadOnly}
        onOpen={onOpen}
        onDelete={() => setIsDeletionModalOpen(true)}
      />
      <DeletionModal
        message={
          activeMonitorCount ? (
            <>
              {isCreatedByCurrentUser
                ? "If you delete this briefcase, "
                : "If you unsubscribe from this briefcase, "}
              {isCreatedByCurrentUser
                ? `Prosecution Pattern Monitors (${activeMonitorCount.toLocaleString(
                    "en-us"
                  )} items) set for this briefcase by any user with whom access is shared will be deleted. `
                : `Prosecution Pattern Monitors (${activeMonitorCount.toLocaleString(
                    "en-us"
                  )} items) you set for this briefcase will be deleted. `}
              Are you SURE you want to delete briefcase{" "}
              <TruncatedText style={{ maxWidth: 185 }}>{name}</TruncatedText>?
            </>
          ) : (
            <>
              {isCreatedByCurrentUser
                ? "Are you SURE you want to delete briefcase "
                : "Are you SURE you want to unsubscribe from briefcase "}
              <TruncatedText style={{ maxWidth: 185 }}>{name}</TruncatedText>?
            </>
          )
        }
        isOpen={isDeletionModalOpen}
        onDismiss={() => setIsDeletionModalOpen(false)}
        onDelete={handleDelete}
      />
    </>
  );
}

function BriefcaseCard({ briefcase, isReadOnly, onOpen, onDelete }: Props) {
  const handleDelete: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    onDelete(briefcase);
  };

  const {
    name,
    applicationCount,
    dateCreated,
    dateUpdated,
    creator,
    isCreatedByCurrentUser,
    isLeader,
  } = briefcase;

  return (
    <>
      <button
        className={cn(styles.card, isLeader && styles.card$leader)}
        onClick={() => onOpen(briefcase)}
      >
        <header className={styles.header}>
          <TruncatedText className={styles.name}>{name}</TruncatedText>
          <div>{applicationCount.toLocaleString("en-us")} Applications</div>
        </header>

        <div className={styles.body}>
          <div className={styles.deEmphasized}>
            {isCreatedByCurrentUser ? "Created by :" : "Shared by :"}
          </div>
          <div>{creator}</div>
          <div className={styles.deEmphasized}>Created on:</div>
          <div>{formatDate(dateCreated, "Y-m-d")}</div>
          <div className={styles.deEmphasized}>Updated on:</div>
          <div>{formatDate(dateUpdated, "Y-m-d")}</div>
        </div>

        <footer className={styles.footer}>
          {isReadOnly ? (
            <>
              <Button
                size="xs"
                tooltip="Add User"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className={styles.button$fake}
              >
                <Button
                  className={styles.button}
                  size="xs"
                  tooltip="Add User"
                  isDisabled={isReadOnly}
                >
                  <PersonPlus />
                </Button>
              </Button>
              <Separator />
              <Button
                size="xs"
                tooltip="Edit Briefcase"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className={styles.button$fake}
              >
                <Button
                  className={styles.button}
                  size="xs"
                  tooltip="Edit Briefcase"
                  isDisabled={isReadOnly}
                >
                  <Pen />
                </Button>
              </Button>
              <Separator />
              <Button
                size="xs"
                tooltip="Delete"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className={styles.button$fake}
              >
                <Button
                  className={styles.button}
                  size="xs"
                  tooltip="Delete"
                  isDisabled={isReadOnly}
                >
                  <Bin />
                </Button>
              </Button>
            </>
          ) : (
            <>
              <Button
                className={styles.button}
                size="xs"
                tooltip="Add User"
                onClick={(e) => {
                  e.stopPropagation();
                  window.location.href = `/briefcase-sharing.php?Briefcase=${briefcase.uuid}`;
                }}
              >
                <PersonPlus />
              </Button>
              <Separator />
              <Button
                className={styles.button}
                size="xs"
                tooltip="Edit Briefcase"
                onClick={(e) => {
                  e.stopPropagation();
                  window.location.href = `/briefcase-management.php?Briefcase=${briefcase.uuid}`;
                }}
              >
                <Pen />
              </Button>
              <Separator />
              <Button
                className={styles.button}
                size="xs"
                tooltip="Delete"
                onClick={handleDelete}
              >
                <Bin />
              </Button>
            </>
          )}
        </footer>
      </button>
    </>
  );
}

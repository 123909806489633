import React, { CSSProperties, HTMLAttributes } from "react";
import cn from "classnames";

import SelectOption, { Option } from "atoms/SelectOption";

import styles from "./module.sass";

export type Size = "default" | "small";

export type Props<V extends string = string> = {
  options: Option<V>[];
  onSelect: (option: Option<V>) => void;
  selectedOption?: Option<V>;

  size?: Size;
  style?: CSSProperties;
  maxHeight?: number;
  topMargin?: number;
};

export default function SelectDropdown<V extends string = string>({
  options,
  onSelect,
  selectedOption,
  size = "default",
  style = {},
  maxHeight,
  topMargin = 64,
}: Props<V>) {
  return (
    <BaseDropdown
      size={size}
      topMargin={topMargin}
      maxHeight={maxHeight}
      style={style}
    >
      {options.map((o) => (
        <SelectOption
          key={o.value}
          option={o}
          onSelect={onSelect}
          isSelected={selectedOption === o}
        />
      ))}
    </BaseDropdown>
  );
}

export interface BaseDropdownProps extends HTMLAttributes<HTMLDivElement> {
  size?: Size;
  maxHeight?: number;
  topMargin?: number;
}

export function BaseDropdown({
  children,
  maxHeight,
  topMargin,
  style = {},
  size = "default",
}: BaseDropdownProps) {
  return (
    <div
      className={cn(styles.dropdown, size === "small" && styles.dropdown$small)}
      style={{ top: topMargin, ...style }}
    >
      <div
        className={styles.optionsContainer}
        style={maxHeight ? { maxHeight } : {}}
      >
        {children}
      </div>
    </div>
  );
}

import React, { MouseEventHandler, useRef, useState } from "react";
import cn from "classnames";

import {
  getStartOfMonth,
  getStartOfLastMonth,
  getStartOfNextMonth,
  isFuture,
} from "domain/dates";
import useClickOutside from "hooks/useClickOutside";
import { DateInput } from "components/Input";
import { ThinChevron, SmallChevron } from "components/Icons";

import styles from "./module.sass";
import Calendar from "./Calendar";

const shortWeekNames = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
const longMonthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export type Props = {
  date: Date | null;
  label: string;
  onChange: (date: Date) => void;

  error?: string;
  isFutureDisabled?: boolean;
  onBlur?: () => void;
};

export default function DatePicker({
  date,
  label,
  onChange,
  error = "",
  isFutureDisabled = false,
  onBlur = () => {},
}: Props) {
  const [navigationDate, setNavigationDate] = useState(
    getStartOfMonth(date ?? new Date())
  );
  const [isOpen, setIsOpen] = useState(false);
  const [isMonthDropdownOpen, setIsMonthDropdownOpen] = useState(false);
  const [isYearDropdownOpen, setIsYearDropdownOpen] = useState(false);
  const monthDropdownRef = useRef<HTMLButtonElement>(null);
  const yearDropdownRef = useRef<HTMLButtonElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  const calendar = new Calendar(navigationDate);

  const closeDropdowns = () => {
    setIsOpen(false);
    setIsMonthDropdownOpen(false);
    setIsYearDropdownOpen(false);
  };
  const selectDate = (date: Date) => {
    closeDropdowns();
    onChange(date);
    setNavigationDate(getStartOfMonth(date));
  };
  const handleClickOutside: MouseEventHandler<HTMLElement> = (e) => {
    closeDropdowns();
    onBlur();
  };

  useClickOutside(ref, handleClickOutside);
  useClickOutside(monthDropdownRef, () => {
    setIsMonthDropdownOpen(false);
  });
  useClickOutside(yearDropdownRef, () => {
    setIsYearDropdownOpen(false);
  });

  return (
    <div className={styles.datePicker} ref={ref}>
      <button
        type="button"
        className={styles.trigger}
        onClick={(e) => setIsOpen(!isOpen)}
      >
        <DateInput
          value={
            date
              ? new Intl.DateTimeFormat("en-US", {
                  month: "short",
                  day: "2-digit",
                  year: "numeric",
                }).format(date)
              : ""
          }
          label={label}
          error={error}
        />
      </button>

      {isOpen && (
        <div className={styles.dropdown}>
          <header className={styles.header}>
            <div className={styles.controls}>
              <button
                type="button"
                onClick={(e) =>
                  setNavigationDate(getStartOfLastMonth(navigationDate))
                }
                className={styles.arrow}
              >
                <ThinChevron />
              </button>
              <div style={{ display: "flex" }}>
                <button
                  type="button"
                  className={styles.smallDropdownTrigger}
                  ref={monthDropdownRef}
                  onClick={(e) => {
                    setIsYearDropdownOpen(false);
                    setIsMonthDropdownOpen(!isMonthDropdownOpen);
                  }}
                >
                  {calendar.shortMonthName}
                  <SmallChevron className={styles.smallChevron} />
                  {isMonthDropdownOpen && (
                    <div className={styles.smallDropdown}>
                      <div>
                        {longMonthNames.map((name, i) => (
                          <button
                            key={name}
                            type="button"
                            className={styles.option}
                            onClick={(e) =>
                              setNavigationDate(
                                getStartOfMonth(
                                  new Date(navigationDate.getFullYear(), i)
                                )
                              )
                            }
                          >
                            {name}
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                </button>
                <button
                  type="button"
                  className={styles.smallDropdownTrigger}
                  ref={yearDropdownRef}
                  onClick={(e) => {
                    setIsMonthDropdownOpen(false);
                    setIsYearDropdownOpen(!isYearDropdownOpen);
                  }}
                >
                  {calendar.year}
                  <SmallChevron className={styles.smallChevron} />
                  {isYearDropdownOpen && (
                    <div className={styles.smallDropdown}>
                      <div>
                        {Array(50)
                          .fill(null)
                          .map((_, i) => (
                            <button
                              key={i}
                              type="button"
                              className={cn(styles.option, styles.option$year)}
                              onClick={(e) =>
                                setNavigationDate(
                                  getStartOfMonth(
                                    new Date(
                                      new Date().getFullYear() - i,
                                      navigationDate.getMonth()
                                    )
                                  )
                                )
                              }
                            >
                              {new Date().getFullYear() - i}
                            </button>
                          ))}
                      </div>
                    </div>
                  )}
                </button>
              </div>
              <button
                type="button"
                className={styles.arrow}
                disabled={
                  isFutureDisabled &&
                  isFuture(getStartOfNextMonth(navigationDate))
                }
                onClick={(e) =>
                  setNavigationDate(getStartOfNextMonth(navigationDate))
                }
              >
                <ThinChevron direction="right" />
              </button>
            </div>
            <div className={styles.weekdays}>
              {shortWeekNames.map((w) => (
                <span key={w}>{w}</span>
              ))}
            </div>
          </header>
          <div className={styles.grid}>
            {calendar.datesInPreviousMonth.map((d) => (
              <button
                key={d.getTime()}
                type="button"
                className={styles.day}
                disabled={isFutureDisabled && isFuture(d)}
                onClick={(e) => selectDate(d)}
              >
                {d.getDate()}
              </button>
            ))}
            {calendar.datesInMonth.map((d) => (
              <button
                type="button"
                key={d.getTime()}
                className={cn(
                  styles.day,
                  styles.day$inSelectedMonth,
                  date?.getTime() === d.getTime() && styles.day$selected
                )}
                disabled={isFutureDisabled && isFuture(d)}
                onClick={(e) => selectDate(d)}
              >
                {d.getDate()}
              </button>
            ))}
            {calendar.datesInNextMonth.map((d) => (
              <button
                key={d.getTime()}
                type="button"
                className={styles.day}
                disabled={isFutureDisabled && isFuture(d)}
                onClick={(e) => selectDate(d)}
              >
                {d.getDate()}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

import React, { useState } from "react";

import { ReleaseNote } from "domain/releaseNotes";
import Text from "atoms/Text";
import Button from "components/Button";
import PageTitle from "components/PageTitle";
import { EditableReleaseNote } from "molecules/ReleaseNote";
import Pagination from "components/Pagination";

export type Props = {
  releaseNotes: ReleaseNote[];
  onCreate: () => void;
  onEdit: (releaseNote: ReleaseNote) => void;
  onDelete: (releaseNote: ReleaseNote) => void;
};

export default function ReleaseNotesAdmin({
  releaseNotes,
  onCreate,
  onEdit,
  onDelete,
}: Props) {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const start = pageIndex * pageSize;
  const end = start + pageSize;
  const shownReleaseNotes = releaseNotes
    .sort((a, b) => (a.creationDate > b.creationDate ? -1 : 1))
    .slice(start, end);

  const handlePageChange = (newPage: number) => {
    setPageIndex(newPage);
  };

  return (
    <section style={{ width: "100%", maxWidth: 1200 }}>
      <PageTitle style={{ paddingLeft: 0, paddingBottom: 30 }}>
        Release Notes
      </PageTitle>
      <Button variant="contained" size="sm" onClick={() => onCreate()}>
        New Release Note
      </Button>
      <Text size={21} style={{ marginTop: 52, marginBottom: 16 }}>
        Past Release Notes
      </Text>
      {shownReleaseNotes.map((releaseNote) => (
        <div style={{ marginBottom: 24 }} key={releaseNote.id}>
          <EditableReleaseNote
            releaseNote={releaseNote}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        </div>
      ))}
      <Pagination
        pageSize={pageSize}
        pageIndex={pageIndex}
        itemCount={releaseNotes.length}
        onChange={handlePageChange}
        onChangePageSize={(newPageSize: number, newPageIndex: number) => {
          setPageSize(newPageSize);
          setPageIndex(newPageIndex);
        }}
      />
    </section>
  );
}

import React from "react";
import { RouteComponentProps } from "react-router";
import FreeTrialPage from "../FreeTrialPage/.";

export default function FreeTrialMarketingPage(props: RouteComponentProps) {
  return (
    <>
      <FreeTrialPage isMinimal={true} {...props} />
    </>
  );
}

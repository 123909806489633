import React, { RefObject, useLayoutEffect, useState } from "react";

export default function useHasEllipsis<TNode extends HTMLElement>(
  ref: RefObject<TNode>
): boolean {
  const [hasEllipsis, setHasEllipsis] = useState(false);

  useLayoutEffect(() => {
    const node = ref.current;
    if (!node) {
      return;
    }
    setHasEllipsis(node.offsetWidth < node.scrollWidth);
  }, [ref.current]);

  return hasEllipsis;
}

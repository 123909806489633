import React, { useCallback, useMemo } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  Column,
  usePagination,
} from "react-table";
import { useQueryParam, NumberParam } from "use-query-params";

import { format as formatDate } from "domain/dates";
import { FileSize, File } from "domain/files";
import Table, {
  EmptyMessage,
  ExtendedColumn,
  actionsHeaderAdditionalProps,
} from "components/Table";
import { SearchInput } from "components/Input";
import { Bin, Download as DownloadIcon } from "components/Icons";
import Button from "components/Button";
import Text from "components/Text";
import Readable from "atoms/Readable";
import Separator from "components/Separator";

import { Container, Heading, Controls } from ".";
import styles from "./module.sass";

export type Props = {
  files: File[];
  isLoading: boolean;
  onDelete: (file: File) => void;
};

export default function RecentFilesPanel({
  files,
  isLoading,
  onDelete,
}: Props) {
  const download = useCallback((file: File) => {
    window.open(file.downloadLink);
  }, []);

  const columns: ExtendedColumn<File>[] = useMemo(
    () => [
      { Header: "Filename", accessor: "name" },
      {
        Header: "Date Created",
        accessor: "dateCreated",
        Cell: ({ value }) => formatDate(value),
        sortType: "datetime",
      },
      {
        Header: "File Size",
        accessor: "bytes",
        Cell: ({ value }) => FileSize.fromBytes(value).format(),
        sortType: (rowA, rowB) => rowA.original.bytes - rowB.original.bytes,
      },
      {
        Header: "Actions",
        id: "actions",
        accessor: "name",
        disableSortBy: true,
        additionalHeaderProps: actionsHeaderAdditionalProps,
        Cell: ({ row }) => (
          <div
            style={{
              display: "flex",
              margin: "-3px 0",
              justifyContent: "center",
            }}
          >
            <Button
              className={styles.button}
              size="xs"
              tooltip="Download"
              onClick={() => download(row.original)}
            >
              <DownloadIcon />
            </Button>
            <Separator />
            <Button
              className={styles.button}
              size="xs"
              tooltip="Delete"
              onClick={() => onDelete(row.original)}
            >
              <Bin />
            </Button>
          </div>
        ),
      },
    ],
    []
  );

  const [pageIndexQuery, setPageIndexQuery] = useQueryParam(
    "recentFilesPage",
    NumberParam
  );

  const table = useTable(
    {
      columns,
      data: files,
      disableSortRemove: true,
      autoResetSortBy: false,
      initialState: {
        pageIndex: pageIndexQuery ?? 0,
        pageSize: 10,
        sortBy: useMemo(
          () => [
            {
              id: "dateCreated",
              desc: true,
            },
          ],
          []
        ),
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const {
    page,
    state: { globalFilter = "" },
    setGlobalFilter,
  } = table;

  return (
    <Container>
      <Heading
        text="Recent Downloads"
        hasBorder={files.length !== 0}
        subtext={
          isLoading || files.length > 0 ? (
            <>
              Displaying{" "}
              <Text variant="bold">{Math.min(files.length, page.length)}</Text>{" "}
              of{" "}
              <Text variant="bold">
                <Readable>{files.length}</Readable>
              </Text>{" "}
              Downloads
            </>
          ) : (
            <>No items</>
          )
        }
      />
      {(isLoading || files.length > 0) && (
        <Controls>
          <div style={{ width: 380 }}>
            <SearchInput
              value={globalFilter}
              label="Search for a File"
              onChange={(value) => setGlobalFilter(value)}
            />
          </div>
        </Controls>
      )}
      <Table
        table={table}
        emptyMessage={
          <EmptyMessage colSpan={4}>
            {globalFilter ? "No matching records found" : "No items"}
          </EmptyMessage>
        }
        onPageChange={(newPageIndex) => {
          setPageIndexQuery(newPageIndex);
        }}
      />
    </Container>
  );
}

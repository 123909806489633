// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._285p05RQnrrvqjxVF4q4ha{width:33px;height:31.99px}@media (min-width: 360px){._285p05RQnrrvqjxVF4q4ha{width:35px;height:33.93px}}@media (min-width: 640px){._285p05RQnrrvqjxVF4q4ha{width:auto;height:auto}}\n", "",{"version":3,"sources":["webpack://./src/components/LNIPLogo/module.sass"],"names":[],"mappings":"AAEA,yBACI,UAAW,CACX,cAAe,CAEf,0BAJJ,yBAKQ,UAAW,CACX,cAAe,CAIE,CAFrB,0BARJ,yBASQ,UAAW,CACX,WAAY,CAAK","sourcesContent":["@import \"../../style/_variables.sass\";\n\n.logoSign {\n    width: 33px;\n    height: 31.99px;\n\n    @media (min-width: $bp-xxs) {\n        width: 35px;\n        height: 33.93px; }\n\n    @media (min-width: $bp-xs) {\n        width: auto;\n        height: auto; } }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logoSign": "_285p05RQnrrvqjxVF4q4ha"
};
export default ___CSS_LOADER_EXPORT___;

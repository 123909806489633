import React, { FormEventHandler, useState } from "react";

import Button from "components/Button";
import Input from "components/Input";
import Select from "components/Select";
import { getAnalytics } from "domain/analytics";

import { TabPanel, TabPanelItem } from ".";

type SearchType =
  | "artUnitNumber"
  | "technologyCenter"
  | "technologyCenterGroup";

export default function ArtUnitForm() {
  const [searchType, setSearchType] = useState<SearchType>("artUnitNumber");
  const [artUnit, setArtUnit] = useState("");
  const [artUnitError, setArtUnitError] = useState("");
  const [technologyCenter, setTechnologyCenter] = useState("");
  const [technologyCenterError, setTechnologyCenterError] = useState("");
  const [technologyCenterGroup, setTechnologyCenterGroup] = useState("");
  const [technologyCenterGroupError, setTechnologyCenterGroupError] =
    useState("");

  const validate = () => {
    setArtUnitError("");
    setTechnologyCenterError("");
    setTechnologyCenterGroupError("");

    if (searchType === "artUnitNumber" && !artUnit.match(/^\d{1,4}$/)) {
      setArtUnitError(
        "Enter a four digit number for direct access\nor less to list matching art units."
      );
      return false;
    }
    if (
      searchType === "technologyCenter" &&
      !technologyCenter.match(/^\d{1,4}$/)
    ) {
      setTechnologyCenterError(
        "Enter a four digit number for direct access\nor less to list matching technology centers."
      );
      return false;
    }
    if (
      searchType === "technologyCenterGroup" &&
      !technologyCenterGroup.match(/^\d{1,4}$/)
    ) {
      setTechnologyCenterGroupError(
        "Enter a four digit number for direct access\nor less to list matching technology center groups."
      );
      return false;
    }

    return true;
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }

    recordFormSubmission(
      searchType,
      artUnit || technologyCenter || technologyCenterGroup
    );

    const urlParams = {
      artUnitNumber: new URLSearchParams({ a: artUnit }),
      technologyCenter: new URLSearchParams({ technologyCenter }),
      technologyCenterGroup: new URLSearchParams({ technologyCenterGroup }),
    }[searchType];
    window.location.href = `/art-unit-search.php?${urlParams.toString()}`;
  };

  const hasAnyValue =
    (searchType === "artUnitNumber" && artUnit) ||
    (searchType === "technologyCenter" && technologyCenter) ||
    (searchType === "technologyCenterGroup" && technologyCenterGroup);

  return (
    <TabPanel>
      <form style={{ width: "100%" }} onSubmit={handleSubmit}>
        <TabPanelItem>
          <Select
            value={searchType}
            onChange={(value) => setSearchType(value as SearchType)}
            options={[
              { value: "artUnitNumber", label: "Art Unit Number" },
              { value: "technologyCenter", label: "Technology Center" },
              {
                value: "technologyCenterGroup",
                label: "Technology Center Group",
              },
            ]}
          />
        </TabPanelItem>
        <TabPanelItem>
          {searchType === "artUnitNumber" && (
            <Input
              label="Example: 2122"
              value={artUnit}
              error={artUnitError}
              onChange={(value) => {
                setArtUnitError("");
                setArtUnit(value.trim());
              }}
            />
          )}
          {searchType === "technologyCenter" && (
            <Input
              label="Example: 2100"
              value={technologyCenter}
              error={technologyCenterError}
              onChange={(value) => {
                setTechnologyCenterError("");
                setTechnologyCenter(value.trim());
              }}
            />
          )}
          {searchType === "technologyCenterGroup" && (
            <Input
              label="Example: 2120"
              value={technologyCenterGroup}
              error={technologyCenterGroupError}
              onChange={(value) => {
                setTechnologyCenterGroupError("");
                setTechnologyCenterGroup(value.trim());
              }}
            />
          )}
        </TabPanelItem>
        <TabPanelItem>
          <Button
            id="homepageArtUnitBtnSubmit"
            leftIcon="magnifier"
            type="submit"
            variant="brand"
            isDisabled={!hasAnyValue}
          >
            Search
          </Button>
        </TabPanelItem>
      </form>
    </TabPanel>
  );
}

function recordFormSubmission(searchType: SearchType, query: string) {
  getAnalytics().recordEvent({
    pageName: "home_page",
    interfaceEvent: "search_art_units",
    interfaceValue: "search",
    action: "click",
    context: {
      searchType,
      query,
    },
  });
}

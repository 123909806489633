import React, { HTMLAttributes } from "react";
import { v4 as uuid } from "uuid";
import cn from "classnames";

import Tooltip from "atoms/Tooltip";
import Text from "atoms/Text";
import { IssueOutcome as IssueOutcomeType } from "domain/ptab";

import styles from "./IssueOutcome.module.sass";

export type Props = {
  outcome: IssueOutcomeType;
} & HTMLAttributes<HTMLSpanElement>;

export default function IssueOutcome({ outcome, ...rest }: Props) {
  const tooltipId = uuid();

  return (
    <>
      <span {...rest} className={cn(styles.issueOutcome, rest.className)}>
        <Text
          as="span"
          size={11}
          weight="black"
          color="grey900"
          data-tip={outcomeToTitleCase[outcome]}
          data-for={tooltipId}
        >
          {outcomeToAbbrevation[outcome]}
        </Text>
      </span>
      <Tooltip id={tooltipId} />
    </>
  );
}

const outcomeToAbbrevation: { [key in IssueOutcomeType]: string } = {
  affirmed: "AFF",
  reversed: "REV",
  affirmedInPart: "AIP",
  notAvailable: "NA",
};

const outcomeToTitleCase: { [key in IssueOutcomeType]: string } = {
  affirmed: "Affirmed",
  reversed: "Reversed",
  affirmedInPart: "Affirmed in Part",
  notAvailable: "Not Available",
};

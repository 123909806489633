import * as React from "react";
import { FieldHookConfig, useField } from "formik";

import SimpleSelect, { Props as SelectProps } from "molecules/SimpleSelect";

type Value = string | null;

export type Props = Omit<FieldHookConfig<Value>, "onChange"> &
  Pick<SelectProps, "options" | "label"> & {
    onChange?: SelectProps["onChange"];
  };

export default function SelectField({
  options,
  label,
  onChange,
  ...rest
}: Props) {
  const [field, meta, helpers] = useField<Value>(rest.name);

  return (
    <div style={{ marginBottom: 14 }}>
      <SimpleSelect
        {...field}
        label={label}
        options={options}
        error={meta.touched && meta.error ? meta.error : undefined}
        onChange={onChange ?? ((val) => helpers.setValue(val))}
      />
    </div>
  );
}

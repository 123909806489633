import React, {
  FocusEventHandler,
  forwardRef,
  MouseEventHandler,
  ReactNode,
  useLayoutEffect,
  useRef,
} from "react";
import cn from "classnames";

import {
  Warning as WarningIcon,
  Magnifier as MagnifierIcon,
  X as XIcon,
  Calendar as CalendarIcon,
} from "components/Icons";

import styles from "./module.sass";
import useCombinedRefs from "hooks/useCombinedRefs";

export type BaseInputProps = {
  value: string;
  label: string;
  onChange: (value: string) => void;

  error?: string;
  icon?: ReactNode;
  labelPointerEvents?: "auto" | "none";
  isSmall?: boolean;
  isReadOnly?: boolean;
  isClearable?: boolean;
  isLabelEager?: boolean;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onEllipsis?: () => void;
};

export const BaseInput = forwardRef<HTMLInputElement, BaseInputProps>(
  (
    {
      value,
      label,
      onChange,
      error = "",
      labelPointerEvents = "auto",
      icon = null,
      isSmall = false,
      isReadOnly = false,
      isClearable = true,
      isLabelEager = true,
      onBlur = () => {},
      onEllipsis = () => {},
    },
    ref
  ) => {
    const privateRef = useRef<HTMLInputElement>(null);
    const inputRef = useCombinedRefs(ref, privateRef);

    useLayoutEffect(() => {
      const hasEllipsis = inputRef.current
        ? inputRef.current.offsetWidth < inputRef.current.scrollWidth
        : false;

      if (hasEllipsis) {
        onEllipsis();
      }
    }, [onEllipsis]);

    const handleResetClick: MouseEventHandler<HTMLButtonElement> = (e) => {
      inputRef?.current?.focus();
      onChange("");
    };

    return (
      <div
        className={cn(
          styles.group,
          error && styles.group$withError,
          isSmall && styles.group$small,
          isLabelEager && styles.group$eagerLabel
        )}
      >
        <div className={styles.topHalf}>
          <label
            className={styles.label}
            style={{
              pointerEvents: labelPointerEvents,
            }}
          >
            <input
              value={value}
              placeholder={label}
              className={styles.input}
              readOnly={isReadOnly}
              ref={inputRef}
              onChange={(e) => onChange(e.target.value)}
              onBlur={onBlur}
            />
            <span className={styles.labelText}>{label}</span>
            {isClearable && value && !isReadOnly && (
              <button
                type="button"
                tabIndex={-1}
                onClick={handleResetClick}
                className={styles.clearButton}
              >
                <XIcon className={styles.x} />
              </button>
            )}
            {icon}
          </label>
          {error && <WarningIcon />}
        </div>
        {error.split("\n").map((e) => (
          <div key={e} className={styles.errorMessage}>
            {e}
          </div>
        ))}
      </div>
    );
  }
);

export type Props = Omit<
  BaseInputProps,
  "icon" | "isClearable" | "isSmall" | "isLabelEager"
>;

const Input = forwardRef<HTMLInputElement, Props>((props, ref) => (
  <BaseInput ref={ref} {...props} />
));

export type SearchInputProps = Omit<
  BaseInputProps,
  "icon" | "isReadOnly" | "isClearable" | "isSmall" | "isLabelEager"
>;

export const SearchInput = (props: SearchInputProps) => (
  <BaseInput
    {...props}
    icon={props.value ? null : <MagnifierIcon className={styles.magnifier} />}
  />
);

export type DateInputProps = Omit<
  BaseInputProps,
  | "icon"
  | "labelPointerEvents"
  | "isReadOnly"
  | "isClearable"
  | "onChange"
  | "isSmall"
  | "isLabelEager"
>;

export const DateInput = (props: DateInputProps) => (
  <BaseInput
    {...props}
    labelPointerEvents="none"
    icon={<CalendarIcon className={styles.calendar} />}
    isReadOnly={true}
    isClearable={false}
    isLabelEager={false}
    isSmall={true}
    onChange={() => {}}
  />
);

export default Input;

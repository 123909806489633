import { ReactNode } from "react";

export type Value = string;

/** Use a `Set` for quick membership tests. */
export type Values = Set<string>;

export interface Option<T = undefined> {
  value: Value;
  label: ReactNode;

  nestedOptions?: Option<T>[];
  metadata: T;
}

export type SelectionState = "yes" | "no" | "partially";

export type ValueToSelectionState = Record<Value, SelectionState>;

/**
 * Traverses all option trees and gathers all
 * encountered options in an array.
 */
export function flattenOptions<T>(options: Option<T>[]): Option<T>[] {
  return options
    .map(getOptionWithDescendents)
    .reduce((a, b) => a.concat(b), []); // .flat()
}

/**
 * Traverses the option tree and gathers all
 * encountered options in an array.
 *
 * Alternative name: `flattenOption`
 *
 * @see flattenOptions
 */
export function getOptionWithDescendents<T>(option: Option<T>): Option<T>[] {
  return [option, ...getDescendents(option)];
}

/**
 * Gets all descendents of an option, some of which may
 * by multiple layers deep.
 */
export function getDescendents<T>(option: Option<T>): Option<T>[] {
  return (option.nestedOptions || [])
    .map(getOptionWithDescendents)
    .reduce((a, b) => a.concat(b), []);
}

export function getValueToSelectionState<T>(
  selectedValues: Values,
  options: Option<T>[]
): ValueToSelectionState {
  return flattenOptions(options).reduce<ValueToSelectionState>(
    (result, option) => {
      result[option.value] = getSelectionState(selectedValues, option);
      return result;
    },
    {}
  );
}

/**
 * Will not traverse the option tree, but only the first
 * level of a given array of options.
 *
 * If you want to index the whole tree,
 * use `indexByValue(flattenOptions(options))`.
 */
export function indexByValue<T>(flatOptions: Option<T>[]) {
  return flatOptions.reduce<Record<Value, Option<T>>>(
    (valueToOption, option) => {
      valueToOption[option.value] = option;
      return valueToOption;
    },
    {}
  );
}

/**
 * Will not traverse the option tree, but only the first
 * level of a given array of options.
 *
 * If you want to index the whole tree,
 * use `indexByValue(flattenOptions(options))`.
 */
export function indexParentsByValue<T>(flatOptions: Option<T>[]) {
  return flatOptions.reduce<Record<Value, Option<T>>>(
    (valueToParent, option) => {
      (option.nestedOptions || []).forEach((nestedOption) => {
        valueToParent[nestedOption.value] = option;
      });
      return valueToParent;
    },
    {}
  );
}

export function isLeafNode<T>(option: Option<T>): boolean {
  return !option.nestedOptions || option.nestedOptions.length === 0;
}

function getSelectionState<T>(
  selectedValues: Values,
  option: Option<T>
): SelectionState {
  return isLeafNode(option)
    ? getLeafNodeSelectionState(selectedValues, option)
    : getInternalNodeSelectionState(selectedValues, option);
}

function getLeafNodeSelectionState<T>(
  selectedValues: Values,
  option: Option<T>
): SelectionState {
  return selectedValues.has(option.value) ? "yes" : "no";
}

function getInternalNodeSelectionState<T>(
  selectedValues: Values,
  option: Option<T>
): SelectionState {
  const descendents = getDescendents(option);
  const selectedDescendentCount = descendents
    .map((d) => d.value)
    .filter((v) => selectedValues.has(v)).length;
  const areAllDescendentsSelected =
    selectedDescendentCount === descendents.length;

  if (selectedDescendentCount === 0) {
    return "no";
  }
  if (areAllDescendentsSelected) {
    return "yes";
  }
  return "partially";
}

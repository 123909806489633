import React, { SVGProps } from "react";

import styles from "./InfoIcon.module.sass";

export type Props = {
  color?: string;
} & SVGProps<SVGSVGElement>;

const InfoIcon = ({ color, ...rest }: Props) => (
  <svg
    width={18}
    height={18}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 18"
    fill={color}
    className={styles.icon}
    {...rest}
  >
    <path d="M8.3 7.5h1.3v5.4H8.3z" />
    <path d="M9 1C4.6 1 1 4.6 1 9s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 15.2C5 16.2 1.8 13 1.8 9S5 1.8 9 1.8 16.2 5 16.2 9 13 16.2 9 16.2z" />
    <circle cy={5.9} cx={9} r={0.8} />
  </svg>
);

export default InfoIcon;

/* istanbul ignore file */

import { Analytics, Event, User } from "./factory";

export class NullAnalytics implements Analytics {
  public recordEvent(event: Event) {}
  public recordPageView(url: string, name: string) {}
  public setCurrentUser(user: User) {}
  public removeCurrentUser() {}
}

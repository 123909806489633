import React, { ReactNode } from "react";

import { LicensingReport } from "domain/licensingReports";

import UngroupedTable from "./UngroupedTable";
import CitedDocumentTable from "./CitedDocumentTable";
import RejectedAssigneeTable from "./RejectedAssigneeTable";

export enum Grouping {
  None = "NONE",
  CitedDocument = "CITED_DOCUMENT",
  RejectedAssignee = "REJECTED_ASSIGNEE",
}

export interface Props {
  /** Should have stable identity to prevent rerenders */
  licensingReport: LicensingReport;
  groupedBy: Grouping;

  setCountText?: (countText: ReactNode) => void;
}

export default function TableFactory({
  licensingReport,
  groupedBy,
  setCountText = () => <></>,
}: Props) {
  if (groupedBy === Grouping.None) {
    return (
      <UngroupedTable
        licensingReport={licensingReport}
        setCountText={setCountText}
      />
    );
  }
  if (groupedBy === Grouping.CitedDocument) {
    return (
      <CitedDocumentTable
        licensingReport={licensingReport}
        setCountText={setCountText}
      />
    );
  }
  if (groupedBy === Grouping.RejectedAssignee) {
    return (
      <RejectedAssigneeTable
        licensingReport={licensingReport}
        setCountText={setCountText}
      />
    );
  }

  throw new Error(
    `No table has been implemented for \`groupedBy\` option ${groupedBy}`
  );
}

import {
  getDaysInMonth,
  getLastSunday,
  getRange,
  incrementDays,
} from "domain/dates";

const shortMonthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export default class Calendar {
  public readonly shortMonthName: string;
  public readonly year: number;
  public readonly datesInPreviousMonth: Date[];
  public readonly datesInMonth: Date[];
  public readonly datesInNextMonth: Date[];

  private date: Date;

  constructor(date: Date) {
    this.date = date;
    this.shortMonthName = shortMonthNames[this.date.getMonth()];
    this.year = this.date.getFullYear();

    const month = date.getMonth();
    const dates = this.getDates();

    this.datesInPreviousMonth = dates.filter(
      (d) => d < date && d.getMonth() !== month
    );
    this.datesInMonth = dates.filter((d) => d.getMonth() === month);
    this.datesInNextMonth = dates.filter(
      (d) => d > date && d.getMonth() !== month
    );
  }

  private getDates(): Date[] {
    const daysInMonth = getDaysInMonth(this.date);
    const UTCDay = this.date.getUTCDay();
    const isFriday = UTCDay === 4;
    const isSaturday = UTCDay === 5;

    // special case: friday and 31 days
    // special case: saturday and >= 30 days
    const dayCount =
      (isFriday && daysInMonth === 31) || (isSaturday && daysInMonth >= 30)
        ? 41
        : 34;
    const firstDate = getLastSunday(this.date);
    const lastDate = incrementDays(firstDate, dayCount);

    return getRange(firstDate, incrementDays(lastDate, 1));
  }
}

import React, {
  forwardRef,
  ReactNode,
  RefObject,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useWindowWidth } from "@react-hook/window-size";

import Text from "atoms/Text";
import Target from "atoms/Target";
import { Application, Event as TimelineEvent } from "domain/applications";
import Popover from "atoms/Popover";
import useClickOutside from "hooks/useClickOutside";
import { capitalize } from "domain/strings";
import colors from "style/colors.module.scss";

import { useLabelWidthCorrection } from "../hooks";
import { createEventViewModel, IEventViewModel } from "../models/events";
import { ITimelineViewModel } from "../models/timeline";

export interface Props {
  variant: "short" | "tall";
  status: Application["status"];
  timelineViewModel: ITimelineViewModel;
  focusedEvent: TimelineEvent | null;
  expandedEvent: TimelineEvent | null;
  onFocusedEventChange: (event: TimelineEvent | null) => void;
  onExpandedEventChange: (event: TimelineEvent | null) => void;
}

/**
 * Uses local time, not UTC.
 */
export default function Line({
  variant,
  timelineViewModel,
  expandedEvent,
  focusedEvent,
  onExpandedEventChange,
  onFocusedEventChange,
}: Props) {
  const popoverRef = useRef(null);
  const todayLabelRef = useRef<HTMLDivElement>(null);
  const lineRightEdgeRef = useRef<HTMLDivElement>(null);
  const [todayLabelOrientation, setTodayLabelOrientation] = useState<
    "right" | "left"
  >("right");

  useClickOutside(popoverRef, () => {
    onExpandedEventChange(null);
  });

  const todayLabelOvershoot = useLabelWidthCorrection(
    todayLabelRef,
    lineRightEdgeRef
  );

  useEffect(() => {
    if (todayLabelOrientation === "left") {
      return;
    }

    if (todayLabelOvershoot) {
      setTodayLabelOrientation("left");
    }
  }, [todayLabelOvershoot]);

  const {
    applicationStatus: status,
    today,
    expectedApprovalWindow,
    firstYear,
    lastYear,
  } = timelineViewModel;

  return (
    <section style={{ position: "relative", width: "100%", zIndex: 0 }}>
      <Years
        variant={variant}
        firstYear={firstYear}
        lastYear={lastYear}
        lastLineRef={lineRightEdgeRef}
      />
      <HorizontalLine>
        {timelineViewModel.events.map((event, i) => {
          const isOpen = expandedEvent && areSameEvent(event, expandedEvent);
          const viewModel = createEventViewModel(event);
          const correction = viewModel.size === "large" ? "15px" : "10px";

          return (
            <Event
              key={i}
              ref={isOpen ? popoverRef : undefined}
              event={viewModel}
              ordinalNumber={timelineViewModel.getEventOrdinalNumberAmongSameType(
                event
              )}
              leftOffset={timelineViewModel.getEventOffset(event, correction)}
              isExpanded={expandedEvent === event}
              isFocused={focusedEvent === event}
              onIsExpandedChange={(isExpanded) =>
                onExpandedEventChange(isExpanded ? event : null)
              }
              onIsFocusedChange={(isFocused) =>
                onFocusedEventChange(isFocused ? event : null)
              }
            />
          );
        })}

        {status === "pending" && (
          <Today
            variant={variant}
            status={status}
            orientation={todayLabelOrientation}
            leftOffset={timelineViewModel.getDateOffset(today)}
            labelRef={todayLabelRef}
          />
        )}

        {expectedApprovalWindow !== null && (
          <ExpectedApprovalWindow
            leftOffset={timelineViewModel.getDateOffset(
              expectedApprovalWindow.start
            )}
            width={timelineViewModel.getWidth(
              expectedApprovalWindow.start,
              expectedApprovalWindow.end
            )}
          />
        )}
      </HorizontalLine>
    </section>
  );
}

function Years({
  variant,
  firstYear,
  lastYear,
  lastLineRef,
}: {
  variant: Props["variant"];
  firstYear: number;
  lastYear: number;
  lastLineRef: RefObject<HTMLDivElement>;
}) {
  type LabelTruncationLevel = 0 | 1 | 2 | 3;
  const [labelTruncationLevel, setLabelTruncationLevel] =
    useState<LabelTruncationLevel>(0);
  const penultimateLabelRef = useRef<HTMLDivElement>(null);
  const windowWidth = useWindowWidth();
  const [windowWidthOfLastOverlapCheck, setWindowWidthOfLastOverlapCheck] =
    useState<number | null>(null);

  useLayoutEffect(() => {
    function increaseLabelTruncationLevel() {
      if (labelTruncationLevel === 3) {
        return;
      }
      setLabelTruncationLevel((level) => (level + 1) as LabelTruncationLevel);
    }

    function ensureYearLabelsDontOverlapLines() {
      const label = penultimateLabelRef.current;
      const line = lastLineRef.current;
      if (!label || !line) {
        return;
      }

      const labelRightEdge = label.getBoundingClientRect().right;
      const lineLeftEdge = line.getBoundingClientRect().left;

      // workaround for not truncating labels in a case
      // when the element is rendered in a portal in the legacy
      // app where, for a moment, both labelRightEdge and lineLeftEdge
      // are zero
      const isElementReady = labelRightEdge !== 0;
      if (!isElementReady) {
        return;
      }

      const areLabelsOverlappingLines = labelRightEdge >= lineLeftEdge;
      if (!areLabelsOverlappingLines) {
        return;
      }

      increaseLabelTruncationLevel();
    }

    function shouldTryResettingLabelTruncationLevel() {
      const hasWindowWidthIncreased =
        windowWidthOfLastOverlapCheck &&
        windowWidthOfLastOverlapCheck < windowWidth;
      return hasWindowWidthIncreased;
    }

    if (shouldTryResettingLabelTruncationLevel()) {
      setLabelTruncationLevel(0);
    }

    ensureYearLabelsDontOverlapLines();
    setWindowWidthOfLastOverlapCheck(windowWidth);
  }, [windowWidth, labelTruncationLevel]);

  const years = Array.from(Array(lastYear - firstYear + 1).keys()).map(
    (i) => firstYear + i
  );

  return (
    <div
      style={{
        height: variant === "short" ? 106 : 285,
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {years.map((year, i) => {
        const isPenultimateYear = i === years.length - 1; // last year isn't part of this array

        return (
          <div
            key={year}
            style={{
              width: 0,
              paddingLeft: 8,
              height: variant === "short" || i === 0 ? 106 : 285,
              borderLeft: `1px solid ${colors.grey400}`,
              // zIndex: -1,
              overflow: "visible",
            }}
          >
            <Text
              size={14}
              style={{ width: "max-content", lineHeight: "20px" }}
            >
              <div
                ref={
                  isPenultimateYear && [0, 2].includes(labelTruncationLevel)
                    ? penultimateLabelRef
                    : undefined // use the bigger label
                }
              >
                {
                  {
                    0: `Year ${i}`,
                    1: `Y${i}`,
                    2: `Y${i}`,
                    3: "",
                  }[labelTruncationLevel]
                }
              </div>
            </Text>
            <Text
              size={14}
              weight="bold"
              style={{ width: "max-content", lineHeight: "20px" }}
            >
              <div
                ref={
                  isPenultimateYear && labelTruncationLevel === 1
                    ? penultimateLabelRef
                    : undefined // use the bigger label
                }
              >
                {labelTruncationLevel < 2 ? year : ""}
              </div>
            </Text>
          </div>
        );
      })}

      {/* zIndex is here to display on top of Events' outline */}
      <div
        ref={lastLineRef}
        style={{ borderRight: `1px solid ${colors.grey400}` }}
        // style={{ borderRight: `1px solid ${colors.grey400}`, zIndex: 2 }}
      />
    </div>
  );
}

function HorizontalLine(props: { children: ReactNode }) {
  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        top: 106,
        display: "flex",
        alignItems: "center",
        height: 1,
        backgroundColor: colors.grey400,
      }}
      {...props}
    />
  );
}

function Today({
  variant,
  status,
  orientation,
  leftOffset,
  labelRef,
}: {
  variant: Props["variant"];
  status: Application["status"];
  orientation: "left" | "right";
  leftOffset: string;
  labelRef: RefObject<HTMLDivElement>;
}) {
  return (
    <Text
      size={12}
      style={{
        height: variant === "short" ? 53 : 232,
        position: "absolute",
        top: -53,
        left: leftOffset,
        borderLeft: `1px solid ${colors.grey900}`,
        whiteSpace: "nowrap",
        zIndex: 2,
      }}
    >
      <div
        ref={labelRef}
        style={{
          position: "absolute",
          right: orientation === "left" ? 4 : undefined,
          left: orientation === "right" ? 4 : undefined,
          marginTop: -6,
          padding: 2,
          backgroundColor: colors.white,
        }}
      >
        Status Today:
        <br />
        <b>{capitalize(status)}</b>
      </div>
    </Text>
  );
}

function ExpectedApprovalWindow({
  width,
  leftOffset,
}: {
  width: string;
  leftOffset: string;
}) {
  return (
    <div
      style={{
        position: "absolute",
        height: 34,
        boxSizing: "border-box",
        width,
        left: leftOffset,
        backgroundColor: "#e0f7fa",
        // opacity: 0.2,
        border: "2px solid #00bcd4",
        borderRadius: 17,
        zIndex: -2,
      }}
    />
  );
}

interface EventProps {
  event: IEventViewModel;
  ordinalNumber: number;
  leftOffset: string;
  isExpanded: boolean;
  isFocused: boolean;
  onIsExpandedChange: (isExpanded: boolean) => void;
  onIsFocusedChange: (isFocused: boolean) => void;
}

const Event = forwardRef<HTMLDivElement, EventProps>(
  (
    {
      event,
      ordinalNumber,
      leftOffset,
      isExpanded,
      isFocused,
      onIsExpandedChange,
      onIsFocusedChange,
    },
    ref
  ) => (
    <div
      style={{
        position: "absolute",
        left: leftOffset,
        marginTop: 2,
        cursor: "pointer",
        zIndex: 1,
      }}
    >
      <Popover
        ref={ref}
        offset={[0, 16]}
        content={() => event.renderPopoverContent(ordinalNumber)}
        variant="light"
        style={{ cursor: "auto", maxWidth: "auto", backgroundColor: "white" }}
        onDismiss={() => onIsExpandedChange(false)}
        isVisible={isExpanded}
        isDismissable
        rendersWhenInvisible={false}
      >
        <div style={{ lineHeight: 0 }}>
          {/* the div has line height 0 so that it's not a bit
          larger than the SVG it contains */}
          <Target
            color={event.color}
            outlineColor={isFocused ? event.color : "white"}
            size={event.size}
            onClick={() => onIsExpandedChange(true)}
            onMouseEnter={() => onIsFocusedChange(true)}
            onMouseLeave={() => onIsFocusedChange(false)}
          />
        </div>
      </Popover>
    </div>
  )
);

function areSameEvent(a: TimelineEvent, b: TimelineEvent): boolean {
  return getEventMainDate(a) === getEventMainDate(b);
}

function getEventMainDate(event: TimelineEvent): Date {
  return event.name === "officeAction" ? event.rejectionDate : event.date;
}

import React, { useState } from "react";

import useApplication from "hooks/useApplication";
import Timeline from "templates/Timeline";

export interface Props {
  applicationNumber: number;
}

/**
 * Created to be used as an iframe.
 */
export default function ({ applicationNumber }: Props) {
  const [isTimelineCollapsed, setIsTimelineCollapsed] = useState(true);
  const {
    status: applicationStatus,
    application,
    error: applicationError,
  } = useApplication({ applicationNumber, applicationId: null });

  if (applicationError) {
    console.error(applicationError);
  }

  if (!application) {
    return null;
  }

  return (
    <Timeline
      today={new Date()}
      application={application}
      isCollapsed={isTimelineCollapsed}
      onIsCollapsedChange={(isCollapsed) => setIsTimelineCollapsed(isCollapsed)}
    />
  );
}

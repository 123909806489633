import React, { forwardRef } from "react";
import { FieldHookConfig, useField } from "formik";

import DatePicker, { Props as DatePickerProps } from "components/DatePicker";

export type Props = Omit<DatePickerProps, "onChange" | "date"> & {
  onChange?: (date: Date) => void;
} & FieldHookConfig<Date>;
// export type Props = DatePickerProps & FieldHookConfig<Date>;

const DateField = forwardRef<HTMLInputElement, Props>(
  ({ label, onChange, ...rest }, ref) => {
    const [field, meta, helpers] = useField<Date>(rest.name);

    return (
      <div style={{ marginBottom: 14 }}>
        <DatePicker
          {...field}
          //   ref={ref}
          date={field.value}
          label={label}
          error={meta.touched && meta.error ? meta.error : undefined}
          onChange={onChange ?? ((date) => helpers.setValue(date))}
          onBlur={() => {}}
        />
      </div>
    );
  }
);

export default DateField;

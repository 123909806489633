import React, { useCallback, useEffect, useState } from "react";
import Text from "components/Text";
import { SimpleLayout } from "../components/Layout/Layout";
import queryString from "query-string";
import {
  downloadPdfFile,
  fetchOpinion,
  OpinionType,
} from "domain/ptabDecisions";
import { Chevron, PdfIcon, X } from "components/Icons";
import { Gap, withDefaultString } from "../components/utils";
import { useLocation, useHistory } from "react-router-dom";
import useDocumentTitle from "hooks/useDocumentTitle";
import Button from "components/Button";
import { format as formatDate } from "domain/dates";
import Link from "components/Link";
import Highlighter from "react-highlight-words";
import styles from "./module.sass";
import useWindowDimensions from "./utils";

const isLine = (str: string) => {
  return [...str].filter((x) => x === "-").length === str.length;
};

const removeExtraLineBreaks = (text?: string): string => {
  if (text && text?.length > 0) {
    const lines: string[] = text.replace(/(\r\n|\n|\r)/gm, "\n").split("\n");

    const res: string[] = [];

    let prevLine = "";
    for (const line of lines) {
      if (line.startsWith("Application ") && prevLine.startsWith("Appeal ")) {
        res.push(`\n${line}`);
      } else if (line.trim() === "") {
        res.push("\n\n");
      } else if (isLine(line)) {
        res.push(`${line}\n`);
      } else {
        res.push(line);
      }

      prevLine = line;
    }

    return res.join("");
  } else {
    return "";
  }
};

const Item = ({
  label,
  value,
  href,
}: {
  label: string;
  value?: string;
  href?: string;
}) => {
  const valueToShow = withDefaultString(value, "-");

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        padding: "8px 0",
        alignItems: "center",
        borderTop: "1px solid rgb(213, 213, 213)",
        width: "100%",
      }}
    >
      <span style={{ minWidth: 150, maxWidth: 150, width: 150 }}>
        <Text color={"lightest"} variant={"italic"}>
          {label}
        </Text>
      </span>

      <Gap />

      {!href && <Text>{valueToShow}</Text>}

      {!!href && (
        <Link href={href} dark openInNewTab>
          {valueToShow}
        </Link>
      )}
    </div>
  );
};

const OpinionPage = () => {
  const location = useLocation();
  const history = useHistory();
  const params = queryString.parse(location.search);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { height: windowHeight } = useWindowDimensions();

  const [decisionId] = useState<number>(
    params && params.decisionId ? parseInt(params.decisionId.toString()) : 675
  );
  const [opinion, setOpinion] = useState<OpinionType>();

  const [searchValue, setSearchValue] = useState("");

  const [activeHighlightIndex, setActiveHighlightIndex] = useState(0);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const highlightColor = "#fff176";

  useDocumentTitle(
    !opinion?.case_name
      ? "Opinion - PatentAdvisor®"
      : `${opinion.case_name} - PatentAdvisor®`
  );

  useEffect(() => {
    setIsLoading(true);
    fetchOpinion({ decisionId })
      .then((x) => {
        if (x) {
          setOpinion(x);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [decisionId]);

  const scrollTo = useCallback(
    (index: number) => {
      const element = document.getElementById(`match_${index}`);
      if (element) {
        setActiveHighlightIndex(index);

        const y =
          element.getBoundingClientRect().top +
          window.pageYOffset -
          windowHeight / 2;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    },
    [setActiveHighlightIndex]
  );

  useEffect(() => {
    scrollTo(0);
  }, [searchValue]);

  const textToHighlight = removeExtraLineBreaks(opinion?.opinion_text);

  var matchCount =
    textToHighlight && searchValue
      ? textToHighlight.toLowerCase().split(searchValue.toLowerCase()).length -
        1
      : 0;

  return (
    <SimpleLayout
      title={withDefaultString(opinion?.case_name, undefined)}
      backText={"Decisions by Issue"}
      onBackClick={
        history.length > 2
          ? () => {
              history.goBack();
            }
          : undefined
      }
      isLoading={isLoading || isDownloading}
      raw={
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            border: "1px solid rgb(213, 213, 213)",
            width: 528,
            height: 44,
            padding: "0 16px",
            boxSizing: "border-box",
            backgroundColor: "white",
            marginBottom: 20,
          }}
        >
          <input
            style={{
              flex: 1,
              height: "100%",
              border: "none",
              outline: "none",
              boxSizing: "border-box",
              color: "rgb(33, 33, 33)",
              fontFamily: "Lato",
              fontSize: 15,
            }}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            value={searchValue}
            placeholder={"Search document"}
          />

          <Gap />

          <Text>{`${
            matchCount > 0 ? activeHighlightIndex + 1 : 0
          }/${matchCount}`}</Text>

          <Gap strip stripSize={24} />

          <Button
            isDisabled={matchCount === 0 || activeHighlightIndex === 0}
            variant={"naked"}
            size={"xxs"}
            onClick={() => {
              scrollTo(activeHighlightIndex - 1);
            }}
          >
            <Chevron up />
          </Button>

          <Gap />

          <Button
            isDisabled={
              matchCount == 0 || activeHighlightIndex === matchCount - 1
            }
            variant={"naked"}
            size={"xxs"}
            onClick={() => {
              scrollTo(activeHighlightIndex + 1);
            }}
          >
            <Chevron />
          </Button>

          <Gap />

          <Button
            isDisabled={!searchValue}
            variant={"naked"}
            size={"xxs"}
            onClick={() => {
              setSearchValue("");
            }}
          >
            <X />
          </Button>
        </div>
      }
    >
      <span
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "space-around",
        }}
      >
        <div
          style={{
            flex: 1,
            height: "100%",
            border: "1px solid rgb(198, 198, 198)",
            padding: 16,
            overflowY: "scroll",
            overflowX: "hidden",
            boxSizing: "border-box",
          }}
        >
          <p
            style={{
              whiteSpace: "pre-line",
              fontFamily: "Lato",
              fontSize: 18,
            }}
          >
            {!!opinion && (
              <Highlighter
                activeIndex={activeHighlightIndex}
                searchWords={[searchValue]}
                autoEscape={true}
                textToHighlight={textToHighlight}
                highlightStyle={{ backgroundColor: highlightColor }}
                highlightTag={({ children, highlightIndex }) => (
                  <span
                    id={`match_${highlightIndex}`}
                    style={{
                      backgroundColor: "rgb(255, 241, 118)",
                      textDecoration:
                        highlightIndex === activeHighlightIndex
                          ? "underline"
                          : "none",
                      textDecorationThickness: 4,
                      textDecorationColor: "rgb(252, 215, 59)",
                    }}
                  >
                    {children}
                  </span>
                )}
              />
            )}
          </p>
        </div>

        <Gap size={50} />

        <div className={styles.about}>
          <span>
            <Text variant={"h2"}>{`About this document`}</Text>
          </span>

          <Gap vertical size={16} />

          <span>
            <Button
              variant={"link"}
              size={"xxs"}
              onClick={() => {
                setIsDownloading(true);
                downloadPdfFile(opinion?.case_name ?? "opinion", decisionId)
                  .then(({ error }) => {
                    if (!!error) {
                      alert(error);
                    }
                  })
                  .finally(() => {
                    setIsDownloading(false);
                  });
              }}
              className={styles.downloadButton}
            >
              <PdfIcon />
              <Gap size={8} />
              <Text color={"link"}>Download PDF</Text>
            </Button>
          </span>

          <Gap vertical size={16} />

          <Item
            label={"Application Number:"}
            value={opinion?.application_number}
            href={opinion?.application_number_link}
          />
          <Item
            label={"Appeal Number:"}
            value={opinion?.appeal_number?.toString()}
          />

          <Item
            label={"Decision Date:"}
            value={
              !opinion?.decision_date
                ? undefined
                : formatDate(new Date(opinion.decision_date), "d/m/Y")
            }
          />

          <Item label={"Case Name:"} value={opinion?.case_name} />

          <Item
            label={"Filing Date:"}
            value={
              !opinion?.filing_date
                ? undefined
                : formatDate(new Date(opinion.filing_date), "d/m/Y")
            }
          />

          <Item
            label={"Examiner Name:"}
            value={opinion?.examiner_name}
            href={`/statistics.php?Parent=Examiner&Examiner=${opinion?.examiner_id}`}
          />
          <Item
            label={"Group Art Unit:"}
            value={opinion?.group_art_unit?.toString()}
          />
          <Item label={"Class:"} value={opinion?.class?.toString()} />
          <Item label={"Subclass:"} value={opinion?.subclass} />
          <Item
            label={"First Named Inventor:"}
            value={opinion?.first_named_inventor}
          />
          <Item
            label={"Title of Invention:"}
            value={opinion?.title_of_invention}
          />

          <Item label={"Panel Judges"} value={opinion?.panel_judges} />
        </div>
      </span>
    </SimpleLayout>
  );
};

export default OpinionPage;

import React from "react";

const Bin = () => (
  <svg
    width="30"
    height="30"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    fill="#666"
  >
    <path d="M25.6 5.9h-7.1V3.5h-7.4v2.4H4.4V10h2.4v16.5h16.5V10h2.4V5.9zM12.3 4.7h5v1.2h-5V4.7zM7.9 10h2.9v15.3H7.9V10zm10 0v15.3h-2.4V10h2.4zm-3.5 15.3H12V10h2.4v15.3zm7.7 0h-2.9V10h2.9v15.3zm2.3-16.5H5.6V7h18.9v1.8z" />
  </svg>
);

export default Bin;

import React, { CSSProperties, ReactNode } from "react";
import { DialogOverlay, DialogContent } from "@reach/dialog";

import Button from "components/Button";
import { X as IconX } from "components/Icons";

import styles from "./module.sass";
import HorizontalRule from "atoms/HorizontalRule";

export type Props = {
  title: string;
  children: ReactNode;
  isOpen: boolean;
  onDismiss: () => void;
};

export default function Popup({ title, children, isOpen, onDismiss }: Props) {
  return (
    <DialogOverlay
      className={styles.overlay}
      isOpen={isOpen}
      onDismiss={onDismiss}
    >
      <DialogContent className={styles.content} aria-label={title}>
        <header className={styles.header}>
          <div>{title}</div>
          <Button size="xxs" variant="naked" onClick={onDismiss}>
            <IconX width={12} height={12} />
          </Button>
        </header>
        <div className={styles.body}>{children}</div>
        <HorizontalRule />
        <footer className={styles.footer}>
          <Button variant="contained" size="sm" onClick={() => onDismiss()}>
            Close
          </Button>
        </footer>
      </DialogContent>
    </DialogOverlay>
  );
}

import { Examiner } from "domain/examiners";
import { ArtUnit } from "domain/artUnits";
import { BASE_URI } from "domain/api";

import { Event } from "./events";

export {
  Event,
  DetaillessEvent,
  AppealEvent,
  OfficeActionEvent,
} from "./events";

export function linkToApplicationDocument(applicationNumber: number): string {
  return `${BASE_URI}/tools/get-pdf.php?an=${applicationNumber}`;
}

export function linkToPatentDocument(patentNumber: number): string {
  return `${BASE_URI}/tools/get-pdf.php?pn=${patentNumber}`;
}

export function linkToQuickPAIR(applicationNumber: number) {
  return `${BASE_URI}/quickpair/${applicationNumber}#info`;
}

export interface Application {
  applicationNumber: number;
  readableApplicationNumber: string;
  patentNumber: number | null;
  readablePatentNumber: string | null;

  title: string;
  status: AppStatus;
  examiner: Examiner | null;
  artUnit: ArtUnit | null;

  events: Event[];
  pdfLinkEnabled: boolean;
}

export type DocumentType =
  | "appeal"
  | "claims"
  | "officeActions"
  | "petition"
  | "responses"
  | "reExam"
  | "terminalDisclaimers"
  | "noticeOfAllowance";

export type AppStatus = "patented" | "abandoned" | "pending" | "unknown";

export type RejectionType =
  | "101"
  | "102"
  | "103"
  | "112"
  | "alice"
  | "mayo"
  | "myriad";

export class AppNumber {
  private value: string;

  private static validPatterns = [
    /^\d{8}$/,
    /^[a-zA-Z]{2}\d{8}$/,
    /^\d{7}$/,
    /^[a-zA-Z]{2}\d{7}$/,
    /^\d{2}\/\d{6}$/,
    /^[a-zA-Z]{2}\d{2}\/\d{6}$/,
    /^\d{1}\/\d{6}$/,
    /^[a-zA-Z]{2}\d{1}\/\d{6}$/,
    /^\d{2}\/\d{3},\d{3}$/,
    /^[a-zA-Z]{2}\d{2}\/\d{3},\d{3}$/,
    /^\d{1}\/\d{3},\d{3}$/,
    /^[a-zA-Z]{2}\d{1}\/\d{3},\d{3}$/,
  ];
  private static invalidPattern = /PCT/i;

  constructor(value: string) {
    this.value = value.trim();
  }

  isValid(): boolean {
    if (AppNumber.invalidPattern.test(this.value)) {
      return false;
    }
    return AppNumber.validPatterns.some((p) => p.test(this.value));
  }
}

export class PatentNumber {
  private value: string;
  private cleanedValue: string;

  private static validPatterns = [
    /^0{0,1}\d{7,8}$/,
    /^0{0,1}\d{1},\d{3},\d{3}$/,
    /^\d{2},\d{3},\d{3}$/,
    /^[D|T]{1}0{0,1}\d{6}$/i,
    /^[D|T]{1}0{0,1}\d{3},\d{3}$/i,
    /^[PP|RE]{2}0{0,1}\d{5,6}$/i,
    /^[PP|RE]{2}\d{2,3},\d{3}$/i,
    /^[H]{1}0{0,1}\d{6}$/i,
    /^[H]{1}0{0,1}\d{3},\d{3}$/i,
  ];

  constructor(value: string) {
    this.value = value.trim();
    this.cleanedValue = this.cleanValue();
  }

  isValid(): boolean {
    return PatentNumber.validPatterns.some((p) => p.test(this.cleanedValue));
  }

  private cleanValue(): string {
    return this.value
      .replace(/^US/, "")
      .replace(/[a-zA-z]{1}$/, "")
      .replace(/[a-zA-Z]{1}\d{1}$/, "");
  }
}

export class PublicationNumber {
  private value: string;
  private cleanedValue: string;

  private static validPatterns = [
    /^\d{11}$/,
    /^\d{11}[a-zA-Z]{1}\d{1}$/,
    /^[a-zA-Z]{2}\d{11}[a-zA-Z]{1}\d{1}$/,
    /^[a-zA-Z]{2}\d{11}$/,
  ];

  constructor(value: string) {
    this.value = value.trim();
    this.cleanedValue = this.cleanValue();
  }

  isValid(): boolean {
    return PublicationNumber.validPatterns.some((p) =>
      p.test(this.cleanedValue)
    );
  }

  private cleanValue(): string {
    return this.value.replace(/\s+/g, "").replace(/\-/g, "");
  }
}

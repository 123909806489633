import React from "react";

const PersonPlus = () => (
  <svg
    width="31"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 31 24"
    fill="#666"
  >
    <path d="M19.59 12.83c3.18 0 5.74-2.84 5.74-6.36S22.77 0 19.59 0s-5.73 2.84-5.73 6.36 2.56 6.47 5.73 6.47zM15 6.47c.1-2.84 2.15-5.11 4.6-5.11a4.86 4.86 0 014.61 5.1 4.86 4.86 0 01-4.59 5.11A4.86 4.86 0 0115 6.48z" />
    <path d="M23.27 12.19v1.37a6.65 6.65 0 016.44 6.81v2.38H10.16v-2.38a6.65 6.65 0 016.44-6.81v-1.37a8 8 0 00-7.73 8.06V24H31v-3.75a7.9 7.9 0 00-7.73-8.06zM10.91 6.97H6.07V1.74H4.91v5.23H0v1.21h4.91v4.91h1.16V8.18h4.84V6.97z" />
  </svg>
);

export default PersonPlus;

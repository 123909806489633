import React, { MouseEvent, useEffect, useRef, useState } from "react";
import cn from "classnames";

import useClickOutside from "hooks/useClickOutside";
import { Chevron } from "components/Icons";
import { BaseInput } from "components/Input";

import styles from "./module.sass";

export type Option<T> = {
  value: T;
  label: string;
};

export type Props<T> = {
  value: T;
  options: Option<T>[];
  onChange: (value: T) => void;

  label?: string;
};

export default function Select<T extends string>({
  value,
  options,
  onChange,
  label = "",
}: Props<T>) {
  const [isOpen, setIsOpen] = useState(false);
  const triggerRef = useRef<HTMLButtonElement>(null);
  const selectedOption = options.find((o) => o.value === value);

  useClickOutside(triggerRef, () => setIsOpen(false));

  const handleOptionClick = (clickedOption: Option<T>) => {
    setIsOpen(false);

    if (clickedOption.value !== value) {
      onChange(clickedOption.value);
    }
  };

  return (
    <button
      onClick={(e) => setIsOpen(!isOpen)}
      className={cn(
        styles.trigger,
        isOpen && styles.trigger$open,
        value && styles.trigger$withValue
      )}
      ref={triggerRef}
      type="button"
    >
      <BaseInput
        label={label}
        value={selectedOption?.label ?? ""}
        icon={<Chevron className={styles.chevron} />}
        labelPointerEvents="none"
        isClearable={false}
        isReadOnly={true}
        isLabelEager={false}
        onChange={() => {}}
      />
      {isOpen && (
        <div className={styles.dropdown}>
          <div className={styles.options}>
            <div>
              {options.map((o) => (
                <button
                  key={o.value}
                  onClick={(e) => handleOptionClick(o)}
                  className={cn(
                    styles.option,
                    o === selectedOption && styles.option$selected
                  )}
                  type="button"
                >
                  {o.label}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </button>
  );
}

export type MultiProps<T> = {
  values: T[];
  options: Option<T>[];
  onChange: (values: T[]) => void;
  label: string;
};

export function MultiSelect<T extends string>({
  values,
  options,
  onChange,
  label,
}: MultiProps<T>) {
  const [displayText, setDisplayText] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const triggerRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    setDisplayText(
      options
        .filter((o) => values.includes(o.value))
        .map((o) => o.label)
        .join(", ")
    );
  }, [values, options]);

  const handleEllipsis = () => {
    const shortDisplayText =
      values.length === 1
        ? "1 filter selected"
        : `${values.length} filters selected`;

    if (displayText !== shortDisplayText) {
      setDisplayText(shortDisplayText);
    }
  };

  const handleOptionClick = (
    e: MouseEvent<HTMLButtonElement>,
    option: Option<T>
  ) => {
    e.stopPropagation();
    onChange(
      values.includes(option.value)
        ? values.filter((v) => v !== option.value)
        : [...values, option.value]
    );
  };

  useClickOutside(triggerRef, () => {
    setIsOpen(false);
  });

  return (
    <button
      onClick={(e) => setIsOpen(!isOpen)}
      className={cn(
        styles.trigger,
        isOpen && styles.trigger$open,
        values.length && styles.trigger$withValue
      )}
      ref={triggerRef}
      type="button"
    >
      <BaseInput
        label={label}
        value={displayText}
        icon={<Chevron className={styles.chevron} />}
        labelPointerEvents="none"
        isClearable={false}
        isReadOnly={true}
        isLabelEager={false}
        onChange={() => {}}
        onEllipsis={handleEllipsis}
      />
      {isOpen && (
        <div className={styles.dropdown}>
          <div className={styles.options}>
            <div>
              {options.map((o) => (
                <button
                  key={o.value}
                  onClick={(e) => handleOptionClick(e, o)}
                  className={cn(
                    styles.option,
                    values.includes(o.value) && styles.option$selected
                  )}
                  type="button"
                >
                  {o.label}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </button>
  );
}

import React, { AnchorHTMLAttributes, ReactNode, RefObject } from "react";

import styles from "./module.sass";
import cn from "classnames";

export type Option<V extends string = string> = {
  value: V;
  label: ReactNode;

  searchableLabel?: string;
  ref?: RefObject<HTMLButtonElement>;
};

export type Props<V extends string = string> = {
  option: Option<V>;
  onSelect: (option: Option<V>) => void;
  isSelected?: boolean;
};

export default function SelectOption<V extends string = string>({
  option,
  onSelect,
  isSelected,
}: Props<V>) {
  return (
    <button
      type="button"
      ref={option.ref}
      className={cn(
        styles.selectOption,
        isSelected && styles.selectOption$selection
      )}
      onClick={() => onSelect(option)}
    >
      {option.label}
    </button>
  );
}

export interface BaseOptionProps
  extends AnchorHTMLAttributes<HTMLAnchorElement> {}

export function LinkOption({ className, ...rest }: BaseOptionProps) {
  return (
    <a
      className={cn(styles.selectOption, styles.selectOption$link, className)}
      {...rest}
    />
  );
}

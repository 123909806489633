import React from "react";

import Text from "atoms/Text";
import { X as XIcon } from "components/Icons";

import styles from "./module.sass";

export type State = "on" | "off";

export interface Props {
  state: State;
  onToggle: (newState: State) => void;
}

export default function HelpToggle({ state, onToggle }: Props) {
  const stateToIcon = {
    on: <XIcon width={8} height={8} />,
    off: (
      <Text size={18} weight="bold">
        ?
      </Text>
    ),
  };

  return (
    <button
      className={[styles.helpToggle, styles[`helpToggle$${state}`]].join(" ")}
      onClick={() => onToggle(state === "on" ? "off" : "on")}
    >
      {stateToIcon[state]}
    </button>
  );
}

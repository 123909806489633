import React, { CSSProperties, ReactNode } from "react";
import cn from "classnames";

import styles from "./module.sass";

export default function Link({
  href,
  children,
  isFluid = false,
  dark,
  openInNewTab,
  className,
  style = { fontSize: 15 },
  onBeforeNavigate,
}: {
  href: string;
  children: ReactNode;
  isFluid?: boolean;
  dark?: boolean;
  openInNewTab?: boolean;
  className?: string;
  style?: CSSProperties;
  onBeforeNavigate?: () => void;
}) {
  return (
    <a
      target={openInNewTab ? "_blank" : undefined}
      href={href}
      className={cn(
        styles.link,
        isFluid && styles.link$fluid,
        dark && styles.dark,
        className
      )}
      style={style}
      onClick={onBeforeNavigate}
    >
      {children}
    </a>
  );
}

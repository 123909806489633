import React from "react";
import Tippy, { TippyProps } from "@tippyjs/react";
import { followCursor } from "tippy.js";
import ReactTooltip, { TooltipProps } from "react-tooltip";

import colors from "style/colors.module.scss";

import styles from "./module.sass";
import cn from "classnames";

export type Props = TooltipProps;

/**
 * @example
 * <>
 *   <span data-tip="tooltip content" data-for="tooltip-id">hover over</span>
 *   <Tooltip id="tooltip-id" />
 * </>
 */
export default function Tooltip(props: Props) {
  return (
    <ReactTooltip
      place="top"
      effect="solid"
      textColor="white"
      className={styles.tooltip}
      backgroundColor={colors.darkBlue800}
      arrowColor={colors.darkBlue800}
      delayShow={575}
      // delayUpdate={575}
      delayHide={125}
      {...props}
    />
  );
}

export type StickyTooltipProps = TippyProps;

/**
 * @deprecated
 */
const StickyTooltip = ({
  className,
  placement = "right",
  maxWidth = 320,
  offset = [25, 12],
  ...rest
}: StickyTooltipProps) => {
  return (
    <Tippy
      {...rest}
      className={cn(styles.stickyTooltip, className)}
      placement={placement}
      offset={offset}
      maxWidth={maxWidth}
      followCursor={true}
      plugins={[followCursor]}
    />
  );
};

export { StickyTooltip };

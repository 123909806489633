import React, { useEffect, useRef, useState } from "react";
import { useQueryParam, NumberParam, StringParam } from "use-query-params";

import useDocumentTitle from "hooks/useDocumentTitle";
import MainContainer from "atoms/Layout";
import FreeTrialForm, { Step } from "organisms/FreeTrialForm";
import { EmptyNavbar } from "components/Navbar";
import Footer from "components/Footer";
import { submitRequest, TrialRequest } from "domain/trial";
import useIsLoggedIn from "hooks/useIsLoggedIn";
import { RouteComponentProps } from "react-router";
import { VerificationStatus, resendEmailVerification } from "domain/trial";
import PageLoader from "molecules/PageLoader";
import SkipNavigationLink from "atoms/SkipNavigationLink";

import styles from "./module.sass";
import cn from "classnames";

export default function FreeTrialPage(
  { isMinimal }: { isMinimal: boolean },
  props: RouteComponentProps
) {
  const { isLoggedIn, status: isLoggedInStatus } = useIsLoggedIn();

  const shouldRedirectToHome = isLoggedInStatus === "success" && isLoggedIn;
  if (shouldRedirectToHome) {
    window.location.href = "/";
  }

  const [verificationStatus] = useQueryParam("verification", NumberParam);
  const [defaultEmail] = useQueryParam("email", StringParam);
  const [isLoading, setIsLoading] = useState(false);

  const defaultStep =
    verificationStatus === VerificationStatus.Expired && defaultEmail
      ? "verificationExpired"
      : "first";
  const [step, setStep] = useState<Step>(defaultStep);
  const [invalidFields, setInvalidFields] = useState<string[]>([]);
  const firstStepRef = useRef<HTMLInputElement>(null);

  useDocumentTitle("LexisNexis PatentAdvisor\u00ae - Free Trial");
  useEffect(() => {
    if (isMinimal) {
      return;
    }
    firstStepRef.current?.focus();
  }, []);

  const handleChangeStep = (newStep: Step) => {
    setStep(newStep);

    if (newStep === "first") {
      firstStepRef.current?.focus();
      return;
    }
  };

  const handleSubmit = async (trialRequest: TrialRequest) => {
    setInvalidFields([]);
    setIsLoading(true);

    try {
      const resp = await submitRequest(trialRequest);
      if (resp.status === 202) {
        setStep("throttled");
        return;
      }
      setStep("success");
    } catch (err) {
      const resp = err?.response;
      if (!resp) {
        setStep("oops");
        console.error(err);
        return;
      }
      if (resp.status === 500) {
        setStep("oops");
        return;
      }
      if (resp.status === 422) {
        setInvalidFields(resp.data.invalidFields);
        return;
      }
      if (resp.status === 403) {
        setStep("competitor");
        return;
      }
      if (resp.status === 401) {
        setStep("customer");
        return;
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendVerification = async (email: string) => {
    try {
      await resendEmailVerification(email);
      setStep("verificationResent");
    } catch (err) {
      console.error(err);
      setStep("oops");
    }
  };

  return (
    <MainContainer
      variant="lightGrey"
      className={cn(isMinimal && styles.marketingFreeTrialContainer)}
    >
      <>
        <SkipNavigationLink />
        {!isMinimal && <EmptyNavbar />}
        {!isMinimal && <PageLoader isLoading={isLoading} />}
        <main
          className={cn(
            styles.formContainer,
            isMinimal && styles.formContainer$marketing
          )}
        >
          <FreeTrialForm
            step={step}
            firstStepRef={firstStepRef}
            invalidFields={invalidFields}
            defaultEmail={defaultEmail ?? ""}
            onSubmit={handleSubmit}
            onChangeStep={handleChangeStep}
            onResendVerification={handleResendVerification}
            isMinimal={isMinimal}
          />
        </main>
        {!isMinimal && step === "first" && (
          <Footer variant="white" position="static" />
        )}
      </>
    </MainContainer>
  );
}

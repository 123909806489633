/**
 * @todo Should Google Analytics be removed when we
 * implement Matomo?
 */
import React, { RefObject, useState, HTMLAttributes, useEffect } from "react";
import cn from "classnames";
import TagManager from "react-gtm-module";

import { TrialRequest } from "domain/trial";
import Text, { Props as TextProps } from "atoms/Text";
import Anchor from "atoms/Anchor";
import Button from "components/Button";
import { getEnvironment, Environment } from "domain/environment";

import FirstStep, { Values } from "./FirstStep";
import styles from "./module.sass";
import Copy from "./Copy";

export type Step =
  | "first"
  | "oops"
  | "competitor"
  | "customer"
  | "throttled"
  | "success"
  | "verificationExpired"
  | "verificationResent";

export type Props = {
  step: Step;
  onSubmit: (trialRequest: TrialRequest) => void;
  onChangeStep: (newStep: Step) => void;

  defaultEmail?: string;
  invalidFields?: string[];
  firstStepRef?: RefObject<HTMLInputElement>;
  onResendVerification?: (email: string) => void;
  isMinimal?: boolean;
};

export default function FreeTrialForm({
  step,
  firstStepRef,
  defaultEmail = "",
  invalidFields = [],
  onSubmit,
  onChangeStep,
  onResendVerification = () => {},
  isMinimal,
}: Props) {
  const [firstStepData, setFirstStepData] = useState<Values | null>(null);

  useEffect(() => {
    const script = document.createElement("script");
    const scriptTest = document.createElement("script");
    const hubspotScript = document.createElement("script");

    script.src = "/assets/js/form-complete.js";
    scriptTest.src = "/assets/js/form-complete-test.js";
    hubspotScript.src = "//js.hs-scripts.com/317639.js";

    script.async = true;
    scriptTest.async = true;
    hubspotScript.async = true;
    hubspotScript.defer = true;

    if (getEnvironment() === Environment.Production) {
      TagManager.initialize({ gtmId: "GTM-5F6SXLF" });
      document.body.appendChild(script);
    } else {
      document.body.appendChild(scriptTest);
    }
    document.body.appendChild(hubspotScript);

    return () => {
      if (getEnvironment() === Environment.Production) {
        document.body.removeChild(script);
      } else {
        document.body.removeChild(scriptTest);
      }
      document.body.removeChild(hubspotScript);
      window.location.reload();
    };
  }, []);

  const handleFirstStepSubmit = (values: Values) => {
    setFirstStepData(values);
    if (!values) {
      onChangeStep("first");
      return;
    }

    const request: TrialRequest = { ...values };

    if (getEnvironment() === Environment.Production) {
      TagManager.dataLayer({
        dataLayer: {
          event: "trialFormSubmit",
        },
      });
    }

    onSubmit(request);
  };

  return (
    <section
      className={cn(
        styles.freeTrialForm,
        isMinimal && styles.freeTrialForm$marketing,
        ["throttled", "verificationExpired", "verificationResent"].includes(
          step
        )
          ? styles.freeTrialForm$min
          : null
      )}
    >
      {!isMinimal &&
      !["throttled", "verificationExpired", "verificationResent"].includes(
        step
      ) ? (
        <header className={styles.header}>
          <a href="/login.php" className={cn(styles.tab, styles.tab$anchor)}>
            Sign In
          </a>
          <div className={cn(styles.tab, styles.tab$active)}>Sign Up</div>
        </header>
      ) : null}
      <div className={styles.body}>
        {step === "first" && (
          <>
            <div className={styles.form}>
              <FirstStep
                ref={firstStepRef}
                initialValues={firstStepData ?? undefined}
                onSubmit={handleFirstStepSubmit}
                invalidFields={invalidFields}
              />
            </div>
            {!isMinimal && <Copy />}
          </>
        )}
        {step === "oops" && <Oops />}
        {step === "competitor" && <Competitor />}
        {step === "customer" && <Customer />}
        {step === "throttled" && <Throttled />}
        {step === "verificationExpired" && (
          <VerificationExpired
            email={firstStepData?.businessEmail ?? defaultEmail}
            onResendVerification={onResendVerification}
          />
        )}
        {step === "verificationResent" && (
          <VerificationResent
            email={firstStepData?.businessEmail ?? defaultEmail}
          />
        )}
        {step === "success" && (
          <Success email={firstStepData?.businessEmail ?? defaultEmail} />
        )}
      </div>
    </section>
  );
}

const Message = ({
  margin = "default",
  children,
  style,
}: HTMLAttributes<HTMLDivElement> & { margin?: "default" | "small" }) => (
  <div
    style={{
      margin: margin === "default" ? "94px 16px" : "75px 16px",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      ...style,
    }}
  >
    {children}
  </div>
);

const MessageHeading = ({ children, style }: TextProps) => (
  <Text
    size={23}
    color="blue900"
    weight="bold"
    style={{ marginBottom: 24, ...style }}
  >
    {children}
  </Text>
);

const MessageBody = ({ style, ...rest }: TextProps) => (
  <Text style={{ lineHeight: "22px", fontSize: 16, ...style }} {...rest} />
);

const Oops = () => (
  <Message style={{ width: 405 }}>
    <MessageHeading>Oops! Something went wrong.</MessageHeading>
    <MessageBody>
      Your request can not be processed. Please try again later.
      <p>
        If you continue to experience problems, please reach out to our{" "}
        <Anchor href="mailto:email@lexisnexisip.com" target="_blank">
          Customer Support team
        </Anchor>
        .
      </p>
    </MessageBody>
  </Message>
);

const Competitor = () => (
  <Message>
    <MessageHeading>
      So sorry... You've been identified as a potential competitor, and we limit
      access to our product when that happens.
    </MessageHeading>
    <MessageBody>
      If you feel you have gotten this message in error, please reach out to our
      <br />
      <Anchor href="mailto:email@lexisnexisip.com" target="_blank">
        Customer Support team
      </Anchor>{" "}
      for further verification.
    </MessageBody>
  </Message>
);

const Customer = () => (
  <Message>
    <MessageHeading>
      Thank you for expressing interest in the LexisNexis PatentAdvisor
      Extension.
    </MessageHeading>
    <MessageBody>
      In order to make the most out of the extension and the full PatentAdvisor
      solution, our team will be in touch to provide login credentials and
      discuss your specific needs and requirements to ensure a personalized and
      tailored experience.
      <br />
      <br />
      <Anchor href="mailto:email@lexisnexisip.com" target="_blank">
        Customer Support team
      </Anchor>{" "}
      for further verification.
    </MessageBody>
  </Message>
);

const Throttled = () => (
  <Message margin="small" style={{ width: 570 }}>
    <MessageHeading style={{ marginBottom: 16 }}>
      Thank you for requesting access to PatentAdvisor.
    </MessageHeading>
    <MessageBody style={{ textAlign: "left" }}>
      Currently, we are experiencing a large number of requests, so it will take
      24-48 hours for your account to be verified. Once verified, you will
      receive an email from us and will be able to sign in.​
    </MessageBody>
  </Message>
);

const Success = ({ email }: { email: string }) => (
  <Message style={{ width: 515 }}>
    <MessageHeading style={{ marginBottom: 16 }}>
      Thanks for signing up!
    </MessageHeading>
    <MessageBody style={{ textAlign: "left" }}>
      You will receive an email at&nbsp;
      <Text as="span" weight="bold">
        {email}
      </Text>
      &nbsp;from us shortly.
      <br />
      Please click the link within to verify your email. If you don't see an
      email from us, check your spam folder.
    </MessageBody>
  </Message>
);

const VerificationExpired = ({
  email,
  onResendVerification,
}: {
  email: string;
  onResendVerification: (email: string) => void;
}) => (
  <Message margin="small" style={{ width: 490 }}>
    <MessageHeading style={{ marginBottom: 16 }}>
      This email verification link has expired
    </MessageHeading>
    <MessageBody style={{ textAlign: "left" }}>
      It looks like this verification link has expired. No worries, we can send
      a new link to&nbsp;
      <Text as="span" weight="bold">
        {email}
      </Text>
      .
      <div style={{ marginTop: 34, display: "flex", justifyContent: "center" }}>
        <Button
          size="sm"
          variant="contained"
          onClick={() => {
            onResendVerification(email);
          }}
        >
          Resend verification link
        </Button>
      </div>
    </MessageBody>
  </Message>
);

const VerificationResent = ({ email }: { email: string }) => (
  <Message margin="small" style={{ width: 510 }}>
    <MessageHeading style={{ marginBottom: 16 }}>
      Your new link is on the way
    </MessageHeading>
    <MessageBody style={{ textAlign: "left" }}>
      We send a new verification link to&nbsp;
      <Text as="span" weight="bold">
        {email}
      </Text>
      .&nbsp;Please click the link within to verify your email. If you don't see
      an email from us, check your spam folder.
    </MessageBody>
  </Message>
);

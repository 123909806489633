import React, { useCallback, useEffect, useState } from "react";
import Text from "components/Text";
import {
  fetchArguments,
  fetchArtUnits,
  fetchExaminers,
  ArgumentType,
  DecisionTypes,
  ExaminerType,
} from "domain/ptabDecisions";
import Tree, { TreeHeader } from "../components/Tree/Tree";
import Button from "components/Button";
import Layout from "../components/Layout/Layout";
import { NoResults } from "components/MySavedWork";
import ReactSelect from "react-select";
import TextInput, { Label } from "../components/TextInput";
import {
  DictionaryItemType,
  Gap,
  getParams,
  getReactSelectDefaultProps,
  isNumber,
  range,
  RaceConditionGuard,
} from "../components/utils";
import Pill from "components/Pill";
import { useLocation, useHistory } from "react-router-dom";
import useDocumentTitle from "hooks/useDocumentTitle";
import {
  findSuccessors,
  findAncestors,
  findSequences,
} from "../components/Tree/utils";
import styles from "./module.sass";
import queryString from "query-string";
import cn from "classnames";

export type ParamsType = {
  artUnitValue?: string;
  year?: string;
  examinerId?: string;
  examinerLabel?: string;
  decisionType?: DecisionTypes;
};

const DECISIONS_TYPES = [
  DecisionTypes.Informative,
  DecisionTypes.Precedential,
  DecisionTypes.Final,
].map((x) => ({
  value: x,
  label: x,
}));

type TREE_STATE_TYPE = {
  selectedIds: Array<number>;
  undefinedIds: Array<number>;
  openIds: Array<number>;
  filters: ParamsType;
};

const sortParams = (args: ParamsType): ParamsType => {
  return {
    artUnitValue: `${args.artUnitValue}`,
    decisionType: args.decisionType,
    examinerId: `${args.examinerId}`,
    examinerLabel: args.examinerLabel,
    year: `${args.year}`,
  };
};

const TREE_STATE_KEY = "ptab.tree.state";

export const PTAB_DECISIONS_SEARCH_KEY = "ptab.decisions.search";

const loadTreeState = (): TREE_STATE_TYPE | undefined => {
  const treeStateStr = window.sessionStorage.getItem(TREE_STATE_KEY);

  try {
    return treeStateStr && treeStateStr?.length > 0
      ? JSON.parse(treeStateStr)
      : undefined;
  } catch {
    return undefined;
  }
};

const saveTreeState = (args: TREE_STATE_TYPE) => {
  window.sessionStorage.setItem(TREE_STATE_KEY, JSON.stringify(args));
};

const clearTreeState = () => {
  window.sessionStorage.removeItem(TREE_STATE_KEY);
};

const examinerRaceConditionGuard = new RaceConditionGuard();

const YEARS = range(2007, new Date().getFullYear())
  .sort((a, b) => b - a)
  .map((x) => x.toString());

const PTABDecisionsPage = () => {
  useDocumentTitle("PTAB Decisions - PatentAdvisor®");

  const location = useLocation();
  const history = useHistory();

  const {
    year: _year,
    artUnitValue: _artUnitValue,
    examinerId: _examinerId,
    examinerLabel: _examinerLabel,
    decisionType: _decisionType,
  }: ParamsType = queryString.parse(location.search);

  const [openIds, setOpenIds] = useState<Array<number>>([]);

  const onOpenChange = useCallback(
    (id: number) => {
      if (openIds.some((x) => x === id)) {
        setOpenIds(openIds.filter((x) => x !== id));
      } else {
        setOpenIds([...openIds, id]);
      }
    },
    [openIds]
  );

  const [items, setItems] = useState<Array<ArgumentType>>([]);
  const [sequences, setSequences] = useState<Array<Array<ArgumentType>>>([]);
  const [allArtUnits, setAllArtUnits] = useState<
    Array<{ value: string; label: string }>
  >([]);
  const [searchHierarchy, setSearchHierarchy] = useState<string>();
  const [examiners, setExaminers] = useState<Array<ExaminerType>>([]);
  const [examinerName, setExaminerName] = useState<string>("");
  const [isExaminersLoading, setExaminersLoading] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [artUnitValue, setArtUnitValue] = useState<{
    value: string;
    label: string;
  } | null>(
    _artUnitValue && isNumber(_artUnitValue)
      ? { value: _artUnitValue, label: _artUnitValue }
      : null
  );

  const [decisionType, setDecisionType] = useState<DictionaryItemType | null>(
    !!_decisionType
      ? DECISIONS_TYPES.find((x) => x.value === _decisionType) ?? null
      : null
  );

  const [years] = useState<Array<DictionaryItemType>>(
    YEARS.map((x) => ({ value: x, label: x }))
  );

  useEffect(() => {
    setSequences(findSequences(items));
  }, [items]);

  const [year, setYear] = useState<DictionaryItemType | null>(
    _year && isNumber(_year) && YEARS.some((x) => x === _year)
      ? { value: _year, label: _year }
      : { value: "2017", label: "2017" }
  );

  const [
    examinerOption,
    setExaminerOption,
  ] = useState<DictionaryItemType | null>(
    _examinerId && _examinerLabel && isNumber(_examinerId)
      ? { value: _examinerId, label: _examinerLabel }
      : null
  );

  const [args, setArgs] = useState<ParamsType>({
    artUnitValue: artUnitValue?.value,
    year: year?.value,
    examinerId: examinerOption?.value,
    examinerLabel: examinerOption?.label,
    decisionType: decisionType?.value,
  });

  useEffect(() => {
    if (examinerName?.length >= 3) {
      setExaminersLoading(true);
      examinerRaceConditionGuard
        .getGuardedPromise(fetchExaminers({ examinerName }))
        .then((res) => {
          setExaminers(res);
        })
        .finally(() => {
          setExaminersLoading(false);
        });
    } else {
      examinerRaceConditionGuard.getGuardedPromise(
        new Promise(() => {
          setExaminers([]);
          setExaminersLoading(false);
        })
      );
    }
  }, [examinerName]);

  useEffect(() => {
    setIsLoading(true);

    history.replace({ search: getParams(args).toString() });

    fetchArguments(args ?? {})
      .then((res) => {
        res?.forEach((item) => (item.isChecked = false));
        setItemsWithTreeStateRestore(res);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [args]);

  const setItemsWithTreeStateRestore = useCallback(
    (newItems: Array<ArgumentType>) => {
      const treeState = loadTreeState();

      if (treeState) {
        const { filters, openIds, selectedIds, undefinedIds } = treeState;

        if (
          JSON.stringify(sortParams(args)) ===
          JSON.stringify(sortParams(filters))
        ) {
          setItems(
            (newItems ?? []).map((x) => ({
              ...x,
              isChecked: (selectedIds ?? []).some((id: number) => id === x.id)
                ? true
                : (undefinedIds ?? []).some((id: number) => id === x.id)
                ? undefined
                : false,
            }))
          );

          setOpenIds(openIds);
        } else {
          setItems(newItems.map((item) => ({ ...item, isChecked: false })));
        }
      } else {
        setItems(newItems.map((item) => ({ ...item, isChecked: false })));
      }
    },
    [args]
  );

  useEffect(() => {
    fetchArtUnits().then((allArtUnits) => {
      setAllArtUnits(
        allArtUnits?.map((x) => ({ value: x.name, label: x.name }))
      );
    });
  }, []);

  const onSelectionChange = useCallback(
    (id: number, childrenIds: Array<number>) => {
      const itemsCopy = items?.map((x) => ({ ...x }));
      const sourceItem = itemsCopy?.find((x) => x.id === id);

      if (!sourceItem) return;

      const children =
        itemsCopy?.filter((item) =>
          childrenIds?.some((id) => id === item.id)
        ) ?? [];

      if (children?.length > 0) {
        if (sourceItem.isChecked === true) {
          children.forEach((item) => (item.isChecked = false));
        } else {
          children.forEach((item) => (item.isChecked = true));
        }
      }

      sourceItem.isChecked = !(sourceItem.isChecked ?? false);

      findAncestors(itemsCopy, id)
        .sort((a, b) => b.tree_lvl - a.tree_lvl)
        .forEach((ancestor) => {
          const successors = findSuccessors(itemsCopy, ancestor.id);

          const someChecked = successors.some((x) => x.isChecked === true);
          const someUnhecked = successors.some((x) => x.isChecked !== true);
          const allChecked = someChecked && !someUnhecked;

          if (someChecked && someUnhecked) {
            ancestor.isChecked = undefined;
          } else if (allChecked) {
            ancestor.isChecked = undefined;
          } else {
            ancestor.isChecked = false;
          }
        });

      setItems(itemsCopy);
    },
    [items, setItems]
  );

  const navigateToDecisionsPage = useCallback(() => {
    const issueIds = items?.filter((x) => x.is_issue)?.map((x) => x.id) ?? [];
    const tagIds = items?.filter((x) => !x.is_issue)?.map((x) => x.id) ?? [];

    const selectedIds =
      items?.filter((x) => x.isChecked === true)?.map((x) => x.id) ?? [];

    saveTreeState({
      selectedIds,
      undefinedIds:
        items?.filter((x) => x.isChecked === undefined)?.map((x) => x.id) ?? [],
      openIds,
      filters: args,
    });

    const urlParams = getParams({
      issues: JSON.stringify(
        selectedIds?.filter((selectedId) =>
          issueIds.some((x) => x === selectedId)
        )
      ),
      tags: JSON.stringify(
        selectedIds?.filter((selectedId) =>
          tagIds.some((x) => x === selectedId)
        )
      ),
      artUnitValue: args?.artUnitValue,
      examinerId: args?.examinerId,
      year: args?.year,
      decisionType: args?.decisionType,
    });

    window.sessionStorage.setItem(PTAB_DECISIONS_SEARCH_KEY, location.search);

    history.push({
      pathname: "/decisions",
      search: urlParams?.toString() ?? "",
    });
  }, [items, artUnitValue, args, openIds]);

  const onSeachHierarchyChange = useCallback(
    (value: string) => {
      setSearchHierarchy(value);

      if (!!value && value?.length > 0) {
        const newOpenIds: Array<number> = [];
        const matchIds = items
          .filter(
            (x) => x.name?.toLowerCase()?.indexOf(value.toLowerCase()) >= 0
          )
          ?.map((x) => x.id);

        matchIds.forEach((matchId) => {
          const sequence = (
            sequences?.find((sequence) =>
              sequence?.some((x) => x.id === matchId)
            ) ?? []
          )?.map((x) => x.id);

          for (let id of sequence) {
            if (id === matchId) {
              break;
            }
            newOpenIds.push(id);
          }
        });
        setOpenIds([...new Set(newOpenIds)]);
      } else {
        setOpenIds([]);
      }
    },
    [sequences]
  );

  const isPillsAvailable =
    args &&
    (!!args.examinerLabel || !!args.artUnitValue || !!args.decisionType);

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      let scrollTop = window.scrollY;

      if (scrollTop > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Layout
      title={"PTAB Decisions"}
      isLoading={isLoading}
      subtitle={"Locate relevant issues in PTAB decision documents."}
      filters={
        <>
          <Label label={"Examiner"}>
            <ReactSelect
              {...getReactSelectDefaultProps({
                width: 200,
                hasValue: !!examinerOption,
                hasOptions: false,
              })}
              value={examinerOption}
              inputValue={examinerName}
              onChange={setExaminerOption}
              onInputChange={setExaminerName}
              options={examiners?.map((x) => ({
                value: `${x.id}`,
                label: x.name,
              }))}
              placeholder={""}
              isClearable={true}
              isSearchable={true}
              isLoading={isExaminersLoading}
              noOptionsMessage={() => "No results"}
              filterOption={() => true}
              isDisabled={isLoading}
            />
          </Label>

          <Gap />

          <Label label={"Art Unit"}>
            <ReactSelect
              {...getReactSelectDefaultProps({
                width: 120,
                hasValue: !!artUnitValue,
                hasOptions: allArtUnits?.length > 0,
              })}
              onChange={setArtUnitValue}
              value={artUnitValue}
              options={allArtUnits}
              isClearable={true}
              isSearchable={true}
              placeholder={"E.g., 2122"}
              isDisabled={isLoading}
            />
          </Label>

          <Gap />

          <Label
            label={"Search from:"}
            tooltipPlace={"bottom"}
            tooltip={
              "All PTAB cases are indexed, however data prior to 2017 may not be fully tagged with arguments."
            }
          >
            <ReactSelect
              {...getReactSelectDefaultProps({
                width: 120,
                hasValue: false,
                hasOptions: true,
              })}
              onChange={setYear}
              value={year}
              options={years}
              isDisabled={isLoading}
            />
          </Label>

          <Gap />

          <Label
            label={"Decision Type"}
            className={styles.decisionType}
            tooltipPlace={"bottom"}
            tooltip={
              "99.9% of all decisions are Final. Informative and Precedential decisions are extremely rare."
            }
          >
            <ReactSelect
              {...getReactSelectDefaultProps({
                hasValue: decisionType !== null,
                hasOptions: true,
                width: 150,
              })}
              onChange={setDecisionType}
              placeholder={"Select Type"}
              value={decisionType}
              options={DECISIONS_TYPES}
              isClearable={true}
              isSearchable={false}
              isDisabled={isLoading}
            />
          </Label>

          <Gap />

          <TextInput
            value={searchHierarchy ?? ""}
            label={"Search Issues"}
            placeholder={"Enter Keywords"}
            onChange={onSeachHierarchyChange}
            isReadOnly={isLoading}
            className={styles.searchHierarchyInput}
          />

          <Gap />

          <Button
            variant={"contained"}
            onClick={() => {
              const newArgs = {
                examinerId: examinerOption?.value,
                examinerLabel: examinerOption?.label,
                artUnitValue: artUnitValue?.value,
                year: year?.value,
                decisionType: decisionType?.value,
              };

              clearTreeState();

              setArgs(newArgs);
            }}
            size={"sm"}
            className={styles.filterButton}
          >
            Filter
          </Button>
        </>
      }
      pills={
        isPillsAvailable && (
          <>
            {!!args?.examinerLabel && (
              <>
                <Pill
                  label={"Examiner"}
                  value={args.examinerLabel ?? ""}
                  onClear={() => {
                    setExaminerOption(null);
                    const newArgs = { ...args };
                    delete newArgs.examinerId;
                    delete newArgs.examinerLabel;

                    clearTreeState();

                    setArgs(newArgs);
                  }}
                />
                <Gap size={8} />
              </>
            )}

            {!!args?.artUnitValue && (
              <>
                <Pill
                  label={"Art Unit"}
                  value={args.artUnitValue ?? ""}
                  onClear={() => {
                    setArtUnitValue(null);
                    const newArgs = { ...args };
                    delete newArgs.artUnitValue;

                    clearTreeState();

                    setArgs(newArgs);
                  }}
                />
                <Gap size={8} />
              </>
            )}

            {!!args?.decisionType && (
              <Pill
                className={styles.pill}
                label={"Decision"}
                value={args?.decisionType ?? ""}
                onClear={() => {
                  setDecisionType(null);
                  const newArgs = { ...args };
                  delete newArgs.decisionType;

                  clearTreeState();

                  setArgs(newArgs);
                }}
              />
            )}
          </>
        )
      }
      summary={
        <>
          <Button
            variant={"brand"}
            size={"xs"}
            isDisabled={
              items?.filter((x) => x.isChecked === true)?.length === 0
            }
            onClick={navigateToDecisionsPage}
            className={styles.viewDecisionsButton}
          >
            View Decisions
          </Button>

          <Gap size={40} />
          <Text>{`${items?.length ?? 0} issues`}</Text>

          <Gap strip />
          <Text>{`${
            items?.filter((x) => x.isChecked === true).length
          } selected`}</Text>
        </>
      }
    >
      <TreeHeader
        className={cn(
          styles.treeHeader,
          isSticky && styles.treeHeader$scroll,
          isPillsAvailable && styles.treeHeader$pills
        )}
      />

      <Tree
        items={items}
        onSelectionChange={onSelectionChange}
        openIds={openIds}
        onOpenChange={onOpenChange}
        highlight={searchHierarchy}
        placeholder={
          <div
            style={{
              padding: 20,
              borderLeft: "1px solid #acbac9",
              borderBottom: "1px solid #acbac9",
            }}
          >
            <NoResults />
          </div>
        }
      />

      <Gap vertical size={12} />

      <Text variant={"italic"} color={"light"} className={styles.infoText}>
        Reversal statistics provided only where we are confident we have all the
        data.
      </Text>
    </Layout>
  );
};

export default PTABDecisionsPage;

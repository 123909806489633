import React from "react";

import styles from "./module.sass";

export type Props = {
  children: string;
};

export default function InputPlaceholder({ children }: Props) {
  return <span className={styles.inputPlaceholder}>{children}</span>;
}

import React, { ReactNode } from "react";

import styles from "./module.sass";

export type Props = {
  children: ReactNode;
};

export default function InputLabel({ children }: Props) {
  return <span className={styles.inputLabel}>{children}</span>;
}

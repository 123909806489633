import React, { forwardRef, HTMLAttributes, ReactNode } from "react";
import cn from "classnames";

import styles from "./module.sass";

type Color =
  | "grey"
  | "cyan"
  | "red"
  | "green"
  | "olive"
  | "yellow"
  | "strongYellow"
  | "orange"
  | "blue"
  | "brown"
  | "teal"
  | "purple"
  | "transparent";

export type Props = {
  color: Color;
  children: ReactNode;
  isUnderlined?: boolean;
} & HTMLAttributes<HTMLSpanElement>;

const Highlight = forwardRef<HTMLSpanElement, Props>(
  ({ color, children, isUnderlined = false, ...rest }, ref) => (
    <span
      ref={ref}
      className={cn(
        styles.highlight,
        styles[`highlight$${color}`],
        isUnderlined && styles.highlight$underlined,
        rest.className
      )}
      {...rest}
    >
      {children}
    </span>
  )
);

export default Highlight;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2bl4WB633E4CZHAVUgWPBx{margin:0;padding:0;list-style:none}._2bl4WB633E4CZHAVUgWPBx li{margin:0}\n", "",{"version":3,"sources":["webpack://./src/style/resets.module.sass","webpack://./src/style/_mixins.sass"],"names":[],"mappings":"AAEA,yBCcE,QAAS,CACT,SAAU,CACV,eAAgB,CDhBlB,4BCmBI,QAAS","sourcesContent":["@import 'style/mixins';\n\n.listReset {\n\t@include list-reset; }\n","@import \"_variables.sass\";\n@import \"_colors.sass\";\n\n@mixin font-base {\n  font-family: Lato, 'sans-serif';\n  font-weight: normal;\n  color: $grey900;\n  font-size: 15px;\n  line-height: normal; }\n\n@mixin pseudo($display: block, $pos: absolute, $content: '') {\n  content: $content;\n  display: $display;\n  position: $pos; }\n\n@mixin list-reset {\n  margin: 0;\n  padding: 0;\n  list-style: none;\n\n  li {\n    margin: 0; } }\n\n@mixin button-reset {\n  @include font-base;\n\n  appearance: none;\n  padding: 0;\n\n  background-color: transparent;\n\n  border: none;\n  outline: none;\n\n  text-decoration: none;\n\n  cursor: pointer;\n\n  &:disabled {\n    cursor: not-allowed; } }\n\n@mixin anchor-reset {\n  color: $grey900;\n  text-decoration: none;\n\n  &:hover, &:active, &:focus {\n    color: $grey900;\n    text-decoration: none; } }\n\n@mixin section-reset {\n  padding: 0;\n  margin: 0; }\n\n@mixin small-reset {\n  font-size: $text-base; }\n\n@mixin label-reset {\n  font-weight: normal; }\n\n@mixin heading-reset {\n  font-weight: normal;\n  font-size: inherit;\n  margin: 0;\n  color: inherit;\n  text-align: left; }\n\n@mixin input-reset {\n  border: none;\n  outline: none; }\n\n@mixin ellipsis() {\n  display: block;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis; }\n\n@mixin textarea-reset {\n  border: none;\n  outline: none; }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listReset": "_2bl4WB633E4CZHAVUgWPBx"
};
export default ___CSS_LOADER_EXPORT___;

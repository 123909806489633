import React, { Fragment, ReactNode } from "react";

import Text from "atoms/Text";
import HorizontalRule from "atoms/HorizontalRule";
import Link from "components/Link";

import styles from "./DecisionInfo.module.sass";

export type Props = {
  applicationNumber: string;
  applicationLink: string;
  decisionDate: string;
  filingDate: string | null;
  examiner: string;
  examinerLink: string;
  groupArtUnit: string;
  firstNamedInventor: string;
  inventionTitle: string;
  panelJudges: string[];
};

export default function DecisionInfo({
  applicationNumber,
  applicationLink,
  decisionDate,
  filingDate,
  examiner,
  examinerLink,
  groupArtUnit,
  firstNamedInventor,
  inventionTitle,
  panelJudges,
}: Props) {
  const rows: [string, ReactNode][] = [
    [
      "Application Number:",
      <Link href={applicationLink} openInNewTab dark>
        {applicationNumber}
      </Link>,
    ],
    ["Decision Date:", decisionDate],
    ["Filing Date:", filingDate],
    [
      "Examiner Name:",
      <Link href={examinerLink} openInNewTab dark>
        {examiner}
      </Link>,
    ],
    ["Group Art Unit:", groupArtUnit],
    ["First Named Inventor:", firstNamedInventor],
    ["Title of Invention:", inventionTitle],
    ["Panel Judges:", panelJudges.join(", ")],
  ];

  return (
    <section className={styles.decisionInfo}>
      <Text as="h2" size={20} color="grey900" style={{ marginBottom: 16 }}>
        About This Document
      </Text>
      <HorizontalRule />
      {rows.map(([heading, value], i) => {
        if (!value) {
          return null;
        }

        return (
          <Fragment key={heading}>
            <div style={{ display: "flex", padding: "8px 0" }}>
              <Text
                size={15}
                color="grey600"
                style={{ width: 148, fontStyle: "italic" }}
              >
                {heading}
              </Text>
              <Text
                size={15}
                color="grey900"
                style={{ display: "flex", flex: 1 }}
              >
                {value}
              </Text>
            </div>
            {i !== rows.length - 1 && <HorizontalRule />}
          </Fragment>
        );
      })}
    </section>
  );
}

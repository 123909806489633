import React, { useMemo, useState } from "react";
import { useQueryParam, NumberParam } from "use-query-params";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";

import { Monitor } from "domain/monitors";
import { format as formatDate } from "domain/dates";
import { Variant } from ".";
import { SearchInput } from "components/Input";
import Table, {
  EmptyMessage,
  ExtendedColumn,
  actionsHeaderAdditionalProps,
} from "components/Table";
import Button from "components/Button";
import { Bin, Pen, Plus } from "components/Icons";
import Separator from "components/Separator";
import Text from "components/Text";
import TruncatedText from "atoms/TruncatedText";
import Popover from "atoms/Popover";
import Readable from "atoms/Readable";

import { Container, Heading, Controls, LongDescription } from ".";
import { DeletionModal } from "components/Modal";

import styles from "./module.sass";

export type Props = {
  monitors: Monitor[];
  isLoading: boolean;
  variant: Variant;
  onBriefcaseClick: (monitor: Monitor) => void;
  onDelete: (monitor: Monitor) => void;
};

export default function MonitorsPanel({
  monitors,
  isLoading,
  variant,
  onBriefcaseClick,
  onDelete,
}: Props) {
  const [monitorToDelete, setMonitorToDelete] = useState<Monitor | null>(null);

  const handleCreate = () => {
    const urlParams = new URLSearchParams({ create: "1" });
    window.location.href = `/prosecution-pattern-monitoring.php?${urlParams.toString()}`;
  };
  const showResults = (monitor: Monitor) => {
    const urlParams = new URLSearchParams({ ppm: monitor.id.toString() });
    window.open(`/ppm-results.php?${urlParams.toString()}`);
  };
  const edit = (monitor: Monitor) => {
    const urlParams = new URLSearchParams({ ppm_id: monitor.id.toString() });
    window.location.href = `/prosecution-pattern-monitoring.php?${urlParams.toString()}`;
  };

  const columns: ExtendedColumn<Monitor>[] = useMemo(
    () => [
      {
        Header: "Monitor Name",
        accessor: "name",
        Cell: ({ value }) => (
          <TruncatedText className={styles.textTruncateMonitors}>
            {value}
          </TruncatedText>
        ),
      },
      {
        Header: "Results",
        accessor: "alertCount",
        Cell: ({ row, value }) => (
          <Text color="link" onClick={() => showResults(row.original)}>
            {value.toLocaleString("en-us")} results
          </Text>
        ),
      },
      {
        Header: "Briefcase",
        accessor: "briefcaseName",
        Cell: ({ row, value }) => (
          <Text color="link" onClick={() => onBriefcaseClick(row.original)}>
            <TruncatedText className={styles.textTruncateMonitors}>
              {value}
            </TruncatedText>
          </Text>
        ),
      },
      {
        Header: "Description",
        accessor: "description",
        Cell: ({ value }) => <LongDescription>{value || "-"}</LongDescription>,
      },
      {
        Header: "Date Created",
        accessor: "dateCreated",
        Cell: ({ value }) => formatDate(value, "Y-m-d"),
        sortType: "datetime",
      },
      {
        Header: "Last Modified",
        accessor: "dateModified",
        Cell: ({ value }) => (value ? formatDate(value, "Y-m-d") : "-"),
        sortType: "datetime",
      },
      {
        id: "actions",
        accessor: "name",
        disableSortBy: true,
        additionalHeaderProps: actionsHeaderAdditionalProps,
        Header: "Actions",
        Cell: ({ row }) => (
          <div
            style={{
              display: "flex",
              margin: "-3px 0",
              justifyContent: "center",
            }}
          >
            {variant === "preview" ? (
              <>
                <Button
                  size="xs"
                  tooltip="Edit monitor"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className={styles.button$fake}
                >
                  <Button
                    className={styles.button}
                    size="xs"
                    tooltip="Edit monitor"
                    isDisabled={variant === "preview"}
                  >
                    <Pen />
                  </Button>
                </Button>
                <Separator />
                <Button
                  size="xs"
                  tooltip="Delete monitor"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className={styles.button$fake}
                >
                  <Button
                    className={styles.button}
                    size="xs"
                    tooltip="Delete monitor"
                    isDisabled={variant === "preview"}
                  >
                    <Bin />
                  </Button>
                </Button>
              </>
            ) : (
              <>
                <Button
                  className={styles.button}
                  size="xs"
                  tooltip="Edit monitor"
                  onClick={() => edit(row.original)}
                >
                  <Pen />
                </Button>
                <Separator />
                <Button
                  className={styles.button}
                  size="xs"
                  tooltip="Delete monitor"
                  onClick={() => setMonitorToDelete(row.original)}
                >
                  <Bin />
                </Button>
              </>
            )}
          </div>
        ),
      },
    ],
    [onBriefcaseClick]
  );

  const [pageIndexQuery, setPageIndexQuery] = useQueryParam(
    "monitorsPage",
    NumberParam
  );

  const table = useTable(
    {
      columns,
      data: monitors,
      disableSortRemove: true,
      autoResetSortBy: false,
      initialState: {
        pageIndex: pageIndexQuery ?? 0,
        pageSize: 10,
        sortBy: useMemo(
          () => [
            {
              id: "dateCreated",
              desc: true,
            },
          ],
          []
        ),
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const {
    page,
    state: { globalFilter },
    setGlobalFilter,
  } = table;

  return (
    <Container>
      <Heading
        text="Monitors"
        subtext={
          isLoading || monitors.length > 0 ? (
            <>
              Displaying{" "}
              <Text variant="bold">
                {Math.min(monitors.length, page.length)}
              </Text>{" "}
              of{" "}
              <Text variant="bold">
                <Readable>{monitors.length}</Readable>
              </Text>{" "}
              total Monitors
            </>
          ) : (
            <>No items</>
          )
        }
      />
      <Controls>
        {variant === "preview" ? (
          <PreviewPPMButton />
        ) : (
          <Button
            variant="contained"
            leftIcon={<Plus />}
            size="sm"
            onClick={handleCreate}
          >
            Create a monitor
          </Button>
        )}

        {(isLoading || monitors.length > 0) && (
          <div style={{ width: 380 }}>
            <SearchInput
              value={globalFilter || ""}
              label="Search for a Monitor"
              onChange={(value) => setGlobalFilter(value)}
            />
          </div>
        )}
      </Controls>
      <Table
        table={table}
        emptyMessage={
          <EmptyMessage colSpan={7}>
            {globalFilter ? "No matching records found" : "No items"}
          </EmptyMessage>
        }
        onPageChange={(newPageIndex) => {
          setPageIndexQuery(newPageIndex);
        }}
      />
      <DeletionModal
        message="Do you really want to DELETE this Prosecution Pattern Monitor and all recorded events under it?"
        isOpen={!!monitorToDelete}
        onDismiss={() => setMonitorToDelete(null)}
        onDelete={() => {
          onDelete(monitorToDelete as Monitor);
          setMonitorToDelete(null);
        }}
      />
    </Container>
  );
}

const PreviewPPMButton = () => {
  const [isPopupShown, setIsPopupShown] = useState(false);

  return (
    <Popover
      offset={[130, 15]}
      content="Creating and editing a Monitor is only available with a paid subscription."
      isDisabled={false}
      isVisible={isPopupShown}
    >
      <div>
        <Button
          variant="contained"
          leftIcon={<Plus />}
          size="sm"
          isDisabled={true}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
            onMouseOver={() => setIsPopupShown(true)}
            onMouseOut={() => setIsPopupShown(false)}
          ></div>
          Create a monitor
        </Button>
      </div>
    </Popover>
  );
};

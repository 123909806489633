import React, { useEffect, useMemo, useState } from "react";
import { Column, usePagination, useTable } from "react-table";
import ReactSelect from "react-select";
import { IssueSetType, ExtTagType } from "domain/ptabAdmin/models";
import Button from "components/Button";
import Checkbox from "components/Checkbox";
import Table from "components/Table";
import { Pen, Bin } from "components/Icons";
import TextInput, {
  Label,
} from "components/PTABDecisions/components/TextInput";
import Text from "components/Text";
import SelectWithSuggestions from "../SelectWithSuggestions";
import {
  DictionaryItemType,
  Gap,
  getReactSelectDefaultProps,
} from "components/PTABDecisions/components/utils";
import {
  createIssueSet,
  editIssueSet,
  fetchTags,
  fetchTagSuggestions,
  fetchExistingIssueOptions,
  deleteTag,
  createTag,
  editTag,
  makeDecisionReviewed,
} from "domain/ptabAdmin";
import Pulse from "components/PTABDecisions/components/Pulse";
import { useOutcomeOptions } from "components/AdminPanel/ApplicationLookupPage";

import styles from "./module.sass";
import cn from "classnames";

const EditTagForm = ({
  existingIssue,
  selectedTag,
  tags,
  onTagsChange,
  onReset,
}: {
  existingIssue?: string;
  selectedTag?: ExtTagType;
  tags: Array<ExtTagType>;
  onTagsChange: (tag: Array<ExtTagType>) => void;
  onReset: () => void;
}) => {
  const [tag, setTag] = useState<DictionaryItemType | null>();

  useEffect(() => {
    setTag(
      selectedTag && { label: selectedTag.name, value: `${selectedTag.tag_id}` }
    );
  }, [selectedTag]);

  const [legalSupportText, setLegalSupportText] = useState<string>("");
  useEffect(() => {
    setLegalSupportText(selectedTag?.legalSupport ?? "");
  }, [selectedTag]);

  const [isDispositive, setIsDispositive] = useState(false);
  useEffect(() => {
    setIsDispositive(!selectedTag ? false : selectedTag?.dispositive);
  }, [selectedTag]);

  return (
    <div className={cn(styles.new, !!selectedTag && styles.new$focused)}>
      <Gap vertical size={12} />

      <Text variant={"bold"}>
        {!!selectedTag ? "Edit Tag:" : "Add New Tag:"}
      </Text>

      <Gap vertical size={12} />

      <Label label={"Tag"} style={{ marginBottom: 4 }}>
        <SelectWithSuggestions
          placeholder={"Enter tag name"}
          isHighlightingEnabled
          maxMenuHeight={260}
          value={tag}
          setValue={setTag}
          inputLengthThreshold={3}
          fetch={(value) => {
            return fetchTagSuggestions({
              tagName: value,
              existingIssue: existingIssue,
            }).then((tags) =>
              (tags ?? [])?.map(({ id: tagId, name }) => ({
                label: `${name}`,
                value: `${tagId}`,
              }))
            );
          }}
        />
      </Label>

      <Gap vertical size={12} />

      <Checkbox
        label={"Dispositive?"}
        labelPosition={"left"}
        isChecked={isDispositive}
        onChange={setIsDispositive}
      />

      <Gap vertical size={12} />

      <Label label={"Legal Support"}>
        <textarea
          value={legalSupportText}
          onChange={(e) => {
            setLegalSupportText(e.target.value);
          }}
          className={styles.legalSupportText}
        />
      </Label>

      <Gap vertical size={12} />

      <div className={styles.controls}>
        {!selectedTag && (
          <Button
            size={"xxs"}
            variant={"contained"}
            isDisabled={!tag}
            onClick={() => {
              onTagsChange([
                ...tags,
                {
                  tag_id: tag?.value,
                  name: tag?.label ?? "",
                  dispositive: isDispositive,
                  legalSupport: legalSupportText,
                },
              ]);

              setTag(null);
              setIsDispositive(false);
              setLegalSupportText("");

              onReset();
            }}
          >
            Add
          </Button>
        )}

        {!!selectedTag && (
          <>
            <Button
              size={"xxs"}
              variant={"contained"}
              className={styles.greenButton}
              onClick={() => {
                onTagsChange(
                  tags.map((iTag) =>
                    iTag === selectedTag
                      ? {
                          ...iTag,
                          tag_id: tag?.value,
                          name: tag?.label ?? "",
                          dispositive: isDispositive,
                          legalSupport: legalSupportText,
                        }
                      : iTag
                  )
                );

                onReset();
              }}
            >
              Save
            </Button>

            <Gap size={12} />

            <Button
              size={"xxs"}
              variant={"contained"}
              className={styles.grayButton}
              onClick={() => {
                onReset();
              }}
            >
              Cancel
            </Button>
          </>
        )}

        {!!selectedTag && (
          <div className={styles.wide}>
            <Button
              size={"xxs"}
              variant={"contained"}
              className={styles.redButton}
              onClick={() => {
                if (confirm("Are you sure you want to delete this item?")) {
                  onTagsChange(
                    tags.filter(
                      (tag) =>
                        JSON.stringify(tag) !== JSON.stringify(selectedTag)
                    )
                  );
                  onReset();
                }
              }}
            >
              Delete Tag
            </Button>
          </div>
        )}
      </div>

      <Gap vertical size={12} />
    </div>
  );
};

const EditIssueSetForm = ({
  issueSet: _issueSet,
  userId,
  decisionId,
  onClose,
}: {
  issueSet?: IssueSetType;
  userId?: number;
  decisionId: number;
  onClose: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTag, setSelectedTag] = useState<ExtTagType | undefined>(
    undefined
  );
  const [originalTags, setOriginalTags] = useState<Array<ExtTagType>>([]);

  const [isExistingIssueOptionsLoading, setExistingIssueOptionsLoading] =
    useState(false);

  const [originalIssueSet, setOriginalIssueSet] = useState<IssueSetType>();
  const [issueSet, setIssueSet] = useState<IssueSetType>({
    claims_of_issue: "",
    issue: "101",
    disposition: "a",
    non_substantive: false,
    tags: [],
  });
  const [tags, setTags] = useState<Array<ExtTagType>>([]);

  const {
    outcomeOptions,
    findOutcomeOption,
    isLoading: isOutcomeOptionsLoading,
  } = useOutcomeOptions();

  const [existingIssueOptions, setExistingIssueOptions] = useState<
    Array<DictionaryItemType>
  >([]);

  useEffect(() => {
    setExistingIssueOptionsLoading(true);

    fetchExistingIssueOptions()
      .then((res) => {
        setExistingIssueOptions(
          res?.sort()?.map((issue) => ({ value: issue, label: issue })) ?? []
        );
      })
      .finally(() => {
        setExistingIssueOptionsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (_issueSet) {
      setOriginalIssueSet({ ..._issueSet });
      setIssueSet({ ..._issueSet });
    }
  }, [_issueSet]);

  useEffect(() => {
    if (_issueSet?.issue_set_id) {
      fetchTags(_issueSet?.issue_set_id).then((res: Array<ExtTagType>) => {
        setOriginalTags([...res]);
        setTags([...res]);
      });
    }
  }, [_issueSet]);

  const columns: Column<ExtTagType>[] = useMemo(
    () => [
      {
        Header: "Tag",
        accessor: "name",
      },
      {
        Header: "Dispositive?",
        accessor: "dispositive",
        Cell: ({ value, row }: { value: boolean; row: any }) => (
          <Checkbox
            isDisabled={!!selectedTag}
            isChecked={value}
            onChange={(newValue) => {
              setTags(
                tags.map((tag) => {
                  return tag === row.original
                    ? { ...tag, dispositive: newValue }
                    : tag;
                })
              );
            }}
          />
        ),
      },
      {
        Header: "Legal Support",
        accessor: "legalSupport",
      },
      {
        Header: "Actions",
        maxWidth: 84,
        Cell: ({ value, row }: { value: any; row: any }) => {
          return (
            <div style={{ display: "flex" }}>
              <Button
                isDisabled={!!selectedTag}
                variant={"naked"}
                size={"xs"}
                className={styles.button}
                onClick={() => {
                  setSelectedTag(row.original);
                }}
                leftIcon={<Pen />}
              />
              <Button
                isDisabled={!!selectedTag}
                variant={"naked"}
                size={"xs"}
                className={styles.button}
                onClick={() => {
                  const newTags = tags.filter(
                    (tag) =>
                      JSON.stringify(tag) !== JSON.stringify(row.original)
                  );
                  if (confirm("Are you sure you want to delete this item?")) {
                    setTags(newTags);
                  }
                }}
                leftIcon={<Bin />}
              />
            </div>
          );
        },
      },
    ],
    [tags, selectedTag]
  );

  const table = useTable(
    {
      columns: columns,
      data: tags,
      initialState: { pageSize: 25 },
    },
    usePagination
  );

  return (
    <div className={styles.root} key={"editIssueSetForm"}>
      <div className={styles.header}>
        <Text variant={"bold"} style={{ fontSize: 16 }}>
          {_issueSet ? "Edit Issue Set" : "Create Issue Set"}
        </Text>
      </div>

      <span className={styles.row}>
        <Text className={styles.col1}>
          Claims of issue
          <br />
          <i style={{ display: "block", fontSize: 11 }}>
            Ex: 1-10, 23-25, 27, 30
          </i>
        </Text>
        <div>
          <TextInput
            className={styles.textInput}
            value={issueSet?.claims_of_issue ?? ""}
            onChange={
              issueSet
                ? (value) => {
                    setIssueSet({ ...issueSet, claims_of_issue: value });
                  }
                : () => {}
            }
          />
        </div>
      </span>

      <span className={styles.row}>
        <Text className={styles.col1}>Non substantive</Text>
        <Checkbox
          isChecked={issueSet?.non_substantive ?? false}
          onChange={
            issueSet
              ? (value) => {
                  setIssueSet({ ...issueSet, non_substantive: value });
                }
              : undefined
          }
          className={styles.col2}
        />
      </span>

      <span className={styles.row}>
        <Text className={styles.col1}>Existing Issue</Text>

        <div
          className={styles.smallSelectContainer}
          style={{ height: "fit-content", width: "100%" }}
        >
          <ReactSelect
            className={styles.col2}
            {...getReactSelectDefaultProps({
              hasValue: false,
              hasOptions: existingIssueOptions?.length > 0,
              height: 24,
            })}
            value={
              issueSet &&
              existingIssueOptions &&
              existingIssueOptions.find(({ value }) => value === issueSet.issue)
            }
            placeholder={""}
            onChange={
              issueSet
                ? (option) => {
                    setIssueSet({ ...issueSet, issue: option?.value ?? "" });
                  }
                : undefined
            }
            options={existingIssueOptions}
            isSearchable={true}
            isClearable={true}
            filterOption={() => true}
            isLoading={isExistingIssueOptionsLoading}
            isDisabled={isExistingIssueOptionsLoading}
          />
        </div>
      </span>

      <span className={styles.row}>
        <Text className={styles.col1}>Outcome</Text>

        <div
          className={cn(styles.col2, styles.smallSelectContainer)}
          style={{ maxWidth: 300 }}
        >
          <ReactSelect
            {...getReactSelectDefaultProps({
              hasValue: false,
              hasOptions: outcomeOptions?.length > 0,
              height: 24,
            })}
            placeholder={""}
            value={findOutcomeOption(issueSet?.disposition)}
            onChange={
              issueSet
                ? (option) => {
                    setIssueSet({
                      ...issueSet,
                      disposition: option?.value ?? "",
                    });
                  }
                : () => {}
            }
            options={outcomeOptions ?? []}
            isSearchable={true}
            isClearable={true}
            filterOption={() => true}
            isLoading={isOutcomeOptionsLoading}
            isDisabled={isOutcomeOptionsLoading}
          />
        </div>
      </span>

      <Gap vertical size={40} />

      <Table table={table} isPaginationHidden />

      <Gap vertical size={16} />

      {issueSet && (
        <EditTagForm
          existingIssue={issueSet.issue}
          selectedTag={selectedTag}
          tags={tags}
          onTagsChange={setTags}
          onReset={() => {
            setSelectedTag(undefined);
          }}
        />
      )}

      <Gap vertical size={15} />

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          size={"xxs"}
          isDisabled={!!selectedTag}
          variant={"contained"}
          className={styles.greenButton}
          onClick={async () => {
            setIsLoading(true);

            let isThereAnyChanges = false;

            if (_issueSet) {
              if (
                JSON.stringify(issueSet) !== JSON.stringify(originalIssueSet)
              ) {
                isThereAnyChanges = true;
                await editIssueSet({
                  decision_id: decisionId,
                  data: issueSet,
                });
              }

              const promises: Array<Promise<any>> = [];

              if (JSON.stringify(tags) !== JSON.stringify(originalTags)) {
                isThereAnyChanges = true;

                originalTags.forEach((originalTag) => {
                  if (!tags?.some((tag) => tag.id === originalTag.id)) {
                    if (_issueSet?.issue_set_id) {
                      promises.push(
                        deleteTag({
                          tag: originalTag,
                          issueSetId: _issueSet?.issue_set_id,
                        })
                      );
                    }
                  }
                });

                tags.forEach((tag) => {
                  const originalTag = originalTags.find(
                    (originalTag) => tag.id === originalTag.id
                  );

                  if (!originalTag) {
                    if (issueSet.issue_set_id && tag?.tag_id) {
                      promises.push(
                        createTag({
                          issueSetId: issueSet.issue_set_id,
                          tagId: tag.tag_id,
                          legalSupport: tag.legalSupport ?? "",
                          dispositive: tag.dispositive,
                        })
                      );
                    }
                  } else {
                    if (
                      JSON.stringify(originalTag) !== JSON.stringify(tag) &&
                      issueSet.issue_set_id &&
                      tag.tag_id
                    ) {
                      promises.push(
                        editTag({
                          tag,
                          issueSetId: issueSet.issue_set_id,
                        })
                      );
                    }
                  }
                });
              }

              if (promises?.length > 0) {
                await Promise.all(promises);
              }
            } else {
              isThereAnyChanges = true;

              await createIssueSet({
                decision_id: decisionId,
                data: issueSet,
                tags: tags,
              });
            }

            if (isThereAnyChanges) {
              await makeDecisionReviewed({
                userId: userId,
                decisionId: decisionId,
              });
            }

            setIsLoading(false);
            onClose();
          }}
        >
          Save and Close
        </Button>

        <Button
          variant={"contained"}
          isDisabled={!!selectedTag}
          size={"xxs"}
          onClick={() => {
            if (
              (!!originalIssueSet &&
                JSON.stringify(issueSet) !==
                  JSON.stringify(originalIssueSet)) ||
              (!!originalTags &&
                JSON.stringify(tags) !== JSON.stringify(originalTags))
            ) {
              if (
                confirm(
                  "There are unsaved changes. Are you sure you want to quit?"
                )
              ) {
                onClose();
              }
            } else {
              onClose();
            }
          }}
        >
          Cancel
        </Button>
      </div>

      {isLoading && (
        <div className={styles.loadingOverlay}>
          <Pulse />
        </div>
      )}
    </div>
  );
};

export default EditIssueSetForm;

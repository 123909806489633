import React, { SVGProps } from "react";

export interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
  outlineColor?: string;
  size?: "medium" | "large";
}

export default function Target({
  color = "lightgray",
  outlineColor = "white",
  size = "medium",
  style,
  ...rest
}: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      xmlSpace="preserve"
      width={size === "medium" ? 21 : 30}
      height={size === "medium" ? 21 : 30}
      fill={color}
      {...rest}
    >
      <circle fill={outlineColor} cx={15} cy={15} r={15} />
      <path d="M15 28C7.83 28 2 22.17 2 15S7.83 2 15 2s13 5.83 13 13-5.83 13-13 13zm0-22c-4.96 0-9 4.04-9 9s4.04 9 9 9 9-4.04 9-9-4.04-9-9-9z" />
      <circle fill="#fff" cx={15} cy={15} r={9} />
      <circle cx={15} cy={15} r={6} />
    </svg>
  );
}

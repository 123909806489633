// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1z7ab5jLOoAAjmXCPxXBnE{position:relative}._1PZxpgM3zN799ALq-LdU1T{position:absolute;width:100%;height:100%;border-radius:50%;background-color:rgba(236,0,0,0.5)}._31RwwDohgiqER14DEKHSAD{animation:_3kINNhf3Vuy-O_nM3U7vaj 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite both}@keyframes _3kINNhf3Vuy-O_nM3U7vaj{0%{transform:scale(0)}50%{transform:scale(1)}100%{transform:scale(0)}}._2NWPViugxI2tOt7u2QSmfk{animation:_34oQvVNO8pHDQ2xSAr0yAA 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite both}@keyframes _34oQvVNO8pHDQ2xSAr0yAA{0%{transform:scale(1)}50%{transform:scale(0)}100%{transform:scale(1)}}\n", "",{"version":3,"sources":["webpack://./src/atoms/Pulse/module.sass"],"names":[],"mappings":"AAAA,yBACI,iBAAkB,CAAG,yBAGrB,iBAAkB,CAClB,UAAW,CACX,WAAY,CAEZ,iBAAkB,CAElB,kCAAsC,CAEtC,yBACI,uFAAoF,CAEpF,mCACI,GACI,kBAAmB,CACvB,IACI,kBAAmB,CACvB,KACI,kBAAmB,CAAA,CAE/B,yBACI,uFAAqF,CAErF,mCACI,GACI,kBAAmB,CACvB,IACI,kBAAmB,CACvB,KACI,kBAAmB,CAAA","sourcesContent":[".pulse {\n    position: relative; }\n\n.circle {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n\n    border-radius: 50%;\n\n    background-color: rgba(236, 0, 0, 0.5);\n\n    &$primary {\n        animation: scale-in-center 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite both;\n\n        @keyframes scale-in-center {\n            0% {\n                transform: scale(0); }\n            50% {\n                transform: scale(1); }\n            100% {\n                transform: scale(0); } } }\n\n    &$secondary {\n        animation: scale-in-center2 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite both;\n\n        @keyframes scale-in-center2 {\n            0% {\n                transform: scale(1); }\n            50% {\n                transform: scale(0); }\n            100% {\n                transform: scale(1); } } } }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pulse": "_1z7ab5jLOoAAjmXCPxXBnE",
	"circle": "_1PZxpgM3zN799ALq-LdU1T",
	"circle$primary": "_31RwwDohgiqER14DEKHSAD",
	"scale-in-center": "_3kINNhf3Vuy-O_nM3U7vaj",
	"circle$secondary": "_2NWPViugxI2tOt7u2QSmfk",
	"scale-in-center2": "_34oQvVNO8pHDQ2xSAr0yAA"
};
export default ___CSS_LOADER_EXPORT___;

import React, { ReactNode } from "react";
import { DialogOverlay, DialogContent } from "@reach/dialog";

import Button from "components/Button";
import { Bin, X as IconX } from "components/Icons";

import styles from "./module.sass";

export type Props = {
  title?: string;
  children: ReactNode;
  ariaLabel: string;
  isOpen?: boolean;
  footer?: ReactNode;
  onDismiss?: () => void;
};

const Modal = ({
  title,
  children,
  ariaLabel,
  onDismiss,
  isOpen = true,
  footer = null,
}: Props) => (
  <DialogOverlay
    className={styles.overlay}
    isOpen={isOpen}
    onDismiss={onDismiss}
  >
    <DialogContent className={styles.content} aria-label={ariaLabel}>
      {!!title && (
        <header className={styles.header}>
          <div>{title}</div>
          {onDismiss && (
            <IconX
              className={styles.x}
              onClick={() => onDismiss()}
              onKeyDown={({ key }) => key === "Enter" && onDismiss()}
            />
          )}
        </header>
      )}
      <div className={styles.body}>{children}</div>
      {footer && <footer className={styles.footer}>{footer}</footer>}
    </DialogContent>
  </DialogOverlay>
);

const DeletionModal = ({
  message,
  isOpen,
  onDismiss,
  onDelete,
}: {
  message: ReactNode;
  isOpen: boolean;
  onDismiss: () => void;
  onDelete: () => void;
}) => (
  <Modal
    title="Please confirm"
    ariaLabel="Deletion dialog"
    footer={
      <>
        <Button variant="outlined" size="xs" onClick={onDismiss}>
          <span style={{ minWidth: 50 }}>Cancel</span>
        </Button>
        <span style={{ paddingLeft: 6 }} />
        <Button variant="contained" size="xs" onClick={onDelete}>
          <span style={{ minWidth: 50 }}>Delete</span>
        </Button>
      </>
    }
    isOpen={isOpen}
    onDismiss={onDismiss}
  >
    <div className={styles.modalMessage}>{message}</div>
  </Modal>
);

export { Modal as default, DeletionModal };

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RWjILwHLAOoW44w0f0j_ *{box-sizing:content-box;letter-spacing:normal}\n", "",{"version":3,"sources":["webpack://./src/Portals.module.sass"],"names":[],"mappings":"AAAA,wBAGE,sBAAuB,CAEvB,qBAAsB","sourcesContent":[".portal {\n\t// resets parent (legacy) app styles\n\t* {\n\t\tbox-sizing: content-box;\n\n\t\tletter-spacing: normal; } }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"portal": "RWjILwHLAOoW44w0f0j_"
};
export default ___CSS_LOADER_EXPORT___;

import React, { forwardRef, InputHTMLAttributes, ReactNode } from "react";
import cn from "classnames";

import InputLabel from "atoms/InputLabel";
import InputError from "atoms/InputError";

import styles from "./module.sass";

export type Props = InputHTMLAttributes<HTMLInputElement> & {
  label?: ReactNode;
  error?: string;
  /** @todo fix by reverting the width limiting */
  hasMaxWidth?: boolean;
  render?: (input: ReactNode) => ReactNode;
};

const SimpleInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      label = "",
      error = "",
      render = (input) => input,
      hasMaxWidth = true,
      ...rest
    },
    ref
  ) => {
    const input = <input ref={ref} className={styles.input} {...rest} />;

    return (
      <label
        className={cn(
          styles.labelGroup,
          error && styles.labelGroup$error,
          hasMaxWidth && styles.labelGroup$withMaxWidth
        )}
      >
        <InputLabel>{label}</InputLabel>
        <div className={styles.inputWrapper}>{render(input)}</div>
        {error.length > 0 && <InputError>{error}</InputError>}
      </label>
    );
  }
);

export default SimpleInput;

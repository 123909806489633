import React, { useCallback, useEffect, useMemo, useState } from "react";
import Table from "components/Table";
import { useTable, usePagination, Column, useSortBy } from "react-table";
import { useHistory, useLocation } from "react-router-dom";
import Layout from "../components/Layout";
import Button from "components/Button";
import Text from "components/Text";
import ReactSelect from "react-select";
import {
  DictionaryItemType,
  Gap,
  getParams,
  getReactSelectDefaultProps,
  MONTHS,
  range,
} from "components/PTABDecisions/components/utils";
import {
  MonthlyReviewType,
  MonthlyReviewStatusTypes,
  fetchMonthlyReviews,
} from "domain/ptabAdmin";
import styles from "./module.sass";
import Link from "components/Link";
import queryString from "query-string";
import { format } from "domain/dates";
import useDocumentTitle from "hooks/useDocumentTitle";
import { PAGE_TITLE, useScrollToTop } from "../utils";

const getStatusColor = (status: MonthlyReviewStatusTypes): string => {
  switch (status) {
    case MonthlyReviewStatusTypes.NoDecisions:
      return "rgb(155, 155, 155)";
    case MonthlyReviewStatusTypes.AutoConversionFailed:
      return "rgb(228, 57, 53)";
    case MonthlyReviewStatusTypes.Complete:
      return "rgb(95, 193, 23)";
    case MonthlyReviewStatusTypes.ReviewInProgress:
      return "rgb(33, 33, 33)";
    case MonthlyReviewStatusTypes.ReviewRequired:
      return "rgb(229, 163, 10)";
    default:
      return "rgb(33, 33, 33)";
  }
};

const YEARS = range(2007, new Date().getFullYear())
  .sort((a, b) => b - a)
  .map((x) => x.toString());

export type ParamsType = {
  year?: string;
  month?: string;
};

const MonthlyReviewPage = () => {
  useDocumentTitle(PAGE_TITLE);
  useScrollToTop();

  const history = useHistory();
  const location = useLocation();

  const { month: _month, year: _year }: ParamsType = queryString.parse(
    location.search
  );

  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState<Array<MonthlyReviewType>>([]);

  const [months] = useState<Array<DictionaryItemType>>(
    MONTHS.map((x) => ({ value: x, label: x }))
  );
  const [years] = useState<Array<DictionaryItemType>>(
    YEARS?.map((x) => ({ label: x, value: x }))
  );

  const [year, setYear] = useState<DictionaryItemType | null>(
    years.find(({ value }) => value === _year) ??
      years.find(
        ({ value }) => value === new Date().getFullYear().toString()
      ) ??
      null
  );
  const [month, setMonth] = useState<DictionaryItemType | null>(
    months.find(({ value }) => value === _month) ??
      months[new Date().getMonth()]
  );

  useEffect(() => {
    fetchMonthlyReviewsCallback();
  }, []);

  const fetchMonthlyReviewsCallback = useCallback(() => {
    setIsLoading(true);
    history.replace({
      search: getParams({ year: year?.value, month: month?.value }).toString(),
    });

    fetchMonthlyReviews({
      month: month?.value,
      year: year?.value,
    })
      .then((res: Array<MonthlyReviewType>) => {
        setData(res);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [month, year]);

  const columns: Column<MonthlyReviewType>[] = useMemo(
    () => [
      {
        Header: "Import Date",
        accessor: "import_date",
        maxWidth: 136,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({
          value,
          row,
        }: {
          value: MonthlyReviewStatusTypes;
          row: any;
        }) => (
          <p
            style={{
              color: getStatusColor(value),
              minWidth: 180,
              padding: 0,
              margin: 0,
            }}
          >
            {value}
          </p>
        ),
      },
      {
        Header: "Count",
        accessor: "count",
        maxWidth: 100,
      },
      {
        Header: "Failed",
        accessor: "failed",
        maxWidth: 100,
        Cell: ({ value, row }: { value: number; row: any }) => (
          <p
            style={{
              color: value > 0 ? "rgb(228, 57, 53)" : undefined,
              padding: 0,
              margin: 0,
            }}
          >
            {value}
          </p>
        ),
      },
      {
        Header: "Unknown Outcome",
        accessor: "unknown_outcome",
        maxWidth: 78,
      },
      {
        Header: "No Issues",
        accessor: "no_issues",
        maxWidth: 92,
      },
      {
        Header: "Actions",
        maxWidth: 84,
        Cell: ({ value, row }: { value: any; row: any }) => {
          const original: MonthlyReviewType = row.original;
          return (
            <Link
              isFluid
              className={styles.editLink}
              href={`/#daily-review?date=${original.import_date}`}
            >
              Edit
            </Link>
          );
        },
      },
    ],
    []
  );

  const table = useTable(
    {
      columns: columns,
      data: data,
      initialState: {
        pageSize: 100,
        sortBy: useMemo(
          () => [
            {
              id: "import_date",
              desc: false,
            },
          ],
          []
        ),
      },
    },
    useSortBy,
    usePagination
  );

  return (
    <Layout
      title={"Monthly Review by Import Date"}
      isMonthlyReviewActive
      isLoading={isLoading}
    >
      <div
        style={{
          maxWidth: 800,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 18,
          }}
        >
          <Text>Month:</Text>

          <Gap size={8} />
          <ReactSelect
            {...getReactSelectDefaultProps({
              hasValue: false,
              hasOptions: true,
              height: 32,
              width: 128,
            })}
            onChange={setMonth}
            placeholder={"Month"}
            value={month}
            options={months}
            isClearable={false}
          />

          <Gap size={20} />
          <Text>Year:</Text>

          <Gap size={8} />
          <ReactSelect
            {...getReactSelectDefaultProps({
              hasValue: false,
              hasOptions: true,
              width: 90,
              height: 32,
            })}
            value={year}
            options={years}
            onChange={setYear}
            isClearable={false}
          />

          <Gap size={20} />
          <Button
            className={styles.goButton}
            onClick={() => {
              fetchMonthlyReviewsCallback();
            }}
            size={"xs"}
            variant={"contained"}
          >
            Go
          </Button>
        </div>
        <Table table={table} isPaginationHidden />
        <Gap vertical size={60} />
      </div>
    </Layout>
  );
};

export default MonthlyReviewPage;

import React, { HTMLAttributes } from "react";

import colors from "style/colors.module.scss";
import styles from "./module.sass";
import cn from "classnames";

export type Props = HTMLAttributes<HTMLDivElement> & {
  variant?: "white" | "lightGrey";
};

export default function MainContainer({
  variant = "white",
  className,
  ...rest
}: Props) {
  return (
    <div
      className={cn(styles.mainContainer, className)}
      style={{
        backgroundColor:
          variant === "lightGrey" ? colors.grey100 : colors.white,
      }}
      {...rest}
    />
  );
}

const ContentContainer = ({ className, ...rest }: Props) => (
  <div className={cn(styles.contentContainer, className)} {...rest} />
);

export { ContentContainer };

import React, { HTMLAttributes, ReactNode } from "react";

import Text from "components/Text";

import styles from "./module.sass";

export type Props = {
  children: ReactNode;
} & HTMLAttributes<HTMLHeadingElement>;

const PageTitle = ({ children, ...rest }: Props) => (
  <header className={styles.row}>
    <h1 className={styles.pageTitle} {...rest}>
      <Text variant="h1">{children}</Text>
    </h1>
  </header>
);

export default PageTitle;

import React, { ReactNode } from "react";
import cn from "classnames";
import { NavLink, Route, Switch, useLocation } from "react-router-dom";

import useDocumentTitle from "hooks/useDocumentTitle";
import { SearchPermissions } from "domain/users";
import { Row, Container } from "components/Layout";
import SmallReg from "components/SmallReg";
import { Back as BackIcon } from "components/Icons";
import { getAnalytics } from "domain/analytics";

import ExaminerForm from "./ExaminerForm";
import QuickPairForm from "./QuickPairForm";
import FileWrapperForm from "./FileWrapperForm";
import LawFirmForm from "./LawFirmForm";
import AssigneeOrApplicantForm from "./AssigneeOrApplicantForm";
import ArtUnitForm from "./ArtUnitForm";
import BusinessDevelopment from "./BusinessDevelopment";
import TechCenterNavigatorForm from "./TechCenterNavigatorForm";
import { recordTabClick } from "./analytics";
import styles from "./module.sass";

export type Props = {
  searchPermissions: SearchPermissions;
  hasSmartReports: boolean;
  hasLicensingReports: boolean;
  lastLogin: Date | null;
};

export default function Home({
  lastLogin,
  searchPermissions,
  hasSmartReports,
  hasLicensingReports,
}: Props) {
  useDocumentTitle("Home - PatentAdvisor\u00ae");

  return (
    <>
      <Row>
        <LastLogin lastLogin={lastLogin} />
      </Row>
      <Row>
        <Container className={styles.home} isPadded>
          <h1 className={styles.welcomeTo}>
            Welcome to LexisNexis PatentAdvisor
            <SmallReg style={{ fontSize: 18 }} />
          </h1>
          <Search
            permissions={searchPermissions}
            hasSmartReports={hasSmartReports}
            hasLicensingReports={hasLicensingReports}
          />
        </Container>
      </Row>
    </>
  );
}

export const TabPanel = ({ children }: { children: ReactNode }) => (
  <div className={styles.tabPanel}>{children}</div>
);

export const SecondaryTabPanel = ({ children }: { children: ReactNode }) => (
  <div className={styles.secondaryTabPanel}>{children}</div>
);

export const TabPanelItem = ({ children }: { children: ReactNode }) => (
  <div className={styles.tabPanelItem}>{children}</div>
);

export const SectionHeading = ({ children }: { children: ReactNode }) => (
  <div className={styles.sectionHeading}>{children}</div>
);

const SubsectionHeading = ({ children }: { children: ReactNode }) => (
  <div className={styles.subsectionHeading}>{children}</div>
);

function Search({
  permissions,
  hasSmartReports,
  hasLicensingReports,
}: {
  permissions: SearchPermissions;
  hasSmartReports: boolean;
  hasLicensingReports: boolean;
}) {
  const { pathname } = useLocation();

  const allowedSearchRoutes = restrictedRoutes
    .filter((r) => permissions[r.permission])
    .map((r) => r.route);
  const activeIndex = allowedSearchRoutes.findIndex((r) => r === pathname);

  return (
    <div className={styles.search}>
      <div className={styles.searchWithTabs}>
        <SectionHeading>Search</SectionHeading>
        <div className={styles.tabs}>
          <div>
            <SubsectionHeading>Insights</SubsectionHeading>
            {permissions.quickPAIR && <QuickPAIRTab />}
            {permissions.examiner && <ExaminerTab />}
            {permissions.artUnit && <ArtUnitTab />}
            {permissions.assigneeApplicant && <AssigneeTab />}
            {permissions.lawFirm && <LawFirmTab />}
            {permissions.pathways && <TechCenterNavigatorTab />}
            <SubsectionHeading>Research</SubsectionHeading>
            {permissions.fileWrapper && <FileWrappersTab />}
            {permissions.ptab && <PTABDecisionsTab />}
          </div>
        </div>
      </div>
      <TabIndicator activeIndex={activeIndex} />
      <Switch>
        <Route path="/search-examiner">
          <ExaminerForm />
          <Back />
        </Route>
        <Route path="/search-quickpair">
          <QuickPairForm />
          <Back />
        </Route>
        <Route path="/search-art-unit">
          <ArtUnitForm />
          <Back />
        </Route>
        <Route path="/search-assignee">
          <AssigneeOrApplicantForm />
          <Back />
        </Route>
        <Route path="/search-law-firm">
          <LawFirmForm canCreateReportCard={hasSmartReports} />
          <Back />
        </Route>
        <Route path="/search-file-wrapper">
          <FileWrapperForm />
          <Back />
        </Route>
        <Route path="/tech-center-navigator">
          <TechCenterNavigatorForm />
          <Back />
        </Route>
        <Route path="/">
          <BusinessDevelopment
            hasSmartReports={hasSmartReports}
            hasLicensingReports={hasLicensingReports}
          />
        </Route>
      </Switch>
    </div>
  );
}

const LastLogin = ({ lastLogin }: { lastLogin: Date | null }) => (
  <div className={styles.lastLogin}>
    {lastLogin ? (
      <>
        Your last login was on:&nbsp;
        {new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        }).format(lastLogin)}
        &nbsp;at&nbsp;
        {new Intl.DateTimeFormat("en-US", {
          hour: "numeric",
          minute: "numeric",
        })
          .format(lastLogin)
          .toLowerCase()
          .replace(" ", "")}
      </>
    ) : (
      <>&nbsp;</>
    )}
  </div>
);

const Back = () => (
  <div className={styles.back}>
    <NavLink to="/" className={styles.backButton}>
      <BackIcon className={styles.backIcon} width={24} height={20.525} />
    </NavLink>
  </div>
);

const TabIndicator = ({ activeIndex }: { activeIndex?: number }) => (
  <div
    className={cn(styles.tabIndicator, styles[`tabIndicator$i${activeIndex}`])}
  ></div>
);

const Tab = ({
  children,
  to,
  onClick,
}: {
  children: ReactNode;
  to: string;
  onClick?: () => void;
}) => (
  <NavLink
    to={to}
    className={styles.tab}
    activeClassName={styles.tab$active}
    onClick={onClick}
  >
    {children}
  </NavLink>
);

type RestrictedRoute = {
  route: string;
  permission: keyof SearchPermissions;
};

const restrictedRoutes: RestrictedRoute[] = [
  { route: "/search-quickpair", permission: "quickPAIR" },
  { route: "/search-examiner", permission: "examiner" },
  { route: "/search-art-unit", permission: "artUnit" },
  { route: "/search-assignee", permission: "assigneeApplicant" },
  { route: "/search-law-firm", permission: "lawFirm" },
  { route: "/tech-center-navigator", permission: "pathways" },
  { route: "/search-file-wrapper", permission: "fileWrapper" },
];

const QuickPAIRTab = () => (
  <Tab
    to="/search-quickpair"
    onClick={() => {
      recordTabClick("quick_pair");
    }}
  >
    QuickPAIR
  </Tab>
);

const ExaminerTab = () => (
  <Tab
    to="/search-examiner"
    onClick={() => {
      recordTabClick("examiner");
    }}
  >
    Examiner
  </Tab>
);

const ArtUnitTab = () => (
  <Tab
    to="/search-art-unit"
    onClick={() => {
      recordTabClick("art_unit");
    }}
  >
    Art Unit
  </Tab>
);

const AssigneeTab = () => (
  <Tab
    to="/search-assignee"
    onClick={() => {
      recordTabClick("assignee");
    }}
  >
    Assignee
  </Tab>
);

const LawFirmTab = () => (
  <Tab
    to="/search-law-firm"
    onClick={() => {
      recordTabClick("law_firm");
    }}
  >
    Law Firm
  </Tab>
);

const TechCenterNavigatorTab = () => (
  <Tab
    to="/tech-center-navigator"
    onClick={() => {
      recordTabClick("tech_center_navigator");
    }}
  >
    Tech Center Navigator
  </Tab>
);

const FileWrappersTab = () => (
  <Tab
    to="/search-file-wrapper"
    onClick={() => {
      recordTabClick("file_wrappers");
    }}
  >
    File Wrappers
  </Tab>
);

const PTABDecisionsTab = () => (
  <Tab
    to="/ptab-decisions"
    onClick={() => {
      recordTabClick("ptab_decisions");
    }}
  >
    PTAB Decisions
  </Tab>
);

import React, { FormEventHandler, useState } from "react";

import Input from "components/Input";
import Button from "components/Button";
import { getAnalytics } from "domain/analytics";

import { TabPanel, TabPanelItem } from ".";

export default function ExaminerForm() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const urlParams = new URLSearchParams({
      f: firstName,
      l: lastName,
    });
    recordFormSubmission(firstName, lastName);
    window.location.href = `/examiner-search.php?${urlParams.toString()}`;
  };

  return (
    <TabPanel>
      <form style={{ width: "100%" }} onSubmit={handleSubmit}>
        <TabPanelItem>
          <Input
            label="First Name"
            value={firstName}
            onChange={(value) => setFirstName(value.trimLeft())}
          />
        </TabPanelItem>
        <TabPanelItem>
          <Input
            label="Last Name"
            value={lastName}
            onChange={(value) => setLastName(value.trimLeft())}
          />
        </TabPanelItem>
        <TabPanelItem>
          <Button
            id="homepageExaminerBtnSubmit"
            type="submit"
            variant="brand"
            leftIcon="magnifier"
            isDisabled={firstName === "" && lastName === ""}
          >
            Search
          </Button>
        </TabPanelItem>
      </form>
    </TabPanel>
  );
}

function recordFormSubmission(firstName: string, lastName: string) {
  getAnalytics().recordEvent({
    pageName: "home_page",
    interfaceEvent: "search_examiners",
    interfaceValue: "search",
    action: "click",
    context: {
      firstName,
      lastName,
    },
  });
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2Z7Ebf5X628PEnJt5kqAYV{width:100%;padding:0 0 100px 0;display:flex;justify-content:center}@media (min-width: 1154px){._2Z7Ebf5X628PEnJt5kqAYV{padding:60px 0 100px 0}}@media (min-width: 1600px){._2Z7Ebf5X628PEnJt5kqAYV{padding:80px 0 100px 0}}\n", "",{"version":3,"sources":["webpack://./src/pages/TrialUserApprovalPage/module.sass"],"names":[],"mappings":"AAGA,yBACC,UAAW,CACX,mBAAoB,CACpB,YAAa,CACb,sBAAuB,CAEvB,2BAND,yBAOE,sBAAuB,CAGK,CAD7B,2BATD,yBAUE,sBAAuB,CAAK","sourcesContent":["@import 'style/colors';\n@import 'style/variables';\n\n.container {\n\twidth: 100%;\n\tpadding: 0 0 100px 0;\n\tdisplay: flex;\n\tjustify-content: center;\n\n\t@media (min-width: 1154px) {\n\t\tpadding: 60px 0 100px 0; }\n\n\t@media (min-width: $bp-xl) {\n\t\tpadding: 80px 0 100px 0; } }\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_2Z7Ebf5X628PEnJt5kqAYV"
};
export default ___CSS_LOADER_EXPORT___;

import React, { HTMLAttributes, useMemo } from "react";
import { Column, usePagination, useSortBy, useTable } from "react-table";

import { SubmittedTrialRequest } from "domain/trial";
import { format as formatDate } from "domain/dates";
import Button from "components/Button";
import Text from "atoms/Text";
import Table, {
  EmptyMessage,
  ExtendedColumn,
  actionsHeaderAdditionalProps,
} from "components/Table";

export type Props = {
  trialRequests: SubmittedTrialRequest[];
  onApprove: (trialRequest: SubmittedTrialRequest) => void;
  onReject: (trialRequest: SubmittedTrialRequest) => void;
  onHide: (trialRequest: SubmittedTrialRequest) => void;
} & HTMLAttributes<HTMLDivElement>;

/**
 * Memoize trialRequests before passing as a prop.
 */
export default function PendingTrialRequests({
  trialRequests,
  onApprove,
  onReject,
  onHide,
  ...rest
}: Props) {
  const pendingRequests = useMemo(
    () => trialRequests.filter((r) => r.status === "pending"),
    [trialRequests]
  );
  const pendingRequestsColumns: ExtendedColumn<SubmittedTrialRequest>[] =
    useMemo(
      () => [
        { Header: "Name", accessor: "name" },
        { Header: "Company", accessor: "company" },
        { Header: "Email", accessor: "email" },
        {
          Header: "Date of Request",
          accessor: "dateRequested",
          sortType: "datetime",
          Cell: ({ value }) => formatDate(value, "Y-m-d H:M"),
        },
        {
          Header: "Status",
          accessor: "status",
          disableSortBy: true,
          Cell: ({ value }) => value.charAt(0).toUpperCase() + value.slice(1),
        },
        {
          Header: "Actions",
          accessor: "id",
          id: "actions",
          additionalHeaderProps: actionsHeaderAdditionalProps,
          disableSortBy: true,
          Cell: ({ row }) => (
            <div style={{ display: "flex" }}>
              <Button
                size="xs"
                variant="contained"
                onClick={() => onApprove(row.original)}
              >
                Approve
              </Button>
              <Button
                size="xs"
                variant="contained"
                style={{ marginLeft: 8 }}
                onClick={() => onReject(row.original)}
              >
                Reject
              </Button>
              <Button
                size="xs"
                variant="outlined"
                style={{ marginLeft: 8 }}
                onClick={() => onHide(row.original)}
              >
                Hide
              </Button>
            </div>
          ),
        },
      ],
      [pendingRequests]
    );
  const pendingRequestsTable = useTable(
    {
      columns: pendingRequestsColumns,
      data: pendingRequests,
      disableSortRemove: true,
      autoResetSortBy: false,
      initialState: {
        pageSize: 10,
        sortBy: useMemo(
          () => [
            {
              id: "dateRequested",
              desc: true,
            },
          ],
          []
        ),
      },
    },
    useSortBy,
    usePagination
  );

  return (
    <section {...rest}>
      <Text as="h2" weight="bold" size={18} style={{ marginBottom: 14 }}>
        Pending Requests ({pendingRequests.length})
      </Text>
      <Table
        table={pendingRequestsTable}
        emptyMessage={
          <EmptyMessage colSpan={6}>There are no pending requests</EmptyMessage>
        }
      />
    </section>
  );
}

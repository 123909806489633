import { ArgumentType } from "domain/ptabDecisions";

export const toPercent = (
  value?: number,
  defaultValue = "-"
): String | undefined => {
  if (!value || value === undefined) return defaultValue;

  return value < 0.5 ? "<1%" : `${Math.round(value)}%`;
};

export type Node = { parent: ArgumentType; children: Array<ArgumentType> };

export const split = (
  items: Array<ArgumentType>,
  level: number
): Array<Node> => {
  const result: Array<Node> = [];

  let parent: ArgumentType | null = null;
  let children: Array<ArgumentType> = [];

  for (const item of items) {
    if (item.tree_lvl === level) {
      if (parent !== null) {
        result.push({ parent, children });
      }
      parent = item;
      children = [];
    } else {
      children.push(item);
    }
  }

  if (parent !== null) {
    result.push({ parent, children });
  }

  return result;
};

export const findSequences = (
  items: Array<ArgumentType>
): Array<Array<ArgumentType>> => {
  const result: Array<Array<ArgumentType>> = [];
  let sequence: Array<ArgumentType> = [];

  for (const item of items) {
    if (sequence.length > 0 && item.tree_lvl + 1 <= sequence.length) {
      result.push(sequence);

      sequence = [...sequence.slice(0, item.tree_lvl)];
    }

    sequence.push(item);
  }

  if (sequence?.length > 0) {
    result.push(sequence);
  }

  return result;
};

export const findAncestors = (
  items: Array<ArgumentType>,
  id: number
): Array<ArgumentType> => {
  const result: Array<ArgumentType> = [];

  const index = items.findIndex((x) => x.id === id);
  const sourceItem = items[index];
  let treeLvl = sourceItem.tree_lvl;

  for (let i = index; i--; i >= 0) {
    const item = items[i];

    if (item.tree_lvl === treeLvl - 1) {
      result.unshift(item);
      treeLvl = item.tree_lvl;
    }
  }

  return result;
};

export const findSuccessors = (
  items: Array<ArgumentType>,
  id: number
): Array<ArgumentType> => {
  const result: Array<ArgumentType> = [];

  const index = items.findIndex((x) => x.id === id);
  const sourceItem = items[index];

  for (const item of items.slice(index + 1, items.length)) {
    if (item.tree_lvl <= sourceItem.tree_lvl) break;
    result.push(item);
  }

  return result;
};

import React from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";

import {
  Briefcase,
  fetchAll,
  deleteSingle,
  create,
  edit,
} from "domain/briefcases";

export default function useBriefcases() {
  const queryClient = useQueryClient();
  const { status, data: briefcases = [] } = useQuery("briefcases", fetchAll);

  const deletion = useMutation(deleteSingle, {
    onMutate: async (briefcase) => {
      await queryClient.cancelQueries("briefcases");
      const previousBriefcases =
        queryClient.getQueryData<Briefcase[]>("briefcases") ?? [];

      const newBriefcases = previousBriefcases.filter(
        (b) => b.uuid !== briefcase.uuid
      );
      queryClient.setQueryData<Briefcase[]>("briefcases", newBriefcases);

      const rollback = () => {
        queryClient.setQueryData<Briefcase[]>("briefcases", previousBriefcases);
      };
      return { rollback };
    },
    onError: (err, briefcase, context: any) => {
      context?.rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("briefcases");
      queryClient.invalidateQueries("briefcasesAppCounts");
      queryClient.invalidateQueries("monitors");
    },
  });
  const creation = useMutation(create, {
    onSuccess: () => {
      queryClient.invalidateQueries("briefcases");
      queryClient.invalidateQueries("briefcasesAppCounts");
    },
  });
  const edition = useMutation(edit, {
    onMutate: async (newBriefcase) => {
      await queryClient.cancelQueries("briefcases");
      const previousBriefcases = queryClient.getQueryData<Briefcase[]>(
        "briefcases"
      );

      if (previousBriefcases) {
        queryClient.setQueryData<Briefcase[]>("briefcases", [
          ...previousBriefcases.filter((b) => b.uuid !== newBriefcase.uuid),
          newBriefcase,
        ]);
      }
      return { previousBriefcases };
    },
    onError: (error, variables, context: any) => {
      if (context?.previousBriefcases) {
        queryClient.setQueryData<Briefcase[]>(
          "briefcases",
          context.previousBriefcases
        );
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries("briefcases");
      queryClient.invalidateQueries("briefcasesAppCounts");
    },
  });

  return { status, briefcases, mutations: { deletion, creation, edition } };
}

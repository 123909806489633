import React, { forwardRef } from "react";

import Input, { Props as InputProps } from "components/Input";
import SelectDropdown from "molecules/SelectDropdown";
import { Option } from "atoms/SelectOption";

import styles from "./module.sass";

export type Props = InputProps & {
  options: Option<string>[];
  onSelect: (option: Option<string>) => void;

  isNoResultsMessageShown?: boolean;
};

const AutocompleteInput = forwardRef<HTMLInputElement, Props>(
  ({ options, isNoResultsMessageShown = false, onSelect, ...rest }, ref) => (
    <div style={{ position: "relative", width: "100%" }}>
      <Input ref={ref} {...rest} />
      {isNoResultsMessageShown && <NoResultsDropdown />}
      {options.length > 0 && (
        <SelectDropdown
          options={options}
          style={{ top: "unset" }}
          maxHeight={230}
          onSelect={onSelect}
        />
      )}
    </div>
  )
);

const NoResultsDropdown = () => (
  <div className={styles.noResultsDropdown}>No results</div>
);

export default AutocompleteInput;

import { insertAsyncScript } from "domain/dom";

import { Analytics, Event, User } from "./factory";

export type MatomoSessionStorage = Pick<Storage, "setItem" | "removeItem">;

abstract class MatomoAnalytics implements Analytics {
  private sessionStorage: MatomoSessionStorage = window.sessionStorage;

  constructor(insertMatomoScript: (url: string) => void = insertAsyncScript) {
    this.disableSessionRecording();
    insertMatomoScript(this.getBaseUrl());
  }

  public recordEvent(event: Event) {
    this.client.push({
      event: "dlv_track",
      dlv_eventData: this.mapEventToMatomoEvent(event),
    });
  }

  public recordPageView(url: string, name: string) {
    this.asyncQueue.push(["setCustomUrl", url]);
    this.asyncQueue.push(["setDocumentTitle", name]);
    this.asyncQueue.push(["trackPageView"]);
  }

  public setCurrentUser(user: User) {
    const matomoUserInfo = {
      userLoginId: user.userId,
      userPermIDUrn: user.wamId,
    };
    this.sessionStorage.setItem(
      "matomoUserInfo",
      JSON.stringify(matomoUserInfo)
    );
  }

  public setSessionStorage(sessionStorage: MatomoSessionStorage) {
    this.sessionStorage = sessionStorage;
  }

  public removeCurrentUser() {
    this.sessionStorage.removeItem("matomoUserInfo");
  }

  protected abstract getBaseUrl(): string;

  private disableSessionRecording() {
    this.asyncQueue.push(["HeatmapSessionRecording.addConfig", {}]);
  }

  private get client(): MatomoClient {
    return (window as any)._mtm || new NullMatomoClient();
  }

  private get asyncQueue(): any[] {
    let win = window as any;
    win._paq = win._paq || [];
    return win._paq;
  }

  private mapEventToMatomoEvent(event: Event): MatomoEventData {
    return {
      UI_PageName: event.pageName,
      UI_Component: event.component,
      UI_SubComponent: event.subComponent,
      UI_Event: event.interfaceEvent,
      UI_Value: event.interfaceValue,
      UI_Action: event.action,
      contextData: event.context,
    };
  }
}

interface MatomoClient {
  push: ({
    event,
    dlv_eventData,
  }: {
    event: "dlv_track";
    dlv_eventData: MatomoEventData;
  }) => void;
}

class NullMatomoClient implements MatomoClient {
  push() {}
}

type MatomoEventData = {
  UI_PageName?: string;
  UI_Component?: string;
  UI_SubComponent?: string;
  UI_Component_Custom?: string;
  UI_Event?: string;
  UI_Value?: string;
  UI_Action?: string;
  contextData?: Record<string, any>;
};

export class QAAnalytics extends MatomoAnalytics {
  protected getBaseUrl() {
    return "https://cert-webanalytics.lexisnexis.com/js/container_patentadvisor.min.js";
  }
}

export class ProductionAnalytics extends MatomoAnalytics {
  protected getBaseUrl() {
    return "https://webanalytics.lexisnexis.com/js/container_patentadvisor.min.js";
  }
}

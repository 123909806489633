import React from "react";
import Full from "./Full";
import Base from "./Base";
import Ball from "./Ball";

export type Props = {
  variant?: "full" | "ball" | "base";
  logoColor?: string;
  textColor?: string;
};

export default function LNIPLogo({
  variant = "full",
  textColor,
  logoColor,
}: Props) {
  return (
    <>
      {variant === "full" && (
        <Full textColor={textColor} logoColor={logoColor} />
      )}
      {variant === "base" && <Base />}
      {variant === "ball" && <Ball />}
    </>
  );
}

import {
  STATUSES,
  basicFetch,
  fetchItems,
  downloadBlob,
  base64toBlob,
  SERVER_RESPONSE,
} from "./utils";

import {
  ArgumentType,
  DecisionType,
  FetchDecisionsArgsType,
  ExaminerType,
  LawFirmType,
  OpinionType,
  ArtUnitType,
  JudgeType,
} from "./models";

export { STATUSES, FetchDecisionsArgsType };

export const fetchExaminers = ({
  examinerName,
}: {
  examinerName: string;
}): Promise<Array<ExaminerType>> => {
  let formData = new FormData();
  formData.append("function", "get_examiner");
  formData.append("examiner_name", examinerName);
  formData.append("limit_results", "100");

  return fetchItems(formData);
};

export const fetchLawFirms = ({
  lawFirmName,
}: {
  lawFirmName: string;
}): Promise<Array<LawFirmType>> => {
  let formData = new FormData();
  formData.append("function", "get_law_firm");
  formData.append("law_firm_name", lawFirmName);
  formData.append("limit_results", "100");

  return fetchItems(formData);
};

export const fetchCustomerNumbers = ({
  customerNumber,
}: {
  customerNumber: string;
}): Promise<Array<number>> => {
  let formData = new FormData();
  formData.append("function", "get_customer_number");
  formData.append("customer_number", customerNumber);

  return fetchItems(formData);
};

export const fetchArtUnits = (): Promise<Array<ArtUnitType>> => {
  let formData = new FormData();
  formData.append("function", "get_art_unit_names");

  return fetchItems(formData);
};

export const downloadPdfFile = async (
  caseName: string,
  decisionId: number
): Promise<{
  error?: string;
}> => {
  let formData = new FormData();
  formData.append("function", "get_pdf_blob");
  formData.append("decision_id", `${decisionId}`);

  try {
    const resp = await basicFetch(formData);
    if (resp && resp.status !== STATUSES.ERROR && !!resp.data) {
      downloadBlob(base64toBlob(resp.data), "opinion.pdf");
    }

    return {
      error: resp?.status === STATUSES.OK ? undefined : resp?.message,
    };
  } catch (e) {
    console.log("blob error", e);
    return {
      error: e.message,
    };
  }
};

export const downloadPdfFiles = async (
  decisionIds: Array<string>
): Promise<{
  status: string;
  message: string;
  downloadedDocuments?: Array<string>;
  unavailableDocuments?: Array<string>;
}> => {
  let formData = new FormData();
  formData.append("function", "get_zip_blob");
  formData.append("decision_ids", `${decisionIds?.join(",")}`);

  try {
    const resp:
      | (SERVER_RESPONSE & {
          downloadedDocuments?: Array<string>;
          unavailableDocuments?: Array<string>;
        })
      | undefined = await basicFetch(formData);

    if (resp && resp.status !== STATUSES.ERROR && !!resp.data) {
      downloadBlob(base64toBlob(resp.data), "opinions.zip");
    }

    return {
      status: resp?.status ?? STATUSES.OK,
      message: resp?.message ?? "",
      downloadedDocuments: resp?.downloadedDocuments,
      unavailableDocuments: resp?.unavailableDocuments,
    };
  } catch (e) {
    console.log("blob error", e);
    return {
      message: e.message,
      status: STATUSES.ERROR,
    };
  }
};

export const fetchJudges = (name: string): Promise<Array<JudgeType>> => {
  let formData = new FormData();
  formData.append("function", "get_judge");
  formData.append("judge_name", name);

  return fetchItems(formData);
};

export const fetchOpinion = async ({
  decisionId,
}: {
  decisionId: number;
}): Promise<OpinionType | null> => {
  let formData = new FormData();
  formData.append("function", "get_opinion");
  formData.append("decision_id", decisionId.toString());

  const resp = await basicFetch(formData);
  if (resp?.status == STATUSES.OK) {
    return resp.data;
  } else {
    return null;
  }
};

export const fetchArguments = ({
  year,
  examinerId,
  artUnitValue,
  decisionType,
}: {
  year?: string;
  examinerId?: string;
  artUnitValue?: string;
  decisionType?: string;
}): Promise<Array<ArgumentType>> => {
  let formData = new FormData();
  formData.append("function", "get_tree");
  !!year && formData.append("decision_date", year);
  !!examinerId && formData.append("examiner_id", examinerId);
  !!artUnitValue && formData.append("artunit_name", artUnitValue);
  !!decisionType && formData.append("decision_type", decisionType);

  return fetchItems(formData);
};

export const fetchDecisions = async ({
  year,
  issues,
  tags,
  artUnitValue,
  outcomeRequest,
  decisionSearch,
  judgeName,
  lawFirmId,
  customerNumber,
  examinerId,
  decisionType,
}: FetchDecisionsArgsType): Promise<Array<DecisionType>> => {
  let formData = new FormData();
  formData.append("function", "get_decisions");

  issues?.length > 0 &&
    formData.append("selected_issues_ids", JSON.stringify({ issues }));
  tags?.length > 0 &&
    formData.append("selected_tags_ids", JSON.stringify({ tags }));
  !!artUnitValue && formData.append("artunit_name", artUnitValue);

  !!decisionSearch && formData.append("decision_text_search", decisionSearch);
  !!outcomeRequest && formData.append("outcome_request", outcomeRequest);
  !!judgeName && formData.append("judge_name", judgeName);
  !!lawFirmId && formData.append("law_firm_id", lawFirmId);
  !!customerNumber && formData.append("customer_number", customerNumber);
  !!examinerId && formData.append("examiner_id", examinerId);
  !!year && formData.append("decision_date", year);
  !!decisionType && formData.append("decision_type", decisionType);

  const res = await fetchItems(formData);

  return res.map((x: any) => ({ ...x, date: Date.parse(x.decision_date) }));
};

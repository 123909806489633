// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3qfyzK1tLPxJjJZjFMUIZp{background-color:red;height:100px}._3NZ7F3QiT8VxKZWE2H2XFw{height:20px;border-bottom:1px solid black}.dEFqMVega9q-I3WalbKzU{border-radius:4px;height:24px;width:54px;min-height:24px;min-width:54px;background-color:#132a43;color:white;font-size:13px}._1eSneBQozZYbpajkLFwK0c{height:32px;min-height:32px;width:54px}\n", "",{"version":3,"sources":["webpack://./src/components/AdminPanel/MonthlyReviewPage/module.sass"],"names":[],"mappings":"AAAA,yBACI,oBAAqB,CACrB,YAAa,CAAG,yBAGhB,WAAY,CACZ,6BAA8B,CAAG,uBAIjC,iBAAkB,CAClB,WAAY,CACZ,UAAW,CACX,eAAgB,CAChB,cAAe,CACf,wBAAiC,CACjC,WAAY,CACZ,cAAe,CAAG,yBAGlB,WAAY,CACZ,eAAgB,CAChB,UAAW","sourcesContent":[".container {\n    background-color: red;\n    height: 100px; }\n\n.item {\n    height: 20px;\n    border-bottom: 1px solid black; }\n\n\n.editLink {\n    border-radius: 4px;\n    height: 24px;\n    width: 54px;\n    min-height: 24px;\n    min-width: 54px;\n    background-color: rgb(19, 42, 67);\n    color: white;\n    font-size: 13px; }\n\n.goButton {\n    height: 32px;\n    min-height: 32px;\n    width: 54px; }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_3qfyzK1tLPxJjJZjFMUIZp",
	"item": "_3NZ7F3QiT8VxKZWE2H2XFw",
	"editLink": "dEFqMVega9q-I3WalbKzU",
	"goButton": "_1eSneBQozZYbpajkLFwK0c"
};
export default ___CSS_LOADER_EXPORT___;

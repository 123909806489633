import React, { createRef, forwardRef, useState } from "react";
import * as yup from "yup";
import { Formik, Form } from "formik";

import { countries, cnProvinces, usStates } from "domain/hubspot";
import { TrialRequest } from "domain/trial";
import { isBusinessEmail } from "domain/email";
import InputField from "molecules/InputField";
import SelectField from "molecules/SelectField";
import Button from "components/Button";
import { Validator } from "domain/validation";
import useWidthBreakpoint from "hooks/useWidthBreakpoint";
import { Password, PasswordStrength } from "domain/security";
import PasswordField from "molecules/PasswordField";
import CheckboxField from "molecules/CheckboxField";
import PrivacyPolicy from "molecules/PrivacyPolicy";
import Text from "atoms/Text";

import styles from "./module.sass";

export type Values = TrialRequest;

const defaultValues: TrialRequest = {
  firstName: "",
  lastName: "",
  companyHq: "",
  companyHqState: "",
  companyHqProvince: "",
  companyHqPostalCode: "",
  businessEmail: "",
  password: "",
  isNewsletterEnabled: false,
  businessPhone: "",
  companyName: "",
  jobTitle: "",
  personLinkedin: "",
  revenueRange: "",
  managementLevel: "",
  primaryIndustry: "",
  companyEmployees: "",
  companyWebsite: "",
  companyCountry: "",
  companyState: "",
  conversionPage: "",
};

const getMaxMessage = (max: number) => `${max} characters maximum`;

function isNewsletterFieldShown(
  country: Values["companyHq"] | undefined | null
) {
  return (
    country !== null &&
    country !== undefined &&
    [
      "BW",
      "KE",
      "MA",
      "NG",
      "ZA",
      "CN",
      "HK",
      "JP",
      "MO",
      "NP",
      "NZ",
      "PH",
      "KR",
      "TW",
      "VN",
      "AL",
      "AT",
      "BY",
      "BE",
      "BG",
      "HR",
      "CY",
      "CZ",
      "DK",
      "DE",
      "GR",
      "HU",
      "IT",
      "KZ",
      "LV",
      "LI",
      "LT",
      "LU",
      "MT",
      "NL",
      "NO",
      "PL",
      "PT",
      "RO",
      "RU",
      "RS",
      "SK",
      "SI",
      "ES",
      "SE",
      "CH",
      "TR",
      "UA",
      "IL",
      "CA",
      "AR",
      "BR",
      "CO",
      "CR",
      "NI",
      "PY",
      "PE",
      "GI",
      "IM",
      "JE",
    ].includes(country)
  );
}

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .max(64, getMaxMessage(64))
    .required("First name is required"),
  lastName: yup
    .string()
    .max(64, getMaxMessage(64))
    .required("Last name is required"),
  companyHq: yup.string().required("Location is required"),
  companyHqState: yup.string().when("companyHq", {
    is: "US",
    then: yup.string().required("State is required"),
    otherwise: yup.string(),
  }),
  companyHqProvince: yup.string().when("companyHq", {
    is: "CN",
    then: yup.string().required("Province is required"),
    otherwise: yup.string(),
  }),
  companyHqPostalCode: yup.string().when("companyHq", {
    is: "DE",
    then: yup
      .string()
      .length(5, "Please enter a valid postal code.")
      .required("Postal code is required"),
    otherwise: yup.string(),
  }),
  businessEmail: yup
    .string()
    .email("Please enter a valid email address.")
    .required("Business email is required")
    .test(
      "businessEmail-validation",
      "This does not appear to be a business email. If you need help, please contact Support.",
      (val) => {
        if (!val) {
          return false;
        }
        const domain = val.split("@").pop() as string;
        return isBusinessEmail(domain);
      }
    ),
  password: yup
    .string()
    .required("Password is required")
    .test("password-strength", "Password is not strong enough.", (val) => {
      if (!val) {
        return false;
      }
      const password = new Password(val);
      return password.strength >= PasswordStrength.High;
    }),
  isNewsletterEnabled: yup.bool(),
});

export type Props = {
  onSubmit: (values: Values) => void;
  initialValues?: Values;
  invalidFields?: string[];
};

const FirstStep = forwardRef<HTMLInputElement, Props>(
  ({ onSubmit, initialValues = defaultValues, invalidFields = [] }, ref) => {
    const [companyHq, setCompanyHq] = useState<string | null>(null);

    const hiddenInputBusinessPhone = document.getElementById(
      "businessPhone"
    ) as HTMLInputElement;
    const hiddenInputCompanyName = document.getElementById(
      "companyName"
    ) as HTMLInputElement;
    const hiddenInputJobTitle = document.getElementById(
      "jobTitle"
    ) as HTMLInputElement;
    const hiddenInputPersonLinkedin = document.getElementById(
      "personLinkedin"
    ) as HTMLInputElement;
    const hiddenInputRevenueRange = document.getElementById(
      "revenueRange"
    ) as HTMLInputElement;
    const hiddenInputManagementLevel = document.getElementById(
      "managementLevel"
    ) as HTMLInputElement;
    const hiddenInputPrimaryIndustry = document.getElementById(
      "primaryIndustry"
    ) as HTMLInputElement;
    const hiddenInputCompanyEmployees = document.getElementById(
      "companyEmployees"
    ) as HTMLInputElement;
    const hiddenInputCompanyWebsite = document.getElementById(
      "companyWebsite"
    ) as HTMLInputElement;
    const hiddenInputCompanyCountry = document.getElementById(
      "companyCountry"
    ) as HTMLInputElement;
    const hiddenInputCompanyState = document.getElementById(
      "companyState"
    ) as HTMLInputElement;
    const hiddenInputConversionPage = document.getElementById(
      "conversionPage"
    ) as HTMLInputElement;

    const isGdprFieldShown = isNewsletterFieldShown(companyHq);
    const widthBreakpoint = useWidthBreakpoint();
    const passwordPopoverPosition = widthBreakpoint == "xs" ? "top" : "right";

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form id="trialForm" style={{ margin: "40px 0" }}>
            <InputField
              name="firstName"
              label="First Name"
              ref={ref}
              maxLength={64}
              onChange={({ target: { value } }: any) => {
                const strippedVal = value.trimLeft().replace(/\s+/g, " ");
                const isValid = new Validator(strippedVal, 64).isValidName;
                if (isValid) {
                  setFieldValue("firstName", strippedVal);
                }
              }}
            />
            <InputField
              name="lastName"
              label="Last Name"
              maxLength={64}
              onChange={({ target: { value } }: any) => {
                const strippedVal = value.trimLeft().replace(/\s+/g, " ");
                const isValid = new Validator(strippedVal, 64).isValidName;
                if (isValid) {
                  setFieldValue("lastName", strippedVal);
                }
              }}
            />
            <SelectField
              name="companyHq"
              label="Country"
              options={countries.map((c) => {
                return {
                  ...c,
                  searchableLabel: c.label,
                  ref: createRef(),
                };
              })}
              onChange={(val) => {
                setFieldValue("companyHq", val);
                setFieldValue("companyHqState", "");
                setFieldValue("companyHqProvince", "");
                setFieldValue("companyHqPostalCode", "");
                setCompanyHq(val);
              }}
            />
            {values.companyHq === "US" && (
              <SelectField
                name="companyHqState"
                label="State"
                options={usStates.map((s) => {
                  return {
                    ...s,
                    searchableLabel: s.label,
                    ref: createRef(),
                  };
                })}
              />
            )}
            {values.companyHq === "CN" && (
              <SelectField
                name="companyHqProvince"
                label="Province"
                options={cnProvinces.map((p) => {
                  return {
                    ...p,
                    searchableLabel: p.label,
                    ref: createRef(),
                  };
                })}
              />
            )}
            {values.companyHq === "DE" && (
              <InputField
                name="companyHqPostalCode"
                label="Postal Code"
                type="number"
                onChange={({ target: { value } }: any) => {
                  const isValid = /^\d{0,5}$/.test(value);
                  if (isValid) {
                    setFieldValue("companyHqPostalCode", value);
                  }
                }}
              />
            )}
            <InputField
              name="businessEmail"
              label="Business Email"
              autoComplete="email"
              maxLength={256}
              onChange={({ target: { value } }: any) => {
                const strippedVal = value.trimLeft().replace(/\s+/g, " ");
                const isValid = !value || value.length <= 256;
                if (isValid) {
                  setFieldValue("businessEmail", strippedVal);
                }
              }}
            />
            <PasswordField
              name="password"
              label="Password"
              variant="new"
              blacklist={[
                values.firstName,
                values.lastName,
                values.businessEmail,
              ]}
              autoComplete="new-password"
              popoverPosition={passwordPopoverPosition}
            />
            {companyHq !== null ? (
              <PrivacyPolicy
                style={{ marginBottom: 18, letterSpacing: "-0.05px" }}
              />
            ) : (
              <div style={{ height: "4px" }}></div>
            )}

            {isGdprFieldShown && (
              <CheckboxField
                name="isNewsletterEnabled"
                label="Yes, please keep me updated about related products, services, and events."
                className={styles.privacyPolicyCheckbox}
              />
            )}

            <input type="hidden" name="businessPhone" id="businessPhone" />
            <input type="hidden" name="companyName" id="companyName" />
            <input type="hidden" name="jobTitle" id="jobTitle" />
            <input type="hidden" name="personLinkedin" id="personLinkedin" />
            <input type="hidden" name="revenueRange" id="revenueRange" />
            <input type="hidden" name="managementLevel" id="managementLevel" />
            <input type="hidden" name="primaryIndustry" id="primaryIndustry" />
            <input
              type="hidden"
              name="companyEmployees"
              id="companyEmployees"
            />
            <input type="hidden" name="companyWebsite" id="companyWebsite" />
            <input type="hidden" name="companyCountry" id="companyCountry" />
            <input type="hidden" name="companyState" id="companyState" />
            <input
              type="hidden"
              name="conversionPage"
              id="conversionPage"
              value={document.URL}
            />

            <Button
              variant="brand"
              type="submit"
              size="sm"
              onClick={() => {
                const businessPhone =
                  hiddenInputBusinessPhone?.getAttribute("value");
                const companyName =
                  hiddenInputCompanyName?.getAttribute("value");
                const jobTitle = hiddenInputJobTitle?.getAttribute("value");
                const personLinkedin =
                  hiddenInputPersonLinkedin?.getAttribute("value");
                const revenueRange =
                  hiddenInputRevenueRange?.getAttribute("value");
                const managementLevel =
                  hiddenInputManagementLevel?.getAttribute("value");
                const primaryIndustry =
                  hiddenInputPrimaryIndustry?.getAttribute("value");
                const companyEmployees =
                  hiddenInputCompanyEmployees?.getAttribute("value");
                const companyWebsite =
                  hiddenInputCompanyWebsite?.getAttribute("value");
                const companyCountry =
                  hiddenInputCompanyCountry?.getAttribute("value");
                const companyState =
                  hiddenInputCompanyState?.getAttribute("value");
                const conversionPage =
                  hiddenInputConversionPage?.getAttribute("value");

                setFieldValue(
                  "businessPhone",
                  businessPhone !== null ? businessPhone.toString() : ""
                );
                setFieldValue(
                  "companyName",
                  companyName !== null ? companyName : ""
                );
                setFieldValue("jobTitle", jobTitle !== null ? jobTitle : "");
                setFieldValue(
                  "personLinkedin",
                  personLinkedin !== null ? personLinkedin : ""
                );
                setFieldValue(
                  "revenueRange",
                  revenueRange !== null ? revenueRange : ""
                );
                setFieldValue(
                  "managementLevel",
                  managementLevel !== null ? managementLevel : ""
                );
                setFieldValue(
                  "primaryIndustry",
                  primaryIndustry !== null ? primaryIndustry : ""
                );
                setFieldValue(
                  "companyEmployees",
                  companyEmployees !== null ? companyEmployees : ""
                );
                setFieldValue(
                  "companyWebsite",
                  companyWebsite !== null ? companyWebsite : ""
                );
                setFieldValue(
                  "companyCountry",
                  companyCountry !== null ? companyCountry : ""
                );
                setFieldValue(
                  "companyState",
                  companyState !== null ? companyState : ""
                );
                setFieldValue(
                  "conversionPage",
                  conversionPage !== null ? conversionPage : ""
                );
              }}
            >
              Sign Up
            </Button>

            {invalidFields.length > 0 && (
              <Text color="red700" style={{ marginTop: 12 }}>
                Something went wrong. Please make sure you entered{" "}
                <Text as="span" color="red700" weight="bold">
                  {invalidFields
                    .map((f) =>
                      f
                        .replace(/([A-Z])/g, " $1")
                        .replace(/^./, (char) => char.toUpperCase())
                    )
                    .join(", ")}
                </Text>{" "}
                correctly.
              </Text>
            )}
          </Form>
        )}
      </Formik>
    );
  }
);

export default FirstStep;

import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import {
  findAllTrialRequests,
  approveTrialRequest,
  rejectTrialRequest,
  hideTrialRequest,
} from "domain/trial";

export default function useTrialRequests() {
  const queryClient = useQueryClient();

  const { status, data: trialRequests = [], error } = useQuery(
    "trialRequests",
    findAllTrialRequests
  );
  const approval = useMutation(approveTrialRequest, {
    onSettled: () => {
      queryClient.invalidateQueries("trialRequests");
    },
  });
  const rejection = useMutation(rejectTrialRequest, {
    onSettled: () => {
      queryClient.invalidateQueries("trialRequests");
    },
  });
  const hiding = useMutation(hideTrialRequest, {
    onSettled: () => {
      queryClient.invalidateQueries("trialRequests");
    },
  });

  return {
    status,
    trialRequests,
    error,
    mutations: { approval, rejection, hiding },
  };
}

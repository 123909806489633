import React, { HTMLAttributes, useState } from "react";

import Button from "components/Button";
import Checkbox from "components/Checkbox";
import colors from "style/colors.module.scss";
import Pulse from "atoms/Pulse";
import styleVariables from "style/variables.module.scss";
import resets from "style/resets.module.sass";

import OptionComponent from "./Option";
import {
  Value,
  Values,
  SelectionState,
  Option,
  getOptionWithDescendents,
  getValueToSelectionState,
  flattenOptions,
  indexByValue,
  indexParentsByValue,
} from "./options";

export interface Props<T> {
  options: Option<T>[];
  isLoading: boolean;
  onSelect: (selectedValues: Values) => void;
}

export type ValueToIsExpanded = Record<Value, boolean>;

export default function Dropdown<T>({
  options,
  isLoading,
  onSelect,
}: Props<T>) {
  // state
  const [selectedValues, setSelectedValues] = useState<Values>(new Set());
  const [valueToIsExpanded, setValueToIsExpanded] = useState<ValueToIsExpanded>(
    {}
  );

  // derived from props
  const hasOptions = options.length > 0;
  const hasNoOptions = !hasOptions;
  const hasLoaded = !isLoading;
  const flatOptions = flattenOptions(options);

  // derived from state and props
  const areAllOptionsSelected = selectedValues.size === flatOptions.length;
  const valueToSelectionState = getValueToSelectionState(
    selectedValues,
    options
  );

  function selectAll() {
    const allValues = flatOptions.map((o) => o.value);
    setSelectedValues(new Set(allValues));
  }

  function unselectAll() {
    setSelectedValues(new Set());
  }

  function selectOption<T>(option: Option<T>) {
    setSelectedValues((values) => {
      const newValues = new Set(values);
      getOptionWithDescendents(option).forEach((option) => {
        newValues.add(option.value);
      });
      return newValues;
    });
  }

  function unselectOption(option: Option<T>) {
    setSelectedValues((values) => {
      const newValues = new Set(values);
      getOptionWithDescendents(option).forEach((option) => {
        newValues.delete(option.value);
      });
      return newValues;
    });
  }

  function isExpanded(option: Option<T>) {
    return valueToIsExpanded?.[option.value] || false;
  }

  function toggleExpansion(option: Option<T>) {
    setValueToIsExpanded((valueToIsExpanded) => ({
      ...valueToIsExpanded,
      [option.value]: !isExpanded(option),
    }));
  }

  function getSelectionState(option: Option<T>): SelectionState {
    return valueToSelectionState[option.value];
  }

  /**
   * Specify selected options with the least number of IDs
   * by ignoring selected nested options when they can be
   * replaced solely by specifying their parent option
   * (for example, when all nested options of a parent are selected).
   *
   * Nested options are explicitely specified only when their parent
   * is partially selected.
   *
   * The motivation is to reduce the length of the GET request URL.
   */
  function compressSelectedOptions() {
    const valueToOption = indexByValue(flatOptions);
    const getOption = (value: Value) => valueToOption[value];

    /**
     * Flat means that the array is made up of nested options alongside
     * their parents. We don't need to traverse the tree to get to them.
     */
    const flatSelectedOptions = Array.from(selectedValues).map(getOption);
    const selectedValueToParent = indexParentsByValue(flatSelectedOptions);

    const getParent = (o: Option<T>): Option<T> | undefined =>
      selectedValueToParent[o.value];
    const isRootLevel = (o: Option<T>) => getParent(o) === undefined;
    const hasParent = (o: Option<T>) => !isRootLevel(o);
    const isSelected = (o: Option<T>) => getSelectionState(o) === "yes";
    const isPartiallySelected = (o: Option<T>) =>
      getSelectionState(o) === "partially";
    const isParentPartiallySelected = (o: Option<T>) =>
      hasParent(o) && isPartiallySelected(getParent(o)!);

    const selectedRootOptions = flatSelectedOptions
      .filter(isRootLevel)
      .filter(isSelected);
    const selectedNonRootOptions = flatSelectedOptions
      .filter(hasParent)
      .filter(isParentPartiallySelected);

    return selectedRootOptions.concat(selectedNonRootOptions);
  }

  function finishSelecting() {
    const compressedSelectedValues = compressSelectedOptions().map(
      (o) => o.value
    );
    onSelect(new Set(compressedSelectedValues));
  }

  return (
    <div
      style={{
        width: 536,
        position: "absolute",
        boxShadow: "2px 5px 6px rgba(0, 0, 0, 0.16)",
        border: `1px solid ${colors.blueGray400}`,
        borderRadius: 4,
        backgroundColor: colors.white,
        zIndex: Number(styleVariables.zDropdown),
        overflow: "hidden",
      }}
    >
      <Header>
        <Checkbox
          label="Select All"
          isChecked={hasLoaded && hasOptions && areAllOptionsSelected}
          isDisabled={hasNoOptions}
          onChange={(isChecked) => (isChecked ? selectAll() : unselectAll())}
        />
        <Button
          size="xxs"
          variant="naked"
          style={{ marginLeft: "auto" }}
          isDisabled={isLoading || hasNoOptions}
          onClick={unselectAll}
        >
          Reset
        </Button>
      </Header>
      <List>
        {isLoading && <Loader />}
        {hasLoaded &&
          options.map((option) => (
            <OptionComponent
              option={option}
              getSelectionState={getSelectionState}
              isExpanded={isExpanded}
              toggleExpansion={toggleExpansion}
              select={selectOption}
              unselect={unselectOption}
            />
          ))}
      </List>
      <Footer>
        <Button
          size="xs"
          variant="contained"
          style={{ marginLeft: "auto" }}
          isDisabled={isLoading || hasNoOptions}
          onClick={finishSelecting}
        >
          Select
        </Button>
      </Footer>
    </div>
  );
}

const Loader = () => (
  <div
    style={{
      height: 262,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Pulse variant="small" />
  </div>
);

const Header = (props: HTMLAttributes<HTMLDivElement>) => (
  <header
    style={{
      padding: "10px 16px",
      display: "flex",
      borderBottom: `1px solid ${colors.blueGray400}`,
    }}
    {...props}
  />
);

const List = (props: HTMLAttributes<HTMLOListElement>) => (
  <ol
    className={resets.listReset}
    style={{
      padding: 16,
      overflowY: "auto",
      maxHeight: 456,
      boxSizing: "border-box",
    }}
    {...props}
  />
);

const Footer = (props: HTMLAttributes<HTMLDivElement>) => (
  <footer
    style={{
      display: "flex",
      justifyContent: "flex-end",
      padding: 16,
      borderTop: `1px solid ${colors.blueGray400}`,
    }}
    {...props}
  />
);

import { getAnalytics } from "domain/analytics";

export function recordTabClick(tabLabel: string) {
  getAnalytics().recordEvent({
    pageName: "home_page",
    interfaceEvent: "tab_click",
    interfaceValue: tabLabel,
    action: "click",
  });
}

import axios from "axios";
import { BASE_URI } from "domain/api";

import { format as formatDate } from "domain/dates";

import { Briefcase, APIBriefcase } from "./models";

function fetchAll() {
  return axios
    .get<APIBriefcase[]>(
      `${BASE_URI}/api/v2/GetAllUsersBriefcasesController.php`
    )
    .then(({ data: apiBriefcases }) =>
      apiBriefcases.map(createFromAPIBriefcase)
    );
}

/**
 * Fetches for all briefcases by default.
 */
async function fetchAppCounts(briefcases: Briefcase[] = []) {
  type BriefcaseAppCounts = Record<string, number>;

  const resp = await axios.post<BriefcaseAppCounts>(
    "/api/v2/briefcases-app-counts.php",
    new URLSearchParams({
      uuids: briefcases.map((b) => b.uuid).join(","),
    })
  );
  return resp.data;
}

function create(name: string) {
  return axios
    .post<APIBriefcase>(
      `${BASE_URI}/api/v2/NewBriefcaseController.php`,
      new URLSearchParams({
        name,
      })
    )
    .then(({ data: apiBriefcase }) => createFromAPIBriefcase(apiBriefcase));
}

function deleteSingle(briefcase: Briefcase) {
  return axios.post(
    `${BASE_URI}/api/v2/DeleteBriefcaseWidgetController.php`,
    new URLSearchParams({
      uuid: briefcase.uuid,
    })
  );
}

async function edit(briefcase: Briefcase) {
  const { data: newBriefcase } = await axios.post<Briefcase>(
    `${BASE_URI}/api/v2/EditBriefcaseController.php`,
    new URLSearchParams({
      uuid: briefcase.uuid,
      name: briefcase.name,
      description: briefcase.description,
    })
  );
  return newBriefcase;
}

function generateAfterFinalStrategy(briefcase: Briefcase) {
  return axios.post(
    `${BASE_URI}/api/v2/AFSProgressController.php`,
    new URLSearchParams({
      uuid: briefcase.uuid,
    })
  );
}

function generatePortfolioPerformanceReport(
  briefcase: Briefcase,
  dateFrom: Date,
  dateTo: Date | null
) {
  return axios.post(
    `${BASE_URI}/api/v2/PortfolioPerformanceReportProgressController.php`,
    new URLSearchParams({
      uuid: briefcase.uuid,
      dateFrom: formatDate(dateFrom, "Y-m-d"),
      dateTo: formatDate(dateTo ?? new Date(), "Y-m-d"),
    })
  );
}

function createFromAPIBriefcase(apiBriefcase: APIBriefcase): Briefcase {
  const dateCreated = new Date(apiBriefcase.dateCreated);
  const dateUpdated = apiBriefcase.dateUpdated
    ? new Date(apiBriefcase.dateUpdated)
    : dateCreated;

  return {
    ...apiBriefcase,
    dateCreated,
    dateUpdated,
  };
}

export {
  fetchAll,
  fetchAppCounts,
  create,
  edit,
  deleteSingle,
  generateAfterFinalStrategy,
  generatePortfolioPerformanceReport,
};

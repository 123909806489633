import React, { useEffect } from "react";

import { User } from "domain/users";
import { initializeExperienceTracking } from "domain/experienceTracking";

export default function useExperienceTracking(user: User | null | undefined) {
  useEffect(() => {
    if (!user) {
      return;
    }
    initializeExperienceTracking(user);
  }, [user]);
}

import { Environment, getEnvironment } from "domain/environment";

import { buildAnalytics, Analytics } from "./factory";
import { NullAnalytics } from "./null";

let analytics: Analytics = new NullAnalytics();

/**
 * Builds analytics on the first invocation, returns the same instance
 * on following invocations. Passing a different environment won't
 * create a new instance if one has already been created.
 */
export function getAnalytics(
  environment: Environment = getEnvironment()
): Analytics {
  if (analytics instanceof NullAnalytics) {
    analytics = buildAnalytics(environment);
  }
  return analytics as unknown as Analytics;
}

import React, { useEffect, useRef } from "react";
import { Switch, Route } from "react-router-dom";

import useAnalytics from "hooks/useAnalytics";
import useExperienceTracking from "hooks/useExperienceTracking";
import useUser from "hooks/useUser";
import FreeTrialPage from "pages/FreeTrialPage";
import FreeTrialMarketingPage from "pages/FreeTrialMarketingPage";
import ExtensionThankYouPage from "pages/ExtensionThankYouPage";
import ReleaseNotesPage from "pages/ReleaseNotesPage";
import ReleaseNotesAdminPage from "pages/ReleaseNotesAdminPage";
import TrialUserApprovalPage from "pages/TrialUserApprovalPage";
import ArgumentFinderPage from "pages/ArgumentFinderPage";
import LicensingReportSetupPage from "pages/LicensingReportSetupPage";
import LicensingReportViewerPage from "pages/LicensingReportViewerPage";
import PlainTimelinePage from "pages/PlainTimelinePage";
// import QuickPAIRPage from "pages/QuickPAIRPage";

import "style/global.sass";

export default function App() {
  const { user, isLoggedOut } = useUser();

  useAnalytics(user);
  useExperienceTracking(user);

  return (
    <Switch>
      <Route path="/extension-registration" component={FreeTrialPage} />
      <Route path="/trial-form" component={FreeTrialMarketingPage} />
      <Route path="/extension-thank-you" component={ExtensionThankYouPage} />
      <Route path="/release-notes" component={ReleaseNotesPage} />
      <Route path="/release-notes-admin" component={ReleaseNotesAdminPage} />
      <Route path="/trial-user-approval" component={TrialUserApprovalPage} />
      <Route
        path="/licensing-reports/setup"
        component={LicensingReportSetupPage}
      />
      <Route
        path="/licensing-reports/view"
        component={LicensingReportViewerPage}
      />
      {/* <Route
        exact
        path="/quick-pair/:applicationNumber/timeline"
        render={(route) => (
          <QuickPAIRPage
            applicationNumber={Number(route.match.params.applicationNumber)}
          />
        )}
      /> */}
      <Route
        exact
        path="/application/:applicationNumber/plain-timeline"
        render={(route) => (
          <PlainTimelinePage
            applicationNumber={Number(route.match.params.applicationNumber)}
          />
        )}
      />
      <Route
        exact
        path="/decision/:id"
        render={(route) => {
          const idParam = route.match.params.id;
          if (isNaN(+idParam)) {
            return null;
          }

          const canGoBack = history.length !== 1; // won't cover all cases

          return (
            <ArgumentFinderPage
              decisionId={Number(idParam)}
              onBack={canGoBack ? () => route.history.goBack() : null}
            />
          );
        }}
      />
    </Switch>
  );
}

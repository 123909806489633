// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2LOw0vjgrILbEL-ELyCtJF{fill:#00172E}._2rhRcZ0ywJm6k3YKZvktv5:hover,._2rhRcZ0ywJm6k3YKZvktv5:focus{fill:#039BE5}\n", "",{"version":3,"sources":["webpack://./src/components/Icons/PdfIcon.module.sass"],"names":[],"mappings":"AAAA,yBACC,YAAa,CAEZ,8DAEC,YAAa","sourcesContent":[".pdf {\n\tfill: #00172E;\n\n\t&$hasHoverState {\n\t\t&:hover, &:focus {\n\t\t\tfill: #039BE5; } } }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pdf": "_2LOw0vjgrILbEL-ELyCtJF",
	"pdf$hasHoverState": "_2rhRcZ0ywJm6k3YKZvktv5"
};
export default ___CSS_LOADER_EXPORT___;

import React from "react";

const ThinChevron = ({
  className = "",
  direction = "left",
}: {
  className?: string;
  direction?: "left" | "right";
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 8.61 17.38"
    className={className}
    width="18"
    height="18"
  >
    {direction === "left" && (
      <path d="M8.61.94l-6.7 7.75 6.7 7.75-1.09.94L0 8.69 7.52 0l1.09.94z" />
    )}
    {direction === "right" && (
      <path
        transform="scale(-1, 1) translate(-8.61, 0)"
        d="M8.61.94l-6.7 7.75 6.7 7.75-1.09.94L0 8.69 7.52 0l1.09.94z"
      />
    )}
  </svg>
);

export default ThinChevron;

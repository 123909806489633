import React, { SVGProps } from "react";

export default function ExternalLink(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 13 13"
      width={13}
      height={13}
      {...props}
    >
      <path d="M12.41 0H7.65c-.33 0-.59.27-.59.59s.27.59.59.59h3.32L4.85 7.3c-.23.23-.24.61 0 .84.23.23.61.24.84 0l6.12-6.12v3.32c0 .33.27.59.59.59s.59-.27.59-.59V.59c0-.33-.27-.59-.59-.59Z" />
      <path d="M10.1 5.87c-.33 0-.59.27-.59.59v5.35H1.19V3.49h5.35c.33 0 .59-.27.59-.59s-.27-.59-.59-.59H.59c-.33 0-.59.27-.59.59v9.51c0 .33.27.59.59.59h9.51c.33 0 .59-.27.59-.59V6.47c0-.33-.27-.59-.59-.59Z" />
    </svg>
  );
}

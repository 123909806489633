import React, { ReactNode } from "react";

import Text from "atoms/Text";
import SmallReg from "components/SmallReg";

import cn from "classnames";
import styles from "./module.sass";

const Copy = () => (
  <div className={cn(styles.copy, styles.copy$step1)}>
    <PAIRCopy />
  </div>
);

export default Copy;

export const ListItem = ({ children }: { children: ReactNode }) => (
  <Text as="li" weight="bold" style={{ marginBottom: 10 }}>
    {children}
  </Text>
);

const PAIRCopy = () => (
  <>
    <Text
      as="h1"
      color="blue900"
      size={27}
      weight="bold"
      style={{ marginBottom: 18, lineHeight: "33px" }}
    >
      Sign up for access to the PatentAdvisor Extension
    </Text>
    <Text
      as="h2"
      size={27}
      weight="light"
      style={{ marginBottom: 20, lineHeight: "33px" }}
    >
      Your gateway to free Examiner Analytics in Patent Center
    </Text>
    <Text>
      Soon you'll experience Patent Center and Private PAIR in a totally new
      way. Sign up and get:
    </Text>
    <ul style={{ paddingLeft: 30, marginBottom: 20 }}>
      <ListItem>
        Key examiner statistics right at the point of need integrated in USPTO
        websites
      </ListItem>
      <ListItem>
        Allowance rate and Average Office Actions to Allowance for examiners and
        art units
      </ListItem>
      <ListItem>
        Examiner Time Allocation (ETA) metric to understand an examiner's
        behavior
      </ListItem>
    </ul>
  </>
);

const FreemiumCopy = () => (
  <>
    <Text
      as="h1"
      color="blue900"
      size={27}
      weight="bold"
      style={{ marginBottom: 18, lineHeight: "33px" }}
    >
      LexisNexis PatentAdvisor
      <SmallReg style={{ fontSize: 15 }} />
      <br />
      30 Day Trial
    </Text>
    <Text
      as="h2"
      size={27}
      weight="light"
      style={{ marginBottom: 20, lineHeight: "33px" }}
    >
      Drive prosecution efficiency and effectiveness to a new level.
    </Text>
    <Text>With your free trial, you have all the tools to:</Text>
    <ul style={{ paddingLeft: 30, marginBottom: 20 }}>
      <ListItem>
        Find the most efficient and effective path with your examiner
      </ListItem>
      <ListItem>
        Research rejections and craft a strategy to guide your decisions
      </ListItem>
      <ListItem>
        Decide whether to appeal and find the best arguments for your case
      </ListItem>
      <ListItem>
        Target the most favorable Tech Center group for your application
      </ListItem>
      <ListItem>
        Create reports to evaluate outside counsel and benchmark your standing
        in a tech space
      </ListItem>
    </ul>
    <Text>
      Plus, if you spend time in Public PAIR or Patent Center, you'll want to
      check out our PAIR Extension, which lets you see examiner data right on
      the USPTO's site.
    </Text>
  </>
);

import React, { forwardRef } from "react";
import { FieldHookConfig, useField } from "formik";

import TextArea, { Props as TextAreaProps } from "molecules/TextArea";

export type Props = TextAreaProps & FieldHookConfig<string>;

const TextAreaField = forwardRef<HTMLTextAreaElement, Props>(
  ({ label, render, rows, cols, onChange, ...rest }, ref) => {
    const [field, meta, helpers] = useField<string>(rest.name);

    return (
      <div style={{ marginBottom: 14 }}>
        <TextArea
          {...field}
          ref={ref}
          label={label}
          rows={rows}
          cols={cols}
          error={meta.touched && meta.error ? meta.error : undefined}
          render={render}
          onChange={onChange ?? ((e) => helpers.setValue(e.target.value))}
        />
      </div>
    );
  }
);

export default TextAreaField;

import React, { ReactNode } from "react";

import styles from "./module.sass";

export type Props = {
  navbar: ReactNode;
  argumentFinder: ReactNode;
};

export default function ArgumentFinderTemplate({
  navbar,
  argumentFinder,
}: Props) {
  return (
    <div className={styles.page}>
      {navbar}
      <main className={styles.container}>{argumentFinder}</main>
      {/* <Footer position="static" /> */}
    </div>
  );
}

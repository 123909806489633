import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import {
  findAll,
  deleteSingle,
  ReleaseNote,
  create,
  edit,
} from "domain/releaseNotes";

export default function useReleaseNotesAdmin({
  isEnabled = true,
}: { isEnabled?: boolean } = {}) {
  const queryClient = useQueryClient();

  const { status, data: releaseNotes = [], error } = useQuery(
    "releaseNotes",
    findAll,
    {
      enabled: isEnabled,
    }
  );

  const deletion = useMutation(deleteSingle, {
    onMutate: async (id) => {
      await queryClient.cancelQueries("releaseNotes");
      const oldNotes =
        queryClient.getQueryData<ReleaseNote[]>("releaseNotes") ?? [];
      const newNotes = oldNotes.filter((note) => note.id !== id);

      queryClient.setQueryData<ReleaseNote[]>("releaseNotes", newNotes);

      const rollback = () => {
        queryClient.setQueryData<ReleaseNote[]>("releaseNotes", oldNotes);
      };
      return { rollback };
    },
    onError: (error, briefcase, context: any) => {
      context?.rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("releaseNotes");
      queryClient.invalidateQueries("latestReleaseNotes");
    },
  });
  const creation = useMutation(create, {
    onSuccess: () => {
      queryClient.invalidateQueries("releaseNotes");
      queryClient.invalidateQueries("latestReleaseNotes");
    },
  });
  const edition = useMutation(edit, {
    onMutate: async (newNote) => {
      await queryClient.cancelQueries("releaseNotes");
      const oldNotes =
        queryClient.getQueryData<ReleaseNote[]>("releaseNotes") ?? [];
      const newNotes = [
        ...oldNotes.filter((note) => note.id !== newNote.id),
        newNote,
      ];

      queryClient.setQueryData<ReleaseNote[]>("releaseNotes", newNotes);

      const rollback = () => {
        queryClient.setQueryData<ReleaseNote[]>("releaseNotes", oldNotes);
      };

      return { rollback };
    },
    onError: (error, variables, context: any) => {
      context?.rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("releaseNotes");
      queryClient.invalidateQueries("latestReleaseNotes");
    },
  });

  return {
    status,
    releaseNotes,
    error,
    mutations: { deletion, creation, edition },
  };
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uArkLK3YSWjk2HbPUsP7h{cursor:pointer;width:18px;height:18px;background-color:transparent}.uArkLK3YSWjk2HbPUsP7h svg{fill:#6d6e71}.uArkLK3YSWjk2HbPUsP7h:hover svg{fill:#039be5}._2hklxwZBFKahqAFkadD2Wo{max-width:400px;display:block;overflow-wrap:break-word;word-break:break-word;font-family:Lato;font-size:16px;line-height:24px}\n", "",{"version":3,"sources":["webpack://./src/components/PTABDecisions/components/Tooltip/module.sass"],"names":[],"mappings":"AAAA,uBACI,cAAe,CACf,UAAW,CACX,WAAY,CACZ,4BAA6B,CAJjC,2BAOQ,YAAwB,CAPhC,iCAWY,YAAsB,CAAG,yBAGjC,eAAgB,CAChB,aAAc,CACd,wBAAyB,CACzB,qBAAsB,CACtB,gBAAiB,CACjB,cAAe,CACf,gBAAiB","sourcesContent":[".container {\n    cursor: pointer;\n    width: 18px;\n    height: 18px;\n    background-color: transparent;\n\n    svg {\n        fill: rgb(109, 110, 113); }\n\n    &:hover {\n        svg {\n            fill: rgb(3, 155, 229); } } }\n\n.tooltip {\n    max-width: 400px;\n    display: block;\n    overflow-wrap: break-word;\n    word-break: break-word;\n    font-family: Lato;\n    font-size: 16px;\n    line-height: 24px; }\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "uArkLK3YSWjk2HbPUsP7h",
	"tooltip": "_2hklxwZBFKahqAFkadD2Wo"
};
export default ___CSS_LOADER_EXPORT___;

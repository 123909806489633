import React, { HTMLAttributes } from "react";

import Checkbox from "components/Checkbox";
import Text from "atoms/Text";
import { SmallChevron } from "components/Icons";
import Button from "components/Button";
import colors from "style/colors.module.scss";

import { Option, SelectionState, isLeafNode } from "./options";

export interface Props<T> {
  option: Option<T>;
  getSelectionState: (option: Option<T>) => SelectionState;
  isExpanded: (option: Option<T>) => boolean;
  toggleExpansion: (option: Option<T>) => void;
  select: (option: Option<T>) => void;
  unselect: (option: Option<T>) => void;
}

export default function OptionComponent<T>({
  option,
  getSelectionState,
  isExpanded,
  toggleExpansion,
  select,
  unselect,
}: Props<T>) {
  const selectionState = getSelectionState(option);
  const canExpand = !isLeafNode(option);

  return (
    <>
      <li key={option.value} style={{ display: "flex", marginBottom: 12 }}>
        <Checkbox
          isChecked={selectionState === "yes"}
          isIndeterminate={selectionState === "partially"}
          onChange={(isChecked) =>
            isChecked ? select(option) : unselect(option)
          }
        />
        {canExpand && (
          <Expander
            isExpanded={isExpanded(option)}
            style={{ marginLeft: 6 }}
            onClick={() => toggleExpansion(option)}
          />
        )}
        <Text
          size={14}
          style={{ width: "100%", marginLeft: canExpand ? 4 : 14 }}
        >
          {option.label}
        </Text>
      </li>
      {canExpand && isExpanded(option) && (
        <div style={{ marginLeft: 30 }}>
          {(option.nestedOptions || []).map((option) => (
            <OptionComponent
              option={option}
              getSelectionState={getSelectionState}
              select={select}
              unselect={unselect}
              toggleExpansion={toggleExpansion}
              isExpanded={isExpanded}
            />
          ))}
        </div>
      )}
    </>
  );
}

interface ExpanderProps extends HTMLAttributes<HTMLButtonElement> {
  isExpanded: boolean;
  onClick: () => void;
}

const Expander = ({
  isExpanded,
  onClick,
  style = {},
  ...rest
}: ExpanderProps) => (
  <Button
    size="xxxs"
    onClick={onClick}
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      ...style,
    }}
    {...rest}
  >
    <SmallChevron
      width={12}
      height={7}
      direction={isExpanded ? "down" : "right"}
      fill={colors.blue900}
    />
  </Button>
);

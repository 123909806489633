// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1Re7pLDNAvZuYF0goVx7Np{border-radius:4px;height:24px;width:54px;background-color:#132a43;color:white;font-size:13px}._1SnLWUhfPDd4jtpM_sO3i8{color:#E43935}\n", "",{"version":3,"sources":["webpack://./src/components/AdminPanel/DailyReviewPage/module.sass"],"names":[],"mappings":"AAAA,yBACI,iBAAkB,CAClB,WAAY,CACZ,UAAW,CACX,wBAAiC,CACjC,WAAY,CACZ,cAAe,CAAG,yBAGlB,aAAc","sourcesContent":[".editLink {\n    border-radius: 4px;\n    height: 24px;\n    width: 54px;\n    background-color: rgb(19, 42, 67);\n    color: white;\n    font-size: 13px; }\n\n.redText {\n    color: #E43935; }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editLink": "_1Re7pLDNAvZuYF0goVx7Np",
	"redText": "_1SnLWUhfPDd4jtpM_sO3i8"
};
export default ___CSS_LOADER_EXPORT___;

import React, { MouseEventHandler, useState } from "react";

import Modal from "components/Modal";
import Button from "components/Button";
import Input from "components/Input";

export type Props = {
  isOpen: boolean;
  onDismiss: () => void;
  onCreate: (name: string) => void;
};

export default function BriefcaseCreationDialog({
  isOpen,
  onDismiss,
  onCreate,
}: Props) {
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");

  const validate = () => {
    setNameError("");
    if (!name) {
      setNameError("This field is required.");
      return false;
    }

    return true;
  };
  const handleCreate: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (!validate()) {
      return;
    }
    onCreate(name);
    setName("");
    setNameError("");
  };
  const dismiss = () => {
    setName("");
    setNameError("");
    onDismiss();
  };

  return (
    <Modal
      title="Create new briefcase"
      ariaLabel="Create new briefcase"
      footer={
        <>
          <Button variant="outlined" size="xs" onClick={() => dismiss()}>
            <span style={{ minWidth: 50 }}>Close</span>
          </Button>
          <span style={{ paddingLeft: 6 }} />
          <Button variant="contained" size="xs" onClick={handleCreate}>
            <span style={{ minWidth: 50 }}>Save</span>
          </Button>
        </>
      }
      isOpen={isOpen}
      onDismiss={dismiss}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ width: 320, marginBottom: 16 }}>
          <Input
            label="Briefcase Name"
            value={name}
            error={nameError}
            onChange={(value) => setName(value)}
          />
        </div>
        <p>Briefcases will now be deleted after 6 months of inactivity.</p>
      </div>
    </Modal>
  );
}

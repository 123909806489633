// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1wbES9vpZvvTuABUadNV57{background-color:white;border:1px solid #aaa;min-width:400px;padding:10px;display:flex;flex-direction:column;position:relative;margin-top:10px}._1wbES9vpZvvTuABUadNV57 ._1NAJac1O3FxLCI6UJkvj26{visibility:collapse}._1wbES9vpZvvTuABUadNV57:hover ._1NAJac1O3FxLCI6UJkvj26{visibility:visible}._3FyHjasP_ZTtIu_WqH7SU_:hover{border:1px solid #337ab7}._1NAJac1O3FxLCI6UJkvj26{position:absolute;top:0;right:0;padding:14px;display:flex;flex-direction:column}._28x4VEeOxxswQ2WSXsoIxX{font-size:16px;line-height:16px;vertical-align:baseline}\n", "",{"version":3,"sources":["webpack://./src/components/AdminPanel/DecisionPage/components/module.sass"],"names":[],"mappings":"AAAA,yBACI,sBAAuB,CACvB,qBAAoC,CACpC,eAAgB,CAChB,YAAa,CACb,YAAa,CACb,qBAAsB,CACtB,iBAAkB,CAClB,eAAgB,CARpB,kDAWQ,mBAAoB,CAX5B,wDAeY,kBAAmB,CAAG,+BAI1B,wBAAmC,CAAG,yBAG1C,iBAAkB,CAClB,KAAM,CACN,OAAQ,CACR,YAAa,CACb,YAAa,CACb,qBAAsB,CAAG,yBAGzB,cAAe,CACf,gBAAiB,CACjB,uBAAwB","sourcesContent":[".root {\n    background-color: white;\n    border: 1px solid rgb(170, 170, 170);\n    min-width: 400px;\n    padding: 10px;\n    display: flex;\n    flex-direction: column;\n    position: relative;\n    margin-top: 10px;\n\n    .overlay {\n        visibility: collapse; }\n\n    &:hover {\n        .overlay {\n            visibility: visible; } } }\n\n.highlightOnHover {\n    &:hover {\n        border: 1px solid rgb(51, 122, 183); } }\n\n.overlay {\n    position: absolute;\n    top: 0;\n    right: 0;\n    padding: 14px;\n    display: flex;\n    flex-direction: column; }\n\n.titleText {\n    font-size: 16px;\n    line-height: 16px;\n    vertical-align: baseline; }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "_1wbES9vpZvvTuABUadNV57",
	"overlay": "_1NAJac1O3FxLCI6UJkvj26",
	"highlightOnHover": "_3FyHjasP_ZTtIu_WqH7SU_",
	"titleText": "_28x4VEeOxxswQ2WSXsoIxX"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grey900": "#212121",
	"grey850": "#373737",
	"grey700": "#616161",
	"grey600": "#757575",
	"grey400": "#bdbdbd",
	"grey350": "#d5d5d5",
	"grey200": "#eee",
	"grey100": "#f5f5f5",
	"blue900": "#0D47a1",
	"darkBlue800": "#132a43",
	"darkCyan600": "#465a63",
	"lightBlue800": "#006ebb",
	"blueGray900": "#455a64",
	"blueGray400": "#acbac8",
	"blueGray100": "#eceff1",
	"green400": "#66bb6a",
	"yellow800": "#f9a825",
	"orange500": "#ff9800",
	"red500": "#f6271c",
	"white": "#fff",
	"black": "#000"
};
export default ___CSS_LOADER_EXPORT___;

import { Briefcase } from "./models";

type SortOrder = "dateUpdated" | "dateCreated" | "alphabetical";

const sortByUUID = (a: Briefcase, b: Briefcase) => a.uuid.localeCompare(b.uuid);
const sortByDateCreated = (a: Briefcase, b: Briefcase) => {
  if (a.dateCreated.getTime() === b.dateCreated.getTime()) {
    return 0;
  }
  return a.dateCreated < b.dateCreated ? -1 : 1;
};
const sortByDateUpdated = (a: Briefcase, b: Briefcase) => {
  if (a.dateUpdated.getTime() === b.dateUpdated.getTime()) {
    return 0;
  }
  return a.dateUpdated < b.dateUpdated ? -1 : 1;
};

const getSortFunction = (
  sortOrder: SortOrder
): ((a: Briefcase, b: Briefcase) => number) => {
  return {
    dateCreated: (a: Briefcase, b: Briefcase) =>
      sortByDateCreated(a, b) || sortByUUID(a, b),
    dateUpdated: (a: Briefcase, b: Briefcase) =>
      sortByDateUpdated(a, b) || sortByUUID(a, b),
    alphabetical: (a: Briefcase, b: Briefcase) =>
      a.name.localeCompare(b.name) || sortByUUID(a, b),
  }[sortOrder];
};

export { SortOrder, getSortFunction };

import React, { useEffect } from "react";

import { getAnalytics, User } from "domain/analytics";

export default function useAnalytics(user: User | null | undefined) {
  useEffect(() => {
    if (!user) {
      getAnalytics().removeCurrentUser();
      return;
    }
    getAnalytics().setCurrentUser(user);
  }, [user]);
}

import React, { useState } from "react";

import Text from "atoms/Text";
import HorizontalRule from "atoms/HorizontalRule";
import { Application, Event as TimelineEvent } from "domain/applications";
import Button from "components/Button";
import colors from "style/colors.module.scss";

import { TimelineViewModel } from "./models/timeline";
import Info from "./Info";
import Events, { Event } from "./Events";
import Line, { Props as LineProps } from "./Line";
import ExpandedLine from "./ExpandedLine";

export interface Props {
  today: Date;
  application: Application;
  isCollapsed: boolean;
  onIsCollapsedChange: (isCollapsed: boolean) => void;

  title?: string;
  areStatisticsLinksInternal?: boolean;
}

export default function Timeline({
  application,
  today,
  title = "TIMELINE",
  isCollapsed,
  areStatisticsLinksInternal = true,
  onIsCollapsedChange,
}: Props) {
  const { events, examiner } = application;
  const [focusedEvent, setFocusedEvent] = useState<TimelineEvent | null>(null);
  const [expandedEvent, setExpandedEvent] = useState<TimelineEvent | null>(
    null
  );

  const timelineViewModel = new TimelineViewModel(
    application.status,
    events,
    today,
    examiner?.expectedApprovalWindow
  );

  const handleEventUnfocused = () => {
    setFocusedEvent(null);
  };
  const handleFocusedEventChange: LineProps["onFocusedEventChange"] = (
    event
  ) => {
    setFocusedEvent(event);
  };
  const handleExpandedEventChange: LineProps["onExpandedEventChange"] = (
    event
  ) => {
    setExpandedEvent(event);
  };

  return (
    <article style={{ overflow: "hidden" }}>
      <Text as="h2" weight="bold" size={18} style={{ marginBottom: 16 }}>
        {title}
      </Text>

      <div style={{ display: "flex" }}>
        <div style={{ flex: 1, position: "relative" }}>
          <HorizontalRule style={{ marginBottom: 24 }} />
          <Info
            application={application}
            areStatisticsLinksInternal={areStatisticsLinksInternal}
          />
          <HorizontalRule style={{ margin: "24px 0 42px 0" }} />
          <Line
            variant={isCollapsed ? "short" : "tall"}
            status={application.status}
            timelineViewModel={timelineViewModel}
            focusedEvent={focusedEvent}
            expandedEvent={expandedEvent}
            onFocusedEventChange={handleFocusedEventChange}
            onExpandedEventChange={handleExpandedEventChange}
          />
          {!isCollapsed && (
            <ExpandedLine
              timelineViewModel={timelineViewModel}
              style={{ position: "absolute", top: 360, width: "100%" }}
              // style={{ position: "absolute", top: 342, width: "100%" }}
            />
          )}
          <Button
            variant="link"
            style={{
              // position: "absolute",
              // marginTop: isCollapsed ? 2 : -6,
              height: "auto",
              marginTop: isCollapsed ? 24 : 18,
              padding: 0,
              fontSize: 12,
            }}
            onClick={() => onIsCollapsedChange(!isCollapsed)}
          >
            {isCollapsed ? "Expand Timeline" : "Collapse Timeline"}
          </Button>
        </div>
        <Events
          style={{
            // marginLeft: 32,
            height: isCollapsed ? 344 : "auto",
            position: "relative",
            // outline: "32px solid white",
            borderLeft: "48px solid white",
            // to be above the line on the left so that long expected approval
            // windows don't go over it
            zIndex: 1,
          }}
        >
          {
            // outline and zIndex are here to hide expected approval windows
            // which overflow the timeline on the X axis
          }
          {events.map((event, i) => (
            <Event
              key={i}
              event={event}
              ordinalNumber={timelineViewModel.getEventOrdinalNumberAmongSameType(
                event
              )}
              isExternallyFocused={focusedEvent === event}
              onMouseOver={() => setFocusedEvent(event)}
              onMouseOut={handleEventUnfocused}
              onClick={() => setExpandedEvent(event)}
            />
          ))}
        </Events>
      </div>
    </article>
  );
}

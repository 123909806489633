import axios from "axios";
import { BASE_URI } from "domain/api";

import { APIFile, File } from "./models";

function fetchRecentFiles(): Promise<File[]> {
  return axios
    .get<APIFile[]>(`${BASE_URI}/api/v2/GetRecentFilesController.php`)
    .then(({ data: apiFiles }) => apiFiles.map(createFromAPIFile));
}

function deleteRecentFile(file: File): Promise<boolean> {
  return axios.post(
    `${BASE_URI}/api/v2/DeleteRecentFileController.php`,
    new URLSearchParams({
      _id: file.id.toString(),
    })
  );
}

function createFromAPIFile(apiFile: APIFile): File {
  return {
    id: apiFile._id,
    name: apiFile.filename,
    dateCreated: new Date(apiFile.timestamp * 1000),
    bytes: apiFile.length,
    downloadLink: apiFile.downloadLink,
  };
}

export { fetchRecentFiles, deleteRecentFile };

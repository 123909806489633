import { MonthOfYear } from "domain/dates";

export interface Search {
  assigneeIds: string[];
  formattedCitedAssigneeNames: string;
  citationDateRange: CitationDateRange;
  citationDateFrom: CitationDate;
  citationDateTo: CitationDate;
  rejectionTypeFilter: RejectionTypeFilter;
  shouldIncludeAbandonedApps: boolean;
  shouldIncludeSelfCitations: boolean;
  reportName: string;
}

export type CitationDateRange =
  | "last5Years"
  | "last3Years"
  | "last1Year"
  | "customDateRange";

export type RejectionTypeFilter = "102And103" | "102" | "103";

export interface CitationDate {
  monthOfYear: MonthOfYear | null;
  hasError: boolean;
}

export const nullCitationDate: CitationDate = {
  monthOfYear: null,
  hasError: false,
};

export interface Storage {
  setItem(key: string, value: string): void;
  getItem(key: string): string | null;
  removeItem(key: string): void;
}

export class SearchHistory {
  private static lastSearchKey = "last ERA search";

  private storage: Storage;

  constructor(storage: Storage = window.sessionStorage) {
    this.storage = storage;
  }

  getLastSearch(): Search | null {
    const stringifiedValue = this.storage.getItem(SearchHistory.lastSearchKey);
    return stringifiedValue ? this.unserializeSearch(stringifiedValue) : null;
  }

  setLastSearch(search: Search) {
    const stringifiedValue = this.serializeSearch(search);
    this.storage.setItem(SearchHistory.lastSearchKey, stringifiedValue);
  }

  clearLastSearch() {
    this.storage.removeItem(SearchHistory.lastSearchKey);
  }

  private serializeSearch(search: Search): string {
    return JSON.stringify(search);
  }

  private unserializeSearch(stringifiedValue: string): Search | null {
    const incompleteValue = JSON.parse(stringifiedValue);

    const citationDateFromMonthOfYear =
      incompleteValue.citationDateFrom.monthOfYear;
    const citationDateToMonthOfYear =
      incompleteValue.citationDateTo.monthOfYear;

    return {
      ...incompleteValue,
      citationDateFrom: {
        monthOfYear: citationDateFromMonthOfYear
          ? new MonthOfYear(
              citationDateFromMonthOfYear.year,
              citationDateFromMonthOfYear.month
            )
          : null,
        hasError: false,
      },
      citationDateTo: {
        monthOfYear: citationDateToMonthOfYear
          ? new MonthOfYear(
              citationDateToMonthOfYear.year,
              citationDateToMonthOfYear.month
            )
          : null,
        hasError: false,
      },
    };
  }
}

export function getDefaultSearch(lastSearch: Search | null): Search {
  return {
    assigneeIds: lastSearch?.assigneeIds ?? [],
    formattedCitedAssigneeNames: lastSearch?.formattedCitedAssigneeNames ?? "",
    citationDateRange: lastSearch?.citationDateRange ?? "last5Years",
    citationDateFrom: lastSearch?.citationDateFrom ?? nullCitationDate,
    citationDateTo: lastSearch?.citationDateTo ?? {
      monthOfYear: MonthOfYear.now().addMonths(1),
      hasError: false,
    },
    rejectionTypeFilter: lastSearch?.rejectionTypeFilter ?? "102And103",
    shouldIncludeAbandonedApps: lastSearch?.shouldIncludeAbandonedApps ?? false,
    shouldIncludeSelfCitations: lastSearch?.shouldIncludeSelfCitations ?? false,
    reportName: lastSearch?.reportName ?? "",
  };
}

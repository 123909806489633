import React, { HTMLAttributes } from "react";
import cn from "classnames";

import { Event as TimelineEvent } from "domain/applications";
import Text from "atoms/Text";
import colors from "style/colors.module.scss";

import { createEventViewModel } from "../models/events";
import styles from "./module.sass";

export interface Props extends HTMLAttributes<HTMLDivElement> {}

export default function Events({ style, children, ...rest }: Props) {
  return (
    <section
      style={{
        width: 218,
        // width: 252,
        // boxSizing: "border-box",
        padding: "16px 24px 16px 12px",
        backgroundColor: colors.grey100,
        overflowY: "auto",
        ...style,
      }}
      {...rest}
    >
      <Text
        style={{
          marginLeft: 6,
          marginBottom: 18,
          fontWeight: "bold",
        }}
      >
        Dates
      </Text>
      {children}
    </section>
  );
}

export interface EventProps extends HTMLAttributes<HTMLDivElement> {
  event: TimelineEvent;
  ordinalNumber: number;
  isExternallyFocused?: boolean;
}

export function Event({
  event,
  ordinalNumber,
  isExternallyFocused = false,
  ...rest
}: EventProps) {
  const viewModel = createEventViewModel(event);

  return (
    <div
      className={cn(styles.event, isExternallyFocused && styles.event$focused)}
      {...rest}
    >
      <Circle style={{ backgroundColor: viewModel.color }} />
      <span style={{ marginLeft: 7, whiteSpace: "nowrap" }}>
        {viewModel.getShortName(ordinalNumber)}
      </span>
      <span style={{ marginLeft: "auto", whiteSpace: "nowrap" }}>
        {viewModel.shortDate}
      </span>
    </div>
  );
}

function Circle({ style, ...rest }: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      style={{
        width: 10,
        height: 10,
        borderRadius: "50%",
        ...style,
      }}
      {...rest}
    />
  );
}

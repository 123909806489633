// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2CzA6fG-P_eB3XN9s79ZXF:hover ._31KVZp7Wd_uu1ayhOGrfLE{fill:#ff8300}._31KVZp7Wd_uu1ayhOGrfLE{fill:#646569}\n", "",{"version":3,"sources":["webpack://./src/components/RELXLogo/module.sass"],"names":[],"mappings":"AAAA,wDAGM,YAAa,CAAG,yBAGpB,YAAa","sourcesContent":[".logo {\n  &:hover {\n    .graphic {\n      fill: #ff8300; } } }\n\n.graphic {\n  fill: #646569; }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "_2CzA6fG-P_eB3XN9s79ZXF",
	"graphic": "_31KVZp7Wd_uu1ayhOGrfLE"
};
export default ___CSS_LOADER_EXPORT___;

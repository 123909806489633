import React from "react";
import cn from "classnames";

import styles from "./module.sass";

export type Props = {
  step: 0 | 1 | 2;
};

export default function StrengthMeter({ step }: Props) {
  return (
    <div className={styles.strengthMeter}>
      <div
        className={cn(styles.strengthMeter, styles[`coloredBar$step${step}`])}
      ></div>
    </div>
  );
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1fmLHQkzORhzeAcC7v6A3u{min-height:100vh;background:#fff !important}._2Y3kElIQD6mihlIboLmHx{padding:60px 0 50px 0;display:flex;justify-content:center}@media (min-width: 1100px){._2Y3kElIQD6mihlIboLmHx{padding:130px 0 50px 0}}._4n11oAURi3w-X6HF_aSb3{padding:0 1px 0 0}@media (min-width: 1100px){._4n11oAURi3w-X6HF_aSb3{padding:0 1px 0 0}}\n", "",{"version":3,"sources":["webpack://./src/pages/FreeTrialPage/module.sass"],"names":[],"mappings":"AAGA,yBACI,gBAAiB,CACjB,0BAA6B,CAAG,wBAGhC,qBAAsB,CACtB,YAAa,CACb,sBAAuB,CAEvB,2BALJ,wBAMQ,sBAAuB,CAMM,CAJjC,wBACI,iBAAkB,CAElB,2BAHJ,wBAIQ,iBAAkB,CAAK","sourcesContent":["@import \"style/_variables\";\n@import \"style/_colors\";\n\n.marketingFreeTrialContainer {\n    min-height: 100vh;\n    background: $white !important; }\n\n.formContainer {\n    padding: 60px 0 50px 0;\n    display: flex;\n    justify-content: center;\n\n    @media (min-width: $bp-md) {\n        padding: 130px 0 50px 0; }\n\n    &$marketing {\n        padding: 0 1px 0 0;\n\n        @media (min-width: $bp-md) {\n            padding: 0 1px 0 0; } } }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"marketingFreeTrialContainer": "_1fmLHQkzORhzeAcC7v6A3u",
	"formContainer": "_2Y3kElIQD6mihlIboLmHx",
	"formContainer$marketing": "_4n11oAURi3w-X6HF_aSb3"
};
export default ___CSS_LOADER_EXPORT___;

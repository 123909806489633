import React from "react";
import { CSSProperties } from "react";

export const getReactSelectStyle = ({
  width,
  height,
}: {
  width?: number;
  height?: number;
}) => ({
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "rgb(227, 242, 253)"
      : state.isFocused
      ? "rgb(242, 250, 255)"
      : "none",
    borderRadius: 4,
    boxSizing: "border-box !important",
    color: "rgb(66, 66, 66)",
    margin: "0",
    padding: "8px 10px",
    width: "100%",
    minHeight: "33px",
    display: "block",
    alignItems: "center",
    fontFamily: "Lato",
    fontSize: 14,
    lineHeight: "20px",
  }),
  menu: (provided: any) => ({
    ...provided,
    boxSizing: "border-box !important",
    width: "100%",
    margin: 0,
    padding: 0,
    border: "none !important",
    outline: "none !important",
    overflow: "hidden",
    boxShadow: "none",
    borderRadius: 3,
  }),
  menuList: (provided: any) => ({
    ...provided,
    width: "100%",
    padding: "8px 10px !important",
    boxSizing: "border-box !important",
    boxShadow: "none",
    border: "1px solid rgb(213, 213, 213)",
    borderRadius: 3,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    boxSizing: "border-box !important",
    width: width ?? "100%",
    minWidth: 100,
    minHeight: 28,
    height: height ?? 40,
    boxShadow: "none",
    border: state.isFocused
      ? "1px solid rgb(13, 71, 161)"
      : "1px solid rgb(213, 213, 213)",
    "&:hover": {
      boxShadow: "none",
      border: "1px solid rgb(13, 71, 161)",
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontFamily: "Lato",
    fontSize: 16,
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: "0 8px",
    boxSizing: "border-box",
    height: "100%",
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    transform: state.selectProps.menuIsOpen && "rotate(180deg)",
    padding: "0 8px",
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    padding: "0 8px",
  }),
});

export const getReactSelectDefaultProps = ({
  hasValue,
  hasOptions,
  width,
  height,
}: {
  hasValue: boolean;
  hasOptions: boolean;
  width?: number;
  height?: number;
}): {} => {
  if (hasValue && hasOptions) {
    return {
      styles: getReactSelectStyle({ width, height }),
      components: {
        IndicatorSeparator: () => null,
        DropdownIndicator: () => null,
      },
    };
  } else if (hasValue && !hasOptions) {
    return {
      styles: getReactSelectStyle({ width, height }),
      components: {
        IndicatorSeparator: () => null,
        DropdownIndicator: () => null,
      },
    };
  } else if (!hasValue && hasOptions) {
    return {
      styles: getReactSelectStyle({ width, height }),
      components: {
        IndicatorSeparator: () => null,
        ClearIndicator: () => null,
      },
    };
  } else {
    return {
      styles: getReactSelectStyle({ width, height }),
      components: {
        IndicatorSeparator: () => null,
        ClearIndicator: () => null,
        DropdownIndicator: () => null,
      },
    };
  }
};

export type DictionaryItemType = {
  value: any;
  label: string;
};

export const range = (start: number, end: number): Array<number> => {
  return Array(end - start + 1)
    .fill(0)
    .map((x, i) => i + start);
};

export const Gap = ({
  size,
  strip,
  stripSize,
  vertical,
}: {
  size?: number;
  strip?: boolean;
  stripSize?: number;
  vertical?: boolean;
}) => {
  const defaultSize = 20;
  const stripStyle: CSSProperties = strip
    ? {
        marginLeft: size ?? defaultSize,
        height: stripSize ?? 24,
        borderLeft: "1px solid rgb(213, 213, 213)",
      }
    : {};
  const basicStyle: CSSProperties = vertical
    ? {
        height: size ?? defaultSize,
        minHeight: size ?? defaultSize,
        maxHeight: size ?? defaultSize,
      }
    : {
        width: size ?? defaultSize,
        minWidth: size ?? defaultSize,
        maxWidth: size ?? defaultSize,
      };
  return (
    <div
      style={{
        ...basicStyle,
        flexShrink: 0,
        flexGrow: 0,
        ...stripStyle,
      }}
    ></div>
  );
};

export const getParams = (args: any): URLSearchParams => {
  const params = new URLSearchParams();

  Object.keys(args)
    .sort()
    .forEach((key: string) => {
      const value: any = args[key];
      if (!!value) {
        params.append(key, `${value}`);
      }
    });

  return params;
};

export function isNumber(value?: string): boolean | undefined {
  if (!!value) {
    return /^-?\d+$/.test(value);
  }
}

export function withNumber(value?: string): string | undefined {
  if (isNumber(value)) {
    return value;
  } else {
    return undefined;
  }
}

export function safe<T>(action: () => T, defaultValue: T) {
  try {
    const res = action();
    return res;
  } catch {
    return defaultValue;
  }
}

export class RaceConditionGuard<PromiseResolveType = any> {
  private lastPromise?: Promise<PromiseResolveType>;

  getGuardedPromise(promise: Promise<PromiseResolveType>) {
    this.lastPromise = promise;
    return this.lastPromise.then(this.preventRaceCondition());
  }

  private preventRaceCondition() {
    const currentPromise = this.lastPromise;
    return (response: PromiseResolveType) => {
      if (this.lastPromise !== currentPromise) {
        return new Promise(() => null) as Promise<PromiseResolveType>;
      }
      return response;
    };
  }
}

export const timeoutAsync = async (delay: number): Promise<void> => {
  return new Promise<void>((resolve) => {
    setTimeout(resolve, delay);
  });
};

export const withDefaultString = (
  value?: string,
  defaultValue?: string
): string | undefined => {
  return !!value && value?.length > 0 ? value : defaultValue;
};

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const capitalise = (value?: string) => {
  return !value ? "" : value.charAt(0).toUpperCase() + value.slice(1);
};

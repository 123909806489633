import React from "react";
import { useQuery } from "react-query";

import {
  APILicensingReportRequest,
  fetchLicensingReport,
} from "domain/licensingReports";

export default function useLicensingReport(
  request: APILicensingReportRequest,
  isEnabled = true
) {
  const {
    data: licensingReport,
    status,
    error,
  } = useQuery(
    ["licensingReport", JSON.stringify(request)],
    () => fetchLicensingReport(request),
    { enabled: isEnabled }
  );

  return { licensingReport, status, error };
}

import React, { RefObject, useLayoutEffect, useState } from "react";

/** Returns by how much pixels in the label too wide. */
export function useLabelWidthCorrection(
  labelRef: RefObject<HTMLElement>,
  rectangleRef: RefObject<HTMLElement>
) {
  const [labelWidthCorrection, setLabelSizeCorrection] = useState(0);

  useLayoutEffect(() => {
    const label = labelRef.current;
    const rectangle = rectangleRef.current;
    if (!label || !rectangle) {
      return;
    }

    const overlap =
      label.getBoundingClientRect().right -
      rectangle.getBoundingClientRect().left;
    const isThereOverlap = overlap > 0;
    if (!isThereOverlap) {
      return;
    }

    const padding = 12;
    setLabelSizeCorrection(overlap + padding);
  }, [labelRef.current, rectangleRef.current]);

  return labelWidthCorrection;
}

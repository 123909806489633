import React, {
  InputHTMLAttributes,
  FocusEventHandler,
  forwardRef,
  useEffect,
  useState,
  useRef,
} from "react";

import { Password } from "domain/security";
import SimpleInput from "molecules/SimpleInput";
import Popover from "atoms/Popover";
import { Eye, EyeCrossed } from "components/Icons";
import Text from "atoms/Text";
import useDebounce from "hooks/useDebounce";
import Pulse from "atoms/Pulse";
import { X as IconX, Checkmark } from "components/Icons";
import StrengthMeter from "atoms/StrengthMeter";

import styles from "./module.sass";

export type Props = InputHTMLAttributes<HTMLInputElement> & {
  label: string;

  variant?: "new" | "existing";
  error?: string;
  blacklist?: string[];
  onBlur?: FocusEventHandler<HTMLDivElement>;
  popoverPosition: "top" | "right";
};

const PasswordInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      value,
      variant = "existing",
      error = "",
      blacklist = [],
      onBlur = () => {},
      popoverPosition,
      ...rest
    },
    ref
  ) => {
    const [isObfuscated, setIsObfuscated] = useState(true);
    const [meterStep, setMeterStep] = useState<0 | 1 | 2>(0);
    const [isFocused, setIsFocused] = useState(false);
    const didMount = useRef(false);
    const debouncedValue = useDebounce(value, 300);

    const isTyping = value !== debouncedValue && didMount.current;

    useEffect(() => {
      didMount.current = true;
    });

    useEffect(() => {
      if (typeof value !== "string") {
        return;
      }

      const password = new Password(value, blacklist);
      const newMeterStep = password.isBlacklisted
        ? 0
        : ([0, 1, 1, 2, 2][password.strength] as 0 | 1 | 2);
      setMeterStep(newMeterStep);
    }, [value, blacklist]);

    const handleFocus: FocusEventHandler<HTMLDivElement> = (e) => {
      setIsFocused(true);
    };

    const handleBlur: FocusEventHandler<HTMLDivElement> = (e) => {
      const isFocusInsideHierarchy =
        e.relatedTarget instanceof Node &&
        e.currentTarget.contains(e.relatedTarget);
      if (isFocusInsideHierarchy) {
        e.stopPropagation();
        return;
      }
      onBlur(e);
      setIsFocused(false);
    };

    return (
      <div
        className={styles.passwordInput}
        onFocus={handleFocus}
        onBlur={handleBlur}
      >
        <Popover
          padding="big"
          offset={popoverPosition === "right" ? [0, 58] : [15, 20]}
          content={
            <div style={{ height: 88, boxSizing: "border-box" }}>
              <Text
                weight="bold"
                size={18}
                color="white"
                style={{ marginBottom: 8 }}
              >
                Let's create a strong password.
              </Text>
              {isTyping && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Pulse />
                </div>
              )}
              {!isTyping && meterStep === 2 && <StrongPasswordMessage />}
              {!isTyping && meterStep !== 2 && <WeakPasswordMessage />}
            </div>
          }
          isDisabled={variant === "existing"}
          isVisible={Boolean(value) && isFocused}
          popoverPosition={popoverPosition}
        >
          <SimpleInput
            {...rest}
            ref={ref}
            value={value}
            type={isObfuscated ? "password" : "text"}
            error={error}
            render={(input) => (
              <>
                {input}
                <button
                  type="button"
                  className={styles.toggle}
                  onClick={() =>
                    setIsObfuscated((isObfuscated) => !isObfuscated)
                  }
                >
                  {isObfuscated ? (
                    <Eye className={styles.eye} />
                  ) : (
                    <EyeCrossed className={styles.eye} />
                  )}
                </button>
              </>
            )}
          />
        </Popover>
        {variant === "new" && !error && value !== "" && (
          <StrengthMeter step={meterStep} />
        )}
      </div>
    );
  }
);

const StrongPasswordMessage = () => (
  <div
    style={{
      padding: "8px 0",
      display: "flex",
      alignItems: "center",
    }}
  >
    <Checkmark
      fill="#fff"
      width={16}
      height={16}
      style={{ padding: 6, backgroundColor: "#6fa136", borderRadius: "50%" }}
    />
    <Text color="white" style={{ marginLeft: 14, fontSize: 16 }}>
      Great password!
      <br />
      It's hack-resistant.
    </Text>
  </div>
);

const WeakPasswordMessage = () => (
  <div
    style={{
      padding: "8px 0",
      display: "flex",
      alignItems: "center",
    }}
  >
    <IconX
      fill="#fff"
      width={16}
      height={16}
      style={{ padding: 6, backgroundColor: "#e43935", borderRadius: "50%" }}
    />
    <Text color="white" style={{ marginLeft: 14, fontSize: 16 }}>
      Your password is easily
      <br />
      hackable. Keep trying.
    </Text>
  </div>
);

export default PasswordInput;

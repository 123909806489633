import React, { SVGProps } from "react";

export default function Chevron({
  up,
  left,
  right,
  width = 18,
  height = 18,
  ...rest
}: SVGProps<SVGSVGElement> & {
  up?: boolean;
  left?: boolean;
  right?: boolean;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19.35 9.76"
      height={height}
      width={width}
      transform={
        up ? `rotate(180)` : left ? `rotate(90)` : right ? `rotate(270)` : ""
      }
      {...rest}
    >
      <path d="M18.11 0L9.68 7.28 1.24 0 0 1.43l9.68 8.33 9.58-8.25.09-.08L18.11 0z" />
    </svg>
  );
}

import "core-js/stable";
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryParamProvider } from "use-query-params";

import App from "./App";
import BrowserExtensionApp from "./BrowserExtensionApp";
import Portals from "./Portals";

const appType = getAppType();
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <HashRouter hashType="noslash">
      <QueryParamProvider ReactRouterRoute={Route}>
        <QueryClientProvider client={queryClient}>
          {appType === "standalone" && <App />}
          {appType === "browserExtension" && <BrowserExtensionApp />}
          {appType === "legacy" && <Portals />}
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </QueryParamProvider>
    </HashRouter>
  </React.StrictMode>,
  document.getElementById("react-app")
);

type AppType = "standalone" | "browserExtension" | "legacy";

function getAppType(): AppType {
  if (window.location.pathname.startsWith("/app.php")) {
    return "standalone";
  }
  if (window.location.pathname.startsWith("/browser-extension.php")) {
    return "browserExtension";
  }
  return "legacy";
}

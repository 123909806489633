import React, { HTMLAttributes, useCallback, useMemo } from "react";
import { Column, usePagination, useSortBy, useTable } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons";

import Text from "atoms/Text";
import Table, { EmptyMessage } from "components/Table";
import { format as formatDate } from "domain/dates";
import { SubmittedTrialRequest, formatAsCSV } from "domain/trial";
import Button from "components/Button";
import { downloadURIEncodedFile } from "domain/dom";

export type Props = {
  trialRequests: SubmittedTrialRequest[];
} & HTMLAttributes<HTMLDivElement>;

/**
 * Memoize trialRequests before passing as a prop.
 */
export default function ReviewedTrialRequests({
  trialRequests,
  ...rest
}: Props) {
  const reviewedRequests = useMemo(
    () => trialRequests.filter((r) => r.status !== "pending"),
    [trialRequests]
  );
  const reviewedRequestsColumns: Column<SubmittedTrialRequest>[] = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ value }) => (
          <span style={{ whiteSpace: "nowrap" }}>{value}</span>
        ),
      },
      {
        Header: "Company",
        accessor: "company",
        Cell: ({ value }) => (
          <span style={{ whiteSpace: "nowrap" }}>{value}</span>
        ),
      },
      { Header: "Email", accessor: "email" },
      {
        Header: "Date of Request",
        accessor: "dateRequested",
        sortType: "datetime",
        Cell: ({ value }) => (
          <span style={{ whiteSpace: "nowrap" }}>
            {formatDate(value, "Y-m-d H:M")}
          </span>
        ),
      },
      {
        Header: "Date of Review",
        accessor: "dateReviewed",
        sortType: (rowA, rowB, id, desc) => {
          const a = rowA.values[id];
          const b = rowB.values[id];
          if (!a && !b) return 0;
          if (!a || a < b) return -1;
          if (!b || a > b) return 1;
          return 0;
        },
        Cell: ({ value }) => (value ? formatDate(value, "Y-m-d") : ""),
      },
      {
        Header: "Notes",
        accessor: "note",
        disableSortBy: true,
        Cell: ({ value }) => (value ? value : "-"),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => (
          <Text color={getStatusColumnColor(value)}>
            {value.charAt(0).toUpperCase() + value.slice(1)}
          </Text>
        ),
      },
      {
        Header: "Reviewed By",
        accessor: "reviewer",
      },
    ],
    [reviewedRequests]
  );
  const reviewedRequestsTable = useTable(
    {
      columns: reviewedRequestsColumns,
      data: reviewedRequests,
      disableSortRemove: true,
      autoResetSortBy: false,
      initialState: {
        pageSize: 10,
        sortBy: useMemo(
          () => [
            {
              id: "dateReviewed",
              desc: true,
            },
          ],
          []
        ),
      },
    },
    useSortBy,
    usePagination
  );

  const approvedRequestsCount = reviewedRequests.filter(
    (r) => r.status === "approved"
  ).length;
  const rejectedRequestsCount = reviewedRequests.filter(
    (r) => r.status === "rejected"
  ).length;
  const hiddenRequestsCount = reviewedRequests.filter(
    (r) => r.status === "hidden"
  ).length;

  const handleExport = () => {
    const today = new Date().toISOString().split("T")[0];
    const csvContent = formatAsCSV(reviewedRequests);

    const uriEncodedCSVFile =
      "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent);
    const fileName = `${today}_reviewed_requests.csv`;

    downloadURIEncodedFile(uriEncodedCSVFile, fileName);
  };

  return (
    <section {...rest}>
      <header
        style={{ display: "flex", alignItems: "flex-end", marginBottom: 18 }}
      >
        <Text as="h2" weight="bold" size={18}>
          Reviewed Requests ({reviewedRequests.length})
        </Text>
        <Text size={18} style={{ marginLeft: 28 }}>
          Approved ({approvedRequestsCount})
        </Text>
        <Text size={18} style={{ marginLeft: 28 }}>
          Rejected ({rejectedRequestsCount})
        </Text>
        <Text size={18} style={{ marginLeft: 28 }}>
          Hidden ({hiddenRequestsCount})
        </Text>
        <Button
          variant="contained"
          size="xs"
          style={{ marginLeft: "auto" }}
          onClick={handleExport}
        >
          <FontAwesomeIcon
            icon={faCloudDownloadAlt}
            style={{ backgroundImage: "none" }}
          />
        </Button>
      </header>
      <Table
        table={reviewedRequestsTable}
        emptyMessage={
          <EmptyMessage colSpan={8}>
            There are no reviewed requests
          </EmptyMessage>
        }
      />
    </section>
  );
}

function getStatusColumnColor(value: string) {
  if (value === "approved") {
    return "green600";
  }
  if (value === "hidden") {
    return "blue900";
  }
  return "red400";
}

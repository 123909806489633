export class Validator {
  private value;
  private maxLength;

  constructor(value: string, maxLength: number | null = null) {
    this.value = value;
    this.maxLength = maxLength;
  }

  get hasValidLength(): boolean {
    return this.maxLength ? this.value.length <= this.maxLength : true;
  }

  get isValidName(): boolean {
    const hasValidCharacters = /^[A-Za-z\u00C0-\u017F.\s-]*$/.test(this.value);
    return hasValidCharacters && this.hasValidLength;
  }

  get isValidOrganizationName(): boolean {
    const hasValidCharacters = /^[^@#$%\^*<>~{}\[\]]*$/.test(this.value);
    return hasValidCharacters && this.hasValidLength;
  }

  get isValidLenientPhoneNumber(): boolean {
    const hasValidCharacters = /^[x0-9+().\-]*$/.test(this.value);
    return hasValidCharacters && this.hasValidLength;
  }
}

// export interface Validator {
//   isValid(value: string): boolean;
// }

// export class NameValidator {
//   isValid(name: string): boolean {
//     return /^[A-Za-z0-9\u00C0-\u017F.\s-]+$/.test(name.trim());
//   }
// }

// export class OrganizationNameValidator {
//   isValid(name: string): boolean {
//     return /^[A-Za-z0-9\s]+$/.test(name.trim());
//   }
// }

// export class LenientPhoneNumberValidator {
//   isValid(phone: string): boolean {
//     return /^[A-Za-z0-9.:()\s-]+$/.test(phone.trim());
//   }
// }

import axios from "axios";
import { BASE_URI } from "../api";
import { BrowserExtensionSettings, SupportDocumentsUrl } from "./models";

function getBrowserExtensionUrl() {
  return axios.post(
    `${BASE_URI}/api/v2/BrowserExtensionController.php`,
    new URLSearchParams({
      action: "GetPluginUrl",
    })
  );
}

function getSupportDocumentsUrl() {
  return axios.post(
    `${BASE_URI}/api/v2/BrowserExtensionController.php`,
    new URLSearchParams({
      action: "GetSupportDocumentsUrl",
    })
  );
}

function getCurrentBrowser() {
  return axios.post(
    `${BASE_URI}/api/v2/BrowserExtensionController.php`,
    new URLSearchParams({
      action: "GetCurrentBrowser",
    })
  );
}

function getBrowserExtensionSettings() {
  return axios.post(
    `${BASE_URI}/api/v2/BrowserExtensionController.php`,
    new URLSearchParams({
      action: "GetBrowserExtensionData",
    })
  );
}

function setBrowserExtensionSettings(
  settings: BrowserExtensionSettings
): Promise<boolean> {
  return axios.post(
    `${BASE_URI}/api/v2/BrowserExtensionController.php`,
    new URLSearchParams({
      action: "SetBrowserExtensionData",
      extensionEnabled: "" + settings.isExtensionEnabled,
      passiveLogin: "" + settings.isPassiveLogin,
    })
  );
}

export {
  getCurrentBrowser,
  getBrowserExtensionUrl,
  getSupportDocumentsUrl,
  getBrowserExtensionSettings,
  setBrowserExtensionSettings,
};
